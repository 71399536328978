import { CheckCircleTwoTone } from '@ant-design/icons';
import { Form, Input, Select, message } from 'antd';
import gql from 'graphql-tag';
import _, { get } from 'lodash';
import { useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import ReactDOM from 'react-dom';
import ProgressButton from 'react-progress-button';
import useWindowSize from 'react-use/lib/useWindowSize';
import { configMode } from 'src/_shared/api/';
import {
	createContact,
	updateContact,
} from 'src/_shared/api/graphql/custom/contacts/';
import { queryReferralQuestionsByCompanyId } from 'src/_shared/api/graphql/custom/referral-questions/';
import {
	createReferral,
	queryReferralsByJobIdIndex,
} from 'src/_shared/api/graphql/custom/referrals/';
import { COMPANIES } from 'src/_shared/constants/';
import { CreateContact } from 'src/_shared/services/hubspot';
import FormElements, { formValues } from 'src/form-builder/FormElements.jsx';
import {
	filterPhoneNumber,
	lambda,
	ml,
	parse,
	sanitize,
	uploadToS3Multipart,
	fetchModernFormQuestions,
} from '../../../services/utils.js';
import OnDeckReferralFormAddContactFields from './OnDeckReferralFormAddContactFields.jsx';
import ReferralLanguageSelector from 'src/_shared/components/ReferralLanguageSelectorComponent';
import ShareSendReferralForm from 'src/_shared/components/ShareSendReferralFormComponent';

const { TextArea } = Input;

function ResumeInput({
	onChange,
	value,
	isResumeRequired,
	currentUser,
	allMultiLingualData,
	...rest
}) {
	return (
		<>
			{!value && (
				<div className="click-to-upload">
					<label htmlFor="resumeFile">
						<span className="link">
							{ml('Click here', currentUser, allMultiLingualData)}
						</span>
						<input
							onChange={onChange}
							{...rest}
							hidden
							type="file"
							accept=".doc,.docx,application/msword,.pdf,application/pdf"
						/>
					</label>{' '}
					{ml('to attach a resume', currentUser, allMultiLingualData)}{' '}
					<span
						className={
							isResumeRequired
								? 'custom-required'
								: 'custom-optional'
						}
					>
						{isResumeRequired
							? `(${ml('required', currentUser, allMultiLingualData)})`
							: `(${ml('optional', currentUser, allMultiLingualData)})`}
					</span>
				</div>
			)}
			{value && (
				<div className="upload-file-text">
					<p>
						{value.name.length > 50
							? value.name.slice(0, 50) + '...'
							: value.name}
						&nbsp;
						<i
							className="icon-bin text-danger cursor-p"
							onClick={() => {
								onChange({ target: { files: [] } });
							}}
						/>
					</p>
				</div>
			)}
		</>
	);
}

const inputs = {};

function OnDeckReferralForm(props) {
	const {
		contacts,
		client,
		currentUser,
		customGeneralModal,
		handleCancel,
		onDeckContacts,
		enterReferralInformationText,
		allMultiLingualData,
		companyData,
		filteredJobs,
		onUpdateJob,
		onCreateUserJobShare,
		onUpdateUserJobShare,
	} = props;
	const [form] = Form.useForm();
	// Heartland Affiliates Customization Options
	const subCompanyId = customGeneralModal?.subCompanyId;
	const generalJobId = customGeneralModal?.generalJobId;
	const hideIncludeMessage = customGeneralModal?.hideIncludeMessage;
	const hideReferralSubheader = customGeneralModal?.hideReferralSubheader;
	const enablePhone = customGeneralModal?.enablePhone;
	const hideDivider = customGeneralModal?.hideDivider;
	const hideJobSelector = customGeneralModal?.hideJobSelector;
	const reverseOrder = customGeneralModal?.reverseOrder;
	const submitText = customGeneralModal?.submitText;
	const recruiterMessageLabel = customGeneralModal?.recruiterMessageLabel;
	const referralSubheaderText = customGeneralModal?.referralSubheaderText;
	const confirmCompliance = get(currentUser, 'company.confirmCompliance');
	const pointsSettings = get(companyData, 'pointsSettings');
	let referralSentPoints = null;
	if (pointsSettings !== null && pointsSettings !== undefined) {
		const pointsSettingsData = JSON.parse(pointsSettings);
		if (pointsSettingsData.enabled) {
			referralSentPoints = get(pointsSettingsData, 'generalReferral');
		}
	}

	const [selectedContact, setSelectedContact] = useState(null);
	const [buttonState, setButtonState] = useState('');
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
	const [referralQuestions, setReferralQuestions] = useState([]);
	const [resumeCharacteristics, setResumeCharacteristics] = useState();
	const [referralType, setReferralType] = useState('email');
	const [selectedJobs, setSelectedJobs] = useState([]);
	const [showConfetti, setShowConfetti] = useState(false);
	const [errors, setErrors] = useState('');
	const { width, height } = useWindowSize();
	const [referralLanguage, setReferralLanguage] = useState(
		props?.currentUser?.languageCode || 'US',
	);

	const { Option } = Select;

	useEffect(() => {
		(async () => {
			// Get array of all referral questions data objects and fetch next token if it is there
			try {
				const { currentUser } = props;
				const formType = 'General';
				let referralQuestionsData = await fetchModernFormQuestions(
					currentUser,
					formType,
				);
				if (
					!referralQuestionsData ||
					(referralQuestionsData &&
						referralQuestionsData.length === 0)
				) {
					const fetchQuestions = async (nextToken) => {
						const response = await client.query({
							query: gql(queryReferralQuestionsByCompanyId),
							fetchPolicy: 'network-only',
							variables: {
								companyId: currentUser.companyId,
								after: nextToken,
							},
						});
						const referralQuestionsData =
							response.data.queryReferralQuestionsByCompanyId
								.items;

						if (
							response.data.queryReferralQuestionsByCompanyId
								.nextToken
						) {
							const moreReferralQuestionsData =
								await fetchQuestions(
									response.data
										.queryReferralQuestionsByCompanyId
										.nextToken,
								);
							return [
								referralQuestionsData,
								...moreReferralQuestionsData,
							];
						}

						return referralQuestionsData;
					};

					// Initiation of the recursive function
					referralQuestionsData = await fetchQuestions();
					if (subCompanyId) {
						referralQuestionsData = referralQuestionsData.filter(
							(item) => item.subCompanyId,
						);
					} else {
						referralQuestionsData = referralQuestionsData.filter(
							(item) => item.isGeneral === true,
						);
					}
				}

				// Get resume characteristics off referral data, and sort out candidate data
				setResumeCharacteristics(
					referralQuestionsData
						.filter(
							(item) =>
								item.sortOrder === 0 &&
								(item.isGeneral === true || item.subCompanyId),
						)
						.map((resumeItem) => {
							if (typeof resumeItem.questions === 'string') {
								return JSON.parse(resumeItem.questions);
							}

							return resumeItem.questions;
						}),
				);
				// Pull and parse questions from data, excluding resume characteristics
				setReferralQuestions(
					_.sortBy(referralQuestionsData, ['sortOrder'])
						.filter(
							(question) =>
								(question.isGeneral === true ||
									question.subCompanyId) &&
								question.sortOrder !== 0,
						)
						.map((questionItem) => {
							if (typeof questionItem.questions === 'string') {
								return JSON.parse(questionItem.questions);
							}

							return questionItem.questions;
						}),
				);
			} catch (error) {
				console.log(error);
			}
		})();
	}, []);
	const changeReferralType = (referralType) => {
		setReferralType(referralType);
	};

	const handleSelectContact = (contact) => {
		if (
			onDeckContacts.some(
				(onDeckContact) =>
					onDeckContact.emailAddress === contact.emailAddress,
			)
		) {
			message.error('You Already Referred That Person', 5);
		} else {
			setSelectedContact(contact);
			if (contact.emailAddress) {
				setReferralType('email');
			} else if (contact.phoneNumber) {
				setReferralType('text');
			}
		}
	};

	const handleClearSelected = () => {
		setSelectedContact(null);
	};

	// Run update contact, and create contact locally because of Apollo exceeding call stack from provider
	const updateExistingContact = async (contact) => {
		await client.mutate({
			mutation: gql(updateContact),
			variables: { input: contact },
		});
	};

	const createNewContact = async (contact) => {
		const newContact = await client.mutate({
			mutation: gql(createContact),
			variables: { input: contact },
		});
		return newContact.data.createContact;
	};

	const createNewReferral = async (referral) => {
		const newReferral = await client.mutate({
			mutation: gql(createReferral),
			variables: { input: referral },
		});
		return newReferral.data.createReferral;
	};

	// START FORM BUILDER----------------------

	const getInputElement = (item) => {
		const Input = FormElements[item.element];
		return (
			<div key={item.id} className="custom-form-group">
				<Input
					ref={(c) => (inputs[item.field_name] = c)}
					key={`form_${item.id}`}
					mutable
					className="custom-input"
					data={item}
				/>
			</div>
		);
	};

	const getSimpleElement = (item) => {
		const Element = FormElements[item.element];
		return <Element key={`form_${item.id}`} mutable data={item} />;
	};

	const data_items = referralQuestions;
	const items = data_items.map((item, index) => {
		if (!item) return null;
		switch (item.element) {
			case 'TextInput':
			case 'TextArea':
			case 'Dropdown': {
				return getInputElement(item, index);
			}

			default: {
				return getSimpleElement(item, index);
			}
		}
	});

	function _collectFormData(data) {
		const STATIC_ELEMENTS = ['Label', 'LineBreak'];
		const formData = [];
		let errors = '';

		const inputElements = data.filter(
			(item) =>
				item && !STATIC_ELEMENTS.includes(item.element) && !item.static,
		);

		for (const item of inputElements) {
			if (formValues[item.field_name]) {
				const storedData = formValues[item.field_name];

				if (
					item.required &&
					(!storedData.value || storedData.value === '')
				) {
					errors += `${item.label || 'Field'} is required. `;
				}
				formData.push({
					name: item.field_name,
					value: storedData.value || '',
					question: item.label || storedData.question || '',
					text: storedData.text || '',
				});
				continue;
			} else if (item.required) {
				errors += `${item.label || 'Field'} is required. `;
			}
		}

		return {
			formData,
			errors,
		};
	}

	// ----END FORM BUILDER---------------------

	const handleSubmit = async (values) => {
		// Check to ensure values are not undefined before proceeding
		let hubSpotPracticeName = '';
		let hubSpotPhone = '';
		let hubSpotState = '';
		let hubSpotCompanyPhone = '';
		let hubSpotEmail = '';

		const job = { selectedJobs: selectedJobs };
		if (
			!currentUser ||
			!currentUser.id ||
			((!job || !job.selectedJobs) && !generalJobId)
		) {
			return;
		}

		try {
			const questionsData = _collectFormData(referralQuestions).formData;
			questionsData.map((item) => {
				const value = sanitize(item.value);
				item.value = value;
				if (item.question.trim() === 'Doctor Mobile Number') {
					hubSpotPhone = sanitize(item.value);
				}

				if (item.question.trim() === 'Practice Name') {
					hubSpotPracticeName = sanitize(item.value);
				}

				if (item.question.trim() === 'State') {
					hubSpotState = sanitize(item.value);
				}

				if (
					item.question.trim() ===
					'Office Phone (if not avaliable, enter NA)'
				) {
					hubSpotCompanyPhone = sanitize(item.value);
				}

				if (item.question.trim() === 'Email') {
					hubSpotEmail = sanitize(item.value);
				}
			});

			const isEmptySelect = questionsData.some(
				(item) =>
					item.name.includes('dropdown') &&
					item.text === 'Select' &&
					item.value === '0',
			);

			const quesErrors = _collectFormData(referralQuestions).errors;
			if (quesErrors.length > 0 || isEmptySelect) {
				setButtonState('error');
				setErrors(quesErrors);
				setTimeout(() => {
					setButtonState('');
				}, 1500);
				return;
			}

			// If user already submitted, return if they click again. Prevents them from double clicking submit
			if (isSubmitDisabled) {
				return;
			}

			// If no errors
			setButtonState('loading');
			setIsSubmitDisabled(true);

			const d = new Date();
			const dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
			let { firstName } = values;
			firstName &&= sanitize(firstName);
			let { lastName } = values;
			lastName &&= sanitize(lastName);
			let { onDeckNote } = values;
			onDeckNote &&= sanitize(onDeckNote);
			let { onDeckMessage } = values;
			onDeckMessage &&= sanitize(onDeckMessage);
			let { emailAddress } = values;
			emailAddress &&= sanitize(emailAddress);
			let { phoneNumber } = values;
			phoneNumber &&= sanitize(phoneNumber);
			// If submitting referral for existing contact do these things--------------------------------------------
			if (selectedContact) {
				if (values.resumeFile) {
					const contactResume = {
						bucket: 'erin-documents',
						key: `resumes/${selectedContact.id}/${dformat}- 
            ${values.resumeFile.name}`,
						region: 'us-east-2',
					};

					await uploadToS3Multipart(
						values.resumeFile,
						contactResume.key,
						contactResume.bucket,
					);
					await updateExistingContact({
						id: selectedContact.id,
						contactResume,
					});
				}

				const referral = {
					companyId: currentUser.companyId,
					contactId: selectedContact.id,
					referralType,
					userId: currentUser.id,
					jobId: generalJobId ?? job.selectedJobs,
					status: 'referred',
					referralSource: 'general',
					referralDevice: 'web',
					languageCode: referralLanguage,
				};

				if (onDeckNote) referral.note = onDeckNote;
				if (onDeckMessage) referral.message = onDeckMessage;
				const createdReferral = await createNewReferral(referral);

				if (
					createdReferral &&
					get(createdReferral, 'job.externalSource') ===
						'Greenhouse' &&
					get(createdReferral, 'contact.emailAddress') &&
					get(currentUser, 'company.enableProspectCreation')
				) {
					// -------------------------- START ENABLE PROSPECT CREATION---------------------------

					const prospect = {
						firstName: createdReferral.contact.firstName,
						lastName: createdReferral.contact.lastName,
						emailId: createdReferral.contact.emailAddress,
						externalJobId: createdReferral.job.externalJobId,
						companyId: currentUser.companyId,
						referralEmailId: createdReferral.user.emailAddress,
						custom_fields: [],
					};

					prospect.configMode = configMode === 'DEV' ? 'dev' : 'prod';
					await lambda({
						endpoint: 'GreenhouseCreateProspectDev',
						variables: prospect,
					});
				}

				// --------------------------END PROSPECT CREATION-----------------------------

				// Show success
				setButtonState('success');
				if (
					referralSentPoints &&
					referralSentPoints !== 0 &&
					referralSentPoints !== '0'
				) {
					message.success(
						`You earned ${referralSentPoints} points`,
						5,
					);
					setShowConfetti(true);
				} else {
					message.success('Your request is submitted.', 5);
				}

				await new Promise((resolve) => {
					setTimeout(() => resolve(), 2000);
				});

				// Close modal
				handleCancel();

				// If fail, reset submit button and allow user to try again
				setButtonState('');
				setIsSubmitDisabled(false);
				// Submitting referral for new contact-------------------------
			} else {
				let isAlreadyReferredToJob = false;

				try {
					isAlreadyReferredToJob = await client
						.query({
							query: gql(queryReferralsByJobIdIndex),
							variables: {
								jobId: generalJobId ?? job.selectedJobs,
							},
							fetchPolicy: 'network-only',
						})
						.then((response) =>
							response.data.queryReferralsByJobIdIndex.items.some(
								(referral) =>
									(referral.contact &&
										referral.contact.emailAddress &&
										referral.contact.emailAddress ===
											emailAddress) ||
									(referral.contact &&
										referral.contact.phoneNumber &&
										filterPhoneNumber(
											referral.contact.phoneNumber,
										) === phoneNumber),
							),
						);
				} catch (error) {
					console.log(
						'validator error for alreadyReferredToJob',
						error,
					);
				}

				if (isAlreadyReferredToJob) {
					message.error(
						'This person has already been referred for this job.',
						5,
					);
					setButtonState('');
					setIsSubmitDisabled(false);
					return;
				}

				const contact = {
					firstName,
					lastName,
					userId: currentUser.id,
					companyId: currentUser.companyId,
				};
				if (phoneNumber) {
					contact.phoneNumber = phoneNumber;
					contact.importMethod = 'mobile';
				}

				if (emailAddress) {
					contact.emailAddress = emailAddress.toLowerCase();
					contact.importMethod = 'email';
				}

				contact.disableSmartReferrals = true;

				// Create a new contact
				const createdContact = await createNewContact(contact);
				if (values.resumeFile) {
					const contactResume = {
						bucket: 'erin-documents',
						key: `resumes/${createdContact.id}/${dformat}-${values.resumeFile.name}`,
						region: 'us-east-2',
					};

					await uploadToS3Multipart(
						values.resumeFile,
						contactResume.key,
						contactResume.bucket,
					);
					await updateExistingContact({
						id: createdContact.id,
						contactResume,
					});
				}

				const referral = {
					companyId: currentUser.companyId,
					contactId: createdContact.id,
					referralType,
					userId: currentUser.id,
					jobId: generalJobId ?? job.selectedJobs,
					status: 'referred',
					referralSource: 'general',
					referralDevice: 'web',
					questionsData: JSON.stringify(questionsData),
				};
				if (onDeckNote) {
					referral.note = onDeckNote;
				}

				if (onDeckMessage) {
					referral.message = onDeckMessage;
				}
				// Then create a referral with newly created contact

				const createdReferral = await createNewReferral(referral);
				if (
					createdReferral &&
					get(createdReferral, 'job.externalSource') ===
						'Greenhouse' &&
					get(createdReferral, 'contact.emailAddress') &&
					currentUser.company &&
					get(currentUser, 'company.enableProspectCreation')
				) {
					// -------------------------START ENABLE PROSPECT CREATION NEW CONTACT----------------------------

					const prospect = {
						firstName: createdReferral.contact.firstName,
						lastName: createdReferral.contact.lastName,
						emailId: createdReferral.contact.emailAddress,
						externalJobId: createdReferral.job.externalJobId,
						companyId: currentUser.companyId,
						referralEmailId: createdReferral.user.emailAddress,
						custom_fields: [],
					};

					prospect.configMode = configMode === 'DEV' ? 'dev' : 'prod';
					await lambda({
						endpoint: 'GreenhouseCreateProspectDev',
						variables: prospect,
					});
				}
				// -------------------------END PROSPECT CREATION NEW CONTACT----------------------------

				// Show success
				setButtonState('success');
				setIsSubmitDisabled(false);
				if (
					referralSentPoints &&
					referralSentPoints !== 0 &&
					referralSentPoints !== '0'
				) {
					message.success(
						`You earned ${referralSentPoints} points`,
						5,
					);
					setShowConfetti(true);
				} else {
					message.success('Your request is submitted.', 5);
				}

				await new Promise((resolve) => {
					setTimeout(() => resolve(), 2000);
				});

				// Close modal
				handleCancel();

				// If fail, reset button and allow user to try again
				setButtonState('');
			}
		} catch (error) {
			console.log(error);
			setButtonState('');
			setIsSubmitDisabled(false);
		}

		if (
			get(currentUser, 'companyId') ===
				COMPANIES.HeartlandAffiliationReferrals ||
			get(currentUser, 'companyId') ===
				COMPANIES.HeartlandAffiliateProgram
		) {
			const hubspotData = {
				company: get(currentUser, 'company.name'),
				companyId: get(currentUser, 'companyId'),
				email: get(values, 'emailAddress'),
				firstname: get(values, 'firstName'),
				lastname: get(values, 'lastName'),
				referred_doctor:
					get(values, 'firstName') + ' ' + get(values, 'lastName'),
				source_campaign: 'Internal Referral - ERIN - Source',
				name_of_your_practice: hubSpotPracticeName,
				phone: hubSpotPhone,
				business_state: hubSpotState,
				office_phone_number: hubSpotCompanyPhone,
			};
			const { phoneNumber } = values;
			if (phoneNumber) {
				hubspotData.phone = phoneNumber;
			}

			const hubspotres = await CreateContact(hubspotData);
			if (hubspotres.status == 'error') {
				message.error(hubspotres.message);
			}
		}
	};

	const onFinishFailed = (errorInfo) => {
		setButtonState('error');
		setErrors(errorInfo);
		setTimeout(() => {
			setButtonState('');
		}, 1500);
	};

	const sortedJobs = _.sortBy(filteredJobs, function (job) {
		return job.title;
	});
	const options = _.orderBy(sortedJobs).map((job) => {
		return <Option key={job.id}>{job.title}</Option>;
	});

	const shouldDisplayForm = () => {
		const foundJob = filteredJobs.find((item) => item.id === selectedJobs);
		if (!foundJob) {
			return false;
		}
		let hideDepartmentShareLink = false;
		if (companyData.hideShareLinkForDepartment) {
			const jsonData = parse(companyData.hideShareLinkForDepartment);
			hideDepartmentShareLink = jsonData.some(function (value) {
				return value.id === foundJob.departmentId;
			});
		}
		return (
			!companyData.disableShareLink &&
			foundJob.status === 'open' &&
			currentUser.role !== 'extendedUser' &&
			!hideDepartmentShareLink &&
			(!companyData.hideShareLinkNoPublicUrl || foundJob.publicLink)
		);
	};

	const ShareSendForm = () => {
		const foundJob = filteredJobs.find((item) => item.id === selectedJobs);
		return (
			<ShareSendReferralForm
				allMultiLingualData={allMultiLingualData}
				client={client}
				currentUser={currentUser}
				jobId={foundJob?.id}
				job={foundJob}
				languageCode={referralLanguage}
				onUpdateJob={onUpdateJob}
				onCreateUserJobShare={onCreateUserJobShare}
				onUpdateUserJobShare={onUpdateUserJobShare}
			/>
		);
	};
	console.log('RESUME CHARACTERISTICS', resumeCharacteristics);
	return (
		<Form
			form={form}
			onFinish={handleSubmit}
			onFinishFailed={onFinishFailed}
		>
			{showConfetti && (
				<div
					style={{
						position: 'absolute',
						top: '5px',
						width: '10%',
						left: '45%',
					}}
				>
					<ConfettiExplosion
						style={{
							force: 1,
							duration: 4000,
							particleCount: 400,
							height,
							width,
							margin: 'auto',
						}}
					/>
				</div>
			)}
			{!hideJobSelector && (
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Submit to', currentUser, allMultiLingualData)}:
					</label>
					<Form.Item name="submitTo">
						<Select
							className="custom-input"
							placeholder="Select General Referral Category"
							onSelect={(selectedJobs) =>
								setSelectedJobs(selectedJobs)
							}
						>
							{options}
						</Select>
					</Form.Item>
				</div>
			)}
			{!hideDivider && <div className="divider" />}
			{reverseOrder && <>{items}</>}
			<OnDeckReferralFormAddContactFields
				selectedContact={selectedContact}
				contacts={contacts}
				form={form}
				handleSelectContact={handleSelectContact}
				handleClearSelected={handleClearSelected}
				enterReferralInformationText={enterReferralInformationText}
				enablePhone={enablePhone}
				referralSubheaderText={referralSubheaderText}
				hideReferralSubheader={hideReferralSubheader}
				currentUser={currentUser}
				allMultiLingualData={allMultiLingualData}
				referralType={referralType}
				changeReferralType={changeReferralType}
				onDeckContacts={onDeckContacts}
			/>
			{!hideIncludeMessage && (
				<div className="custom-form-group">
					<label className="custom-label">
						{ml(
							'Include a message to your contact',
							currentUser,
							allMultiLingualData,
						)}
						<span>
							{' '}
							{ml('(optional)', currentUser, allMultiLingualData)}
						</span>
					</label>
					<Form.Item name="onDeckNote">
						<TextArea
							className="custom-input"
							placeholder={ml(
								'Personalize the message to your referral',
								currentUser,
								allMultiLingualData,
							)}
							rows={4}
						/>
					</Form.Item>
				</div>
			)}

			<div className="custom-form-group">
				<label className="custom-label">
					{recruiterMessageLabel ??
						ml(
							'Message the Recruiter',
							currentUser,
							allMultiLingualData,
						)}{' '}
					<span>
						{ml('(optional)', currentUser, allMultiLingualData)}
					</span>
				</label>
				<Form.Item name="onDeckMessage">
					<TextArea
						className="custom-input"
						placeholder={ml(
							'How do you know them, why are they a good fit, etc.',
							currentUser,
							allMultiLingualData,
						)}
						rows={4}
					/>
				</Form.Item>
			</div>

			{!reverseOrder && <>{items}</>}

			{selectedContact && selectedContact.contactResume ? (
				<div style={{ marginBottom: '30px' }}>
					{`Contact's current resume attached (${
						selectedContact.contactResume.key.split('-')[7]
					})`}
					&nbsp;
					<CheckCircleTwoTone twoToneColor="#52c41a" />
				</div>
			) : resumeCharacteristics &&
			  resumeCharacteristics.length > 0 &&
			  resumeCharacteristics[0]['Ask for resume attachment'] ? (
				<Form.Item
					name="resumeFile"
					getValueFromEvent={(event) => event.target.files[0]}
					rules={[
						{
							required:
								resumeCharacteristics &&
								resumeCharacteristics.length &&
								resumeCharacteristics[0]['Resume required'],
							message: 'Required',
						},
						{
							validator(rule, value, callback) {
								if (
									value &&
									![
										'application/msword',
										'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
										'application/pdf',
									].includes(value.type)
								) {
									return callback(
										'You can only upload doc, docx, pdf files!',
									);
								}

								callback();
							},
						},
						{
							validator(rule, value, callback) {
								if (value && value.size / 1024 / 1024 > 2) {
									return callback(
										'File size must smaller than 2MB!',
									);
								}

								callback();
							},
						},
					]}
				>
					<ResumeInput
						isResumeRequired={
							resumeCharacteristics &&
							resumeCharacteristics.length &&
							resumeCharacteristics[0]['Resume required']
						}
						currentUser={currentUser}
						allMultiLingualData={allMultiLingualData}
					/>
				</Form.Item>
			) : null}

			<div className="modal-footer-btn">
				<ProgressButton
					controlled
					durationSuccess={3000}
					state={buttonState}
				>
					{submitText ??
						ml(
							'Submit General Referral',
							currentUser,
							allMultiLingualData,
						)}
				</ProgressButton>
			</div>
			<ReferralLanguageSelector
				allMultiLingualData={allMultiLingualData}
				currentUser={currentUser}
				setReferralLanguage={setReferralLanguage}
			/>
			{!hideJobSelector && shouldDisplayForm() && <ShareSendForm />}
		</Form>
	);
}

export default OnDeckReferralForm;
