import { format, parse } from 'date-fns';
import get from 'lodash/get';
import { Component, createRef } from 'react';
import xss from 'xss';
import dompurify from 'dompurify';
import { COLORS } from '../_shared/styles/colors';
import HeaderBar from './HeaderBar.jsx';
import StarRating from './StarRating.jsx';
import { Select, Input } from 'antd';
const sanitizer = dompurify.sanitize;
export const formValues = {};

const FormElements = {};

const myxss = new xss.FilterXSS({
	whiteList: {
		u: [],
		br: [],
		b: [],
		i: [],
		ol: ['style'],
		ul: ['style'],
		li: [],
		p: ['style'],
		sub: [],
		sup: [],
		div: ['style'],
		em: [],
		strong: [],
		span: ['style'],
	},
});

function ComponentLabel(props) {
	const createMarkup = (content) => {
		const container = document.createElement('div');
		container.innerHTML = content;
		return container.innerHTML;
	};

	const { required } = props;

	return (
		<label className="custom-label">
			<div
				dangerouslySetInnerHTML={{
					__html: sanitizer(createMarkup(props.data.label)),
				}}
				style={{ display: 'inline' }}
			/>
			{required ? (
				<span className="custom-required">(required)</span>
			) : (
				<span className="custom-optional">(optional)</span>
			)}
		</label>
	);
}

function ComponentHeader(props) {
	if (props.mutable) {
		return null;
	}

	return (
		<>
			{props.data.pageBreakBefore && (
				<div className="preview-page-break">Page Break</div>
			)}
			<HeaderBar
				parent={props.parent}
				editModeOn={props.editModeOn}
				data={props.data}
				static={props.data.static}
				required={props.data.required}
				onDestroy={props._onDestroy}
				onEdit={props.onEdit}
			/>
		</>
	);
}

function Header(props) {
	let classNames = 'static';
	if (props.data.bold) {
		classNames += ' bold';
	}

	if (props.data.italic) {
		classNames += ' italic';
	}

	let baseClasses = 'SortableItem rfb-item';
	if (props.data.pageBreakBefore) {
		baseClasses += ' alwaysbreak';
	}

	return (
		<div className={baseClasses}>
			<ComponentHeader {...props} />
			<h3
				dangerouslySetInnerHTML={{
					__html: sanitizer(myxss.process(props.data.content)),
				}}
				className={classNames}
			/>
		</div>
	);
}

function Paragraph(props) {
	let classNames = 'static';
	if (props.data.bold) {
		classNames += ' bold';
	}

	if (props.data.italic) {
		classNames += ' italic';
	}

	let baseClasses = 'SortableItem rfb-item';
	if (props.data.pageBreakBefore) {
		baseClasses += ' alwaysbreak';
	}

	return (
		<div className={baseClasses}>
			<ComponentHeader {...props} />
			<p
				dangerouslySetInnerHTML={{
					__html: sanitizer(myxss.process(props.data.content)),
				}}
				className={classNames}
			/>
		</div>
	);
}

class Label extends Component {
	createMarkup(content) {
		const container = document.createElement('div');
		container.innerHTML = content;
		return container.innerHTML;
	}

	render() {
		let classNames = 'static';
		if (this.props.data.bold) {
			classNames += ' bold';
		}

		if (this.props.data.italic) {
			classNames += ' italic';
		}

		let baseClasses = 'SortableItem rfb-item';
		if (this.props.data.pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}

		return (
			<div className={baseClasses}>
				<ComponentHeader {...this.props} />
				<label
					dangerouslySetInnerHTML={{
						__html: sanitizer(
							this.createMarkup(this.props.data.content),
						),
					}}
				/>
			</div>
		);
	}
}

function LineBreak(props) {
	let baseClasses = 'SortableItem rfb-item';
	if (props.data.pageBreakBefore) {
		baseClasses += ' alwaysbreak';
	}

	return (
		<div className={baseClasses}>
			<ComponentHeader {...props} />
			<hr />
		</div>
	);
}

class TextInput extends Component {
	constructor(props) {
		super(props);
		this.inputField = createRef();
	}

	handleChange = (e) => {
		formValues[this.props.data.field_name] = {
			value: e.target.value,
			text: '',
			question: this.props.data.label,
			required: this.props.data.required || false
		};
		
		if (this.props.handleChange) {
			this.props.handleChange({
				name: this.props.data.field_name,
				value: e.target.value,
				question: this.props.data.label,
				text: '',
			});
		}
	};

	render() {
		const props = {};
		props.type = 'text';
		props.className = 'custom-input';
		props.name = this.props.data.field_name;
		props.onChange = this.handleChange;
		if (this.props.mutable) {
			props.defaultValue = this.props.defaultValue;
			props.ref = this.inputField;
		}

		let baseClasses = 'SortableItem rfb-item';
		if (this.props.data.pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}

		if (this.props.read_only) {
			props.disabled = 'disabled';
		}

		const hasError = this.props.errors && typeof this.props.errors === 'string' &&
    		this.props.errors?.includes(`${this.props.data.label} is required`);

		return (
			<div className={baseClasses}>
				<ComponentHeader {...this.props} />
				<div className="custom-form-group">
					<ComponentLabel
						{...this.props}
						required={this.props?.data?.required}
					/>
					<Input
						className="custom-input"
						{...props}
						placeholder={this.props?.data?.placeholder || ''}
					/>
					{hasError && (
          <div className="ant-form-explain error-message">
            This field is required
          </div>
        )}
				</div>
			</div>
		);
	}
}

class NumberInput extends Component {
	constructor(props) {
		super(props);
		this.inputField = createRef();
	}

	render() {
		const props = {};
		props.type = 'number';
		props.className = 'form-control';
		props.name = this.props.data.field_name;

		if (this.props.mutable) {
			props.defaultValue = this.props.defaultValue;
			props.ref = this.inputField;
		}

		if (this.props.read_only) {
			props.disabled = 'disabled';
		}

		let baseClasses = 'SortableItem rfb-item';
		if (this.props.data.pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}

		return (
			<div className={baseClasses}>
				<ComponentHeader {...this.props} />
				<div className="custom-form-group">
					<ComponentLabel
						{...this.props}
						required={this.props?.data?.required}
					/>
					<Input
						className="custom-input"
						{...props}
						placeholder={this.props?.data?.placeholder || ''}
					/>
				</div>
			</div>
		);
	}
}

class TextArea extends Component {
	constructor(props) {
		super(props);
		this.inputField = createRef();
	}

	handleChange = (e) => {
		// Store value in formValues
		formValues[this.props.data.field_name] = {
			value: e.target.value,
			text: '',
			question: this.props.data.label,
			required: this.props.data.required || false
		};
		
		if (this.props.handleChange) {
			this.props.handleChange({
				name: this.props.data.field_name,
				value: e.target.value,
				question: this.props.data.label,
				text: '',
			});
		}
	};

	render() {
		const props = {};
		props.className = 'custom-input';
		props.name = this.props.data.field_name;
		props.onChange = this.handleChange;
		if (this.props.read_only) {
			props.disabled = 'disabled';
		}

		if (this.props.mutable) {
			props.defaultValue = this.props.defaultValue;
			props.ref = this.inputField;
		}

		let baseClasses = 'SortableItem rfb-item';
		if (this.props.data.pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}

	const hasError = this.props.errors && typeof this.props.errors === 'string' &&
    	this.props.errors?.includes(`${this.props.data.label} is required`);

		return (
			<div className={baseClasses}>
				<ComponentHeader {...this.props} />
				<div className="custom-form-group">
					<ComponentLabel
						{...this.props}
						required={this.props?.data?.required ? 'true' : null}
					/>
					<Input.TextArea
						className="custom-input"
						{...props}
						placeholder={this.props?.data?.placeholder || ''}
					/>
					{hasError && (
          <div className="ant-form-explain error-message">
            This field is required
          </div>
        )}
				</div>
			</div>
		);
	}
}
class Dropdown extends Component {
	constructor(props) {
		super(props);
		this.inputField = createRef();
		this.state = {
			value: props.defaultValue || undefined,
			text: ''
		};
	}
  
	handleChange = (value, option) => {
		const text = option?.children || '';
		
		this.setState({ 
			value,
			text
		});

		formValues[this.props.data.field_name] = {
			value,
			text,
			question: this.props.data.label,
			required: this.props.data.required || false
		};
	};

	render() {
		const props = {};
		console.log("DROPDOWN PROPS", this.props?.data);
		props.className = 'custom-input';
		props.name = this.props.data.field_name;
		props.value = this.state.value;
		
		if (this.props.read_only) {
			props.disabled = 'disabled';
		}
  
		let baseClasses = 'SortableItem rfb-item';
		if (this.props.data.pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}
  
		const { options } = this.props.data;

		const hasError = this.props.errors && typeof this.props.errors === 'string' &&
    		this.props.errors?.includes(`${this.props.data.label} is required`);

		return (
			<div className={baseClasses}>
				<ComponentHeader {...this.props} />
				<div className="custom-form-group">
					<ComponentLabel
						{...this.props}
						required={this.props?.data?.required}
					/>
					<Select
						{...props}
						className="custom-input"
						placeholder={this.props?.data?.placeholder || ''}
						onChange={this.handleChange}
						ref={this.inputField}
					>
						{options.map((option) => {
							const this_key = `preview_${option.key}`;
							return (
								<Select.Option
									key={this_key}
									value={option.value}
								>
									{option.text}
								</Select.Option>
							);
						})}
					</Select>
					{hasError && (
          <div className="ant-form-explain error-message">
            This field is required
          </div>
        )}
				</div>
			</div>
		);
	}
}

class Tags extends Component {
	constructor(props) {
		super(props);
		this.inputField = createRef();
		const { defaultValue, data } = props;
		this.state = {
			value: this.getDefaultValue(defaultValue, data.options),
		};
	}

	getDefaultValue(defaultValue, options) {
		if (defaultValue) {
			if (typeof defaultValue === 'string') {
				const vals = new Set(
					defaultValue.split(',').map((x) => x.trim()),
				);
				return options.filter((x) => vals.has(x.value));
			}

			return options.filter((x) => defaultValue.includes(x.value));
		}

		return [];
	}

	handleChange = (e) => {
		this.setState({ value: e });
	};

	render() {
		const options = this.props.data.options.map((option) => {
			option.label = option.text;
			return option;
		});
		const props = {};
		props.isMulti = true;
		props.name = this.props.data.field_name;
		props.onChange = this.handleChange;

		props.options = options;
		if (!this.props.mutable) {
			props.value = options[0].text;
		} // To show a sample of what tags looks like

		if (this.props.mutable) {
			props.isDisabled = this.props.read_only;
			props.value = this.state.value;
			props.ref = this.inputField;
		}

		let baseClasses = 'SortableItem rfb-item';
		if (this.props.data.pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}

		return (
			<div className={baseClasses}>
				<ComponentHeader {...this.props} />
				<div className="custom-form-group">
					<ComponentLabel {...this.props} />
					<Select className="custom-input" {...props} />
				</div>
			</div>
		);
	}
}

class Checkboxes extends Component {
	constructor(props) {
		super(props);
		this.options = {};
	}

	render() {
		const self = this;
		let classNames = 'custom-control custom-checkbox';
		if (this.props.data.inline) {
			classNames += ' option-inline';
		}

		let baseClasses = 'SortableItem rfb-item';
		if (this.props.data.pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}

		return (
			<div className={baseClasses}>
				<ComponentHeader {...this.props} />
				<div className="custom-form-group">
					<ComponentLabel {...this.props} />
					{this.props.data.options.map((option) => {
						const this_key = `preview_${option.key}`;
						const props = {};
						props.name = `option_${option.key}`;

						props.type = 'checkbox';
						props.value = option.value;
						if (self.props.mutable) {
							props.defaultChecked =
								self.props.defaultValue !== undefined &&
								self.props.defaultValue.includes(option.key);
						}

						if (this.props.read_only) {
							props.disabled = 'disabled';
						}

						return (
							<div key={this_key} className={classNames}>
								<input
									ref={(c) => {
										if (c && self.props.mutable) {
											self.options[
												`child_ref_${option.key}`
											] = c;
										}
									}}
									id={'fid_' + this_key}
									className="custom-input"
									{...props}
								/>
								<label htmlFor={'fid_' + this_key}>
									{option.text}
								</label>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

class RadioButtons extends Component {
	constructor(props) {
		super(props);
		this.options = {};
	}

	render() {
		const self = this;
		let classNames = 'custom-control custom-radio';
		if (this.props.data.inline) {
			classNames += ' option-inline';
		}

		let baseClasses = 'SortableItem rfb-item';
		if (this.props.data.pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}

		return (
			<div className={baseClasses}>
				<ComponentHeader {...this.props} />
				<div className="custom-form-group">
					<ComponentLabel {...this.props} />
					{this.props.data.options.map((option) => {
						const this_key = `preview_${option.key}`;
						const props = {};
						props.name = self.props.data.field_name;

						props.type = 'radio';
						props.value = option.value;
						if (self.props.mutable) {
							props.defaultChecked =
								self.props.defaultValue !== undefined &&
								(self.props.defaultValue.includes(option.key) ||
									self.props.defaultValue.includes(
										option.value,
									));
						}

						if (this.props.read_only) {
							props.disabled = 'disabled';
						}

						return (
							<div key={this_key} className={classNames}>
								<input
									ref={(c) => {
										if (c && self.props.mutable) {
											self.options[
												`child_ref_${option.key}`
											] = c;
										}
									}}
									id={'fid_' + this_key}
									className="custom-input"
									{...props}
								/>
								<label htmlFor={'fid_' + this_key}>
									{option.text}
								</label>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

function Image({ data, mutable, parent, editModeOn, _onDestroy, onEdit }) {
	const style = data.center ? { textAlign: 'center' } : null;

	let baseClasses = 'SortableItem rfb-item';
	if (data.pageBreakBefore) {
		baseClasses += ' alwaysbreak';
	}

	return (
		<div className={baseClasses} style={style}>
			{!mutable && (
				<HeaderBar
					parent={parent}
					editModeOn={editModeOn}
					data={data}
					required={data.required}
					onDestroy={_onDestroy}
					onEdit={onEdit}
				/>
			)}
			{data.src && (
				<img src={data.src} width={data.width} height={data.height} />
			)}
			{!data.src && <div className="no-image">No Image</div>}
		</div>
	);
}

class Rating extends Component {
	constructor(props) {
		super(props);
		this.inputField = createRef();
	}

	render() {
		const props = {};
		props.name = this.props.data.field_name;
		props.ratingAmount = 5;

		if (this.props.mutable) {
			props.rating =
				this.props.defaultValue === undefined
					? 0
					: Number.parseFloat(this.props.defaultValue, 10);
			props.editing = true;
			props.disabled = this.props.read_only;
			props.ref = this.inputField;
		}

		let baseClasses = 'SortableItem rfb-item';
		if (this.props.data.pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}

		return (
			<div className={baseClasses}>
				<ComponentHeader {...this.props} />
				<div className="form-group">
					<ComponentLabel {...this.props} />
					<StarRating {...props} />
				</div>
			</div>
		);
	}
}

function HyperLink(props) {
	let baseClasses = 'SortableItem rfb-item';
	if (props.data.pageBreakBefore) {
		baseClasses += ' alwaysbreak';
	}

	return (
		<div className={baseClasses}>
			<ComponentHeader {...props} />
			<div className="custom-form-group">
				<a target="_blank" href={props.data.href} rel="noreferrer">
					{props.data.content}
				</a>
			</div>
		</div>
	);
}

function Download(props) {
	let baseClasses = 'SortableItem rfb-item';
	if (props.data.pageBreakBefore) {
		baseClasses += ' alwaysbreak';
	}

	return (
		<div className={baseClasses}>
			<ComponentHeader {...props} />
			<div className="custom-form-group">
				<a href={`${props.download_path}?id=${props.data.file_path}`}>
					{props.data.content}
				</a>
			</div>
		</div>
	);
}

class Camera extends Component {
	constructor(props) {
		super(props);
		this.state = { img: null };
	}

	clearImage = () => {
		this.setState({
			img: null,
		});
	};

	displayImage = (e) => {
		const self = this;
		const { target } = e;
		let file;
		let reader;

		if (target.files && target.files.length > 0) {
			file = target.files[0];

			reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onloadend = () => {
				self.setState({
					img: reader.result,
				});
			};
		}
	};

	render() {
		let baseClasses = 'SortableItem rfb-item';
		const name = this.props.data.field_name;
		const fileInputStyle = this.state.img ? { display: 'none' } : null;
		if (this.props.data.pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}

		let sourceDataURL;
		if (
			this.props.read_only === true &&
			this.props.defaultValue &&
			this.props.defaultValue.length > 0
		) {
			sourceDataURL = this.props.defaultValue.indexOf(name > -1)
				? this.props.defaultValue
				: `data:image/png;base64,${this.props.defaultValue}`;
		}

		return (
			<div className={baseClasses}>
				<ComponentHeader {...this.props} />
				<div className="custom-form-group">
					<ComponentLabel {...this.props} />
					{this.props.read_only === true &&
					this.props.defaultValue &&
					this.props.defaultValue.length > 0 ? (
						<div>
							<img src={sourceDataURL} />
						</div>
					) : (
						<div className="image-upload-container">
							<div style={fileInputStyle}>
								<input
									name={name}
									type="file"
									accept="image/*"
									capture="camera"
									className="image-upload"
									onChange={this.displayImage}
								/>
								<div className="image-upload-control">
									<div className="btn btn-default btn-school">
										<i className="fas fa-camera" /> Upload
										Photo
									</div>
									<p>
										Select an image from your computer or
										device.
									</p>
								</div>
							</div>

							{this.state.img && (
								<div>
									<img
										src={this.state.img}
										height="100"
										className="image-upload-preview"
									/>
									<br />
									<div
										className="btn btn-school btn-image-clear"
										onClick={this.clearImage}
									>
										<i className="fas fa-times" /> Clear
										Photo
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

FormElements.Header = Header;
FormElements.Paragraph = Paragraph;
FormElements.Label = Label;
FormElements.LineBreak = LineBreak;
FormElements.TextInput = TextInput;
FormElements.NumberInput = NumberInput;
FormElements.TextArea = TextArea;
FormElements.Dropdown = Dropdown;
FormElements.Checkboxes = Checkboxes;
FormElements.RadioButtons = RadioButtons;
FormElements.Image = Image;
FormElements.Rating = Rating;
FormElements.Tags = Tags;
FormElements.HyperLink = HyperLink;
FormElements.Download = Download;
FormElements.Camera = Camera;

export default FormElements;
