import { forwardRef, Fragment, memo, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Input, Select, Switch, Tag, Card, Button, Space, Divider } from 'antd';
import { EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { RiDragMove2Fill } from 'react-icons/ri';
import dompurify from 'dompurify';
import { motion } from 'motion/react';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { preserveOffsetOnSource } from '@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';

import { useFormBuilderContext } from 'src/new-form-builder/FormBuilderContext';
import { useFormAreaContext } from 'src/new-form-builder/FormAreaContext';

const { TextArea } = Input;
const { Option } = Select;
const sanitizer = dompurify.sanitize;

const ELEMENT_TYPES = {
	INPUT: 'input',
	TEXTAREA: 'textarea',
	SELECT: 'select',
	LABEL: 'label',
	LINEBREAK: 'linebreak',
	SWITCH: 'switch',
};

const idleState = { type: 'idle' };
const draggingState = { type: 'dragging' };

const CardPrimitive = forwardRef(function CardPrimitive(
	{
		element,
		state,
		closestEdge,
		onEdit,
		onDuplicate,
		onRemove,
		dragHandleRef,
	},
	ref,
) {
	const [isHovered, setIsHovered] = useState(false);

	const renderTag = () => {
		const elementAttributes = {
			[ELEMENT_TYPES.INPUT]: {
				label: 'Text Input',
				color: 'blue',
			},
			[ELEMENT_TYPES.TEXTAREA]: {
				label: 'Multi-Line Input',
				color: 'green',
			},
			[ELEMENT_TYPES.SELECT]: {
				label: 'Dropdown',
				color: 'magenta',
			},
			[ELEMENT_TYPES.LABEL]: {
				label: 'Rich Text',
				color: 'gold',
			},
			[ELEMENT_TYPES.LINEBREAK]: {
				label: 'Line Break',
				color: 'cyan',
			},
			// Commented out until we have time to add new form elements
			// [ELEMENT_TYPES.SWITCH]: {
			//   label: 'Switch',
			//   color: 'purple'
			// }
		};

		return (
			<Tag color={elementAttributes[element.type].color}>
				{elementAttributes[element.type].label}
			</Tag>
		);
	};

	// Render the form element content based on type
	const renderElementContent = () => {
		switch (element.type) {
			case ELEMENT_TYPES.INPUT:
				return (
					<div style={{ margin: 0 }}>
						<div className="custom-label">
							{element.label || 'Input Field'}
							{element.required ? (
								<span className="custom-required">
									(required)
								</span>
							) : (
								<span className="custom-optional">
									(optional)
								</span>
							)}
						</div>
						<Input
							className="custom-input"
							placeholder={element.placeholder || 'Enter text'}
							disabled
							style={{ margin: 0, cursor: 'grab' }}
						/>
					</div>
				);
			case ELEMENT_TYPES.TEXTAREA:
				return (
					<div style={{ margin: 0 }}>
						<div className="custom-label">
							{element.label || 'Text Area'}
							{element.required ? (
								<span className="custom-required">
									(required)
								</span>
							) : (
								<span className="custom-optional">
									(optional)
								</span>
							)}
						</div>
						<TextArea
							className="custom-input"
							rows={4}
							placeholder={element.placeholder || 'Enter text'}
							disabled
							style={{ cursor: 'inherit' }}
						/>
					</div>
				);
			case ELEMENT_TYPES.SELECT:
				return (
					<div style={{ margin: 0 }}>
						<div className="custom-label">
							{element.label || 'Select'}
							{element.required ? (
								<span className="custom-required">
									(required)
								</span>
							) : (
								<span className="custom-optional">
									(optional)
								</span>
							)}
						</div>
						<Select
							className="custom-input form-builder-select"
							placeholder={
								element.placeholder || 'Select an option'
							}
							style={{ width: '100%', cursor: 'inherit' }}
							disabled
						>
							{(element.options || []).map((option, idx) => (
								<Option key={idx} value={option.value}>
									{option.label}
								</Option>
							))}
						</Select>
					</div>
				);
			case ELEMENT_TYPES.LABEL:
				return (
					<div
						className="rich-text-custom"
						style={{ margin: '0', minHeight: '16px' }}
					>
						<div
							dangerouslySetInnerHTML={{
								__html: sanitizer(element.content),
							}}
							style={{ display: 'inline' }}
						/>
					</div>
				);
			case ELEMENT_TYPES.LINEBREAK:
				return <Divider style={{ margin: '8px 0' }} />;
			// Commented out until we have time to add new form elements
			// case ELEMENT_TYPES.SWITCH:
			//   return (
			//     <div style={{ margin: '0' }}>
			//       <div className="custom-label">
			//         {element.label || 'Switch'}
			//         {element.required
			//           ? <span className="custom-required">(required)</span>
			//           : <span className="custom-optional">(optional)</span>
			//         }
			//       </div>
			//       <Switch style={{ cursor: 'inherit' }} className="custom-input" disabled />
			//     </div>
			//   );
			default:
				return null;
		}
	};

	const getCardStyle = () => {
		const baseStyle = {
			borderRadius: '10px',
			transition: 'border-color 0.3s ease, opacity 0.3s ease',
		};

		switch (state.type) {
			case 'dragging':
				return {
					...baseStyle,
					opacity: 0.4,
					border: '1px solid #ffffff',
				};
			case 'preview':
				return {
					...baseStyle,
					boxShadow: 'none',
					border: '1px solid #ffffff',
				};
			default:
				return {
					...baseStyle,
					cursor: 'grab',
					'--shadow-color': '0deg 0% 60%',
					border: '1px solid ' + (isHovered ? '#242E3F' : '#ffffff'),
					boxShadow: '0 1px 3px #0000001a,0 1px 2px #0000000f',
				};
		}
	};

	return (
		<motion.div
			ref={ref}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			style={{ position: 'relative' }}
		>
			<Card
				ref={dragHandleRef}
				size="small"
				headStyle={{ borderRadius: '10px' }}
				bodyStyle={{ borderRadius: '10px' }}
				style={getCardStyle()}
				className={`form-element-card ${state.type === 'dragging' ? 'dragging' : ''}`}
				data-testid={`element-${element.id}`}
			>
				{renderElementContent()}
				{closestEdge && <DropIndicator edge={closestEdge} gap="8px" />}
			</Card>

			{/* White overlay to help contrast the edit buttons on hover */}
			{state.type !== 'dragging' && isHovered && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 0.6 }}
					transition={{ duration: 0.3 }}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'white',
						borderRadius: '10px',
						pointerEvents: 'none',
						zIndex: 10,
						border: 'none',
					}}
				/>
			)}

			{/* Render overlays only when not dragging and hovered */}
			{state.type !== 'dragging' && isHovered && (
				<>
					{/* Left overlay: Tag with element type */}
					<motion.div
						initial={{ opacity: 0, x: -20 }}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 0.3 }}
						style={{
							position: 'absolute',
							top: 10,
							left: 13,
							pointerEvents: 'none',
							zIndex: 20,
						}}
					>
						{renderTag()}
					</motion.div>
					{/* Right overlay: Control buttons */}
					<motion.div
						initial={{ opacity: 0, x: 20 }}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 0.3 }}
						style={{
							position: 'absolute',
							top: 9,
							right: 8,
							zIndex: 20,
						}}
					>
						<Space size={2}>
							{element.type !== ELEMENT_TYPES.LINEBREAK && (
								<Button
									icon={<EditOutlined />}
									size="small"
									onClick={onEdit}
									style={{
										backgroundColor: 'transparent',
										border: 'none',
										color: '#242E3F',
										boxShadow: 'none',
										padding: 0,
									}}
									onMouseEnter={(e) => {
										e.currentTarget.style.backgroundColor =
											'transparent';
										e.currentTarget.style.color = '#4096ff';
									}}
									onMouseLeave={(e) => {
										e.currentTarget.style.backgroundColor =
											'transparent';
										e.currentTarget.style.color = '#242E3F';
									}}
								/>
							)}
							<Button
								icon={<CopyOutlined />}
								size="small"
								onClick={onDuplicate}
								style={{
									backgroundColor: 'transparent',
									border: 'none',
									color: '#242E3F',
									boxShadow: 'none',
									padding: 0,
								}}
								onMouseEnter={(e) => {
									e.currentTarget.style.backgroundColor =
										'transparent';
									e.currentTarget.style.color = '#4096ff';
								}}
								onMouseLeave={(e) => {
									e.currentTarget.style.backgroundColor =
										'transparent';
									e.currentTarget.style.color = '#242E3F';
								}}
							/>
							<Button
								icon={<DeleteOutlined />}
								size="small"
								danger
								onClick={onRemove}
								style={{
									backgroundColor: 'transparent',
									border: 'none',
									color: '#EF3C3F',
									boxShadow: 'none',
									padding: 0,
								}}
								onMouseEnter={(e) => {
									e.currentTarget.style.backgroundColor =
										'transparent';
									e.currentTarget.style.color = '#ff7875';
								}}
								onMouseLeave={(e) => {
									e.currentTarget.style.backgroundColor =
										'transparent';
									e.currentTarget.style.color = '#EF3C3F';
								}}
							/>
						</Space>
					</motion.div>
					{/* Center overlay: "Hold to drag" text */}
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.3 }}
						style={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							pointerEvents: 'none',
							zIndex: 15,
							color: '#172b4d',
							fontWeight: 'bold',
							fontSize: '14px',
							display: 'flex',
							alignItems: 'center',
							verticalAlign: 'middle',
							gap: '4px',
						}}
					>
						<RiDragMove2Fill
							size={16}
							style={{ display: 'block' }}
						/>
						<span style={{ display: 'block', lineHeight: '1' }}>
							Drag
						</span>
					</motion.div>
				</>
			)}
		</motion.div>
	);
});

// Main Card component that adds drag-and-drop behavior
export const FormElementCard = memo(function FormElementCard({
	element,
	index,
	onEdit,
	onDuplicate,
	onRemove,
}) {
	const ref = useRef(null);
	const dragHandleRef = useRef(null);
	const [closestEdge, setClosestEdge] = useState(null);
	const [state, setState] = useState(idleState);

	const { instanceId, registerElement } = useFormBuilderContext();
	const { formAreaId } = useFormAreaContext();

	useEffect(() => {
		if (!ref.current || !dragHandleRef.current) return;

		return registerElement({
			elementId: element.id,
			entry: {
				element: ref.current,
				dragHandle: dragHandleRef.current,
			},
		});
	}, [element.id, registerElement]);

	useEffect(() => {
		const cardElement = ref.current;
		if (!cardElement) return;

		return combine(
			draggable({
				element: cardElement,
				dragHandle: dragHandleRef.current,
				getInitialData: () => ({
					type: 'form-element',
					elementId: element.id,
					index,
					instanceId,
					formAreaId,
				}),
				onGenerateDragPreview: ({ location, nativeSetDragImage }) => {
					const rect = cardElement.getBoundingClientRect();

					setCustomNativeDragPreview({
						nativeSetDragImage,
						getOffset: preserveOffsetOnSource({
							element: cardElement,
							input: location.current.input,
						}),
						render({ container }) {
							setState({ type: 'preview', container, rect });
							return () => setState(draggingState);
						},
					});
				},
				onDragStart: () => setState(draggingState),
				onDrop: () => setState(idleState),
			}),
			dropTargetForElements({
				element: cardElement,
				canDrop: ({ source }) => {
					return (
						source.data.instanceId === instanceId &&
						(source.data.type === 'form-element' ||
							source.data.type === 'sidebar-element') &&
						(source.data.type !== 'form-element' ||
							source.data.elementId !== element.id)
					);
				},
				getIsSticky: () => true,
				getData: ({ input, element }) => {
					const data = {
						type: 'form-element',
						elementId: element.id,
						index,
						formAreaId,
					};

					return attachClosestEdge(data, {
						input,
						element,
						allowedEdges: ['top', 'bottom'],
					});
				},
				onDragEnter: (args) => {
					if (
						args.source.data.type !== 'form-element' ||
						args.source.data.elementId !== element.id
					) {
						setClosestEdge(extractClosestEdge(args.self.data));
					}
				},
				onDrag: (args) => {
					if (
						args.source.data.type !== 'form-element' ||
						args.source.data.elementId !== element.id
					) {
						setClosestEdge(extractClosestEdge(args.self.data));
					} else {
						setClosestEdge(null);
					}
				},
				onDragLeave: () => {
					setClosestEdge(null);
				},
				onDrop: () => {
					setClosestEdge(null);
				},
			}),
		);
	}, [element.id, formAreaId, index, instanceId, registerElement]);

	return (
		<Fragment>
			<motion.div
				className="form-element-card-wrapper"
				data-element-id={element.id}
				data-index={index}
				style={{
					opacity: state.type === 'dragging' ? 0.4 : 1,
				}}
			>
				<CardPrimitive
					ref={ref}
					element={element}
					state={
						state.type === 'dragging' ? draggingState : idleState
					}
					closestEdge={closestEdge}
					onEdit={onEdit}
					onDuplicate={onDuplicate}
					onRemove={onRemove}
					dragHandleRef={dragHandleRef}
				/>
			</motion.div>
			{state.type === 'preview' &&
				ReactDOM.createPortal(
					<div
						style={{
							boxSizing: 'border-box',
							width: state.rect.width,
							height: state.rect.height,
						}}
					>
						<CardPrimitive
							element={element}
							state={{ type: 'preview' }}
							closestEdge={null}
							onEdit={onEdit}
							onDuplicate={onDuplicate}
							onRemove={onRemove}
						/>
					</div>,
					state.container,
				)}
		</Fragment>
	);
});
