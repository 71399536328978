/**
 * Registering cards and their action menu trigger element,
 * so that we can restore focus to the trigger when a card moves between columns.
 */
export function createRegistry() {
	const elements = new Map();
	const columns = new Map();

	function registerElement({ elementId, entry }) {
		elements.set(elementId, entry);
		return function cleanup() {
			elements.delete(elementId);
		};
	}

	function registerColumn({ columnId, entry }) {
		columns.set(columnId, entry);
		return function cleanup() {
			columns.delete(columnId);
		};
	}

	function getElement(elementId) {
		const entry = elements.get(elementId);
		if (!entry) {
			console.warn(`Element with ID ${elementId} not found in registry`);
			return null;
		}
		return entry;
	}

	function getColumn(columnId) {
		const entry = columns.get(columnId);
		if (!entry) {
			console.warn(`Column with ID ${columnId} not found in registry`);
			return null;
		}
		return entry;
	}

	return { registerElement, registerColumn, getElement, getColumn };
}
