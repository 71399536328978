import { useState } from 'react';
import {
	Button,
	Card,
	Form,
	Input,
	Select,
	Switch,
	Divider,
	Col,
	Row,
} from 'antd';
import { ToggleEmailPhone } from 'src/_shared/referral-modal/referral-modal-form/ToggleEmailPhoneComponent.jsx';
import PhoneInput from 'react-phone-input-2';
import { ml } from 'src/_shared/services/utils.js';
import 'react-phone-input-2/lib/bootstrap.css';
import { LuCheck } from 'react-icons/lu';
import { motion } from 'motion/react';
import dompurify from 'dompurify';

const { TextArea } = Input;
const { Option } = Select;
const sanitizer = dompurify.sanitize;

const ELEMENT_TYPES = {
	INPUT: 'input',
	TEXTAREA: 'textarea',
	SELECT: 'select',
	LABEL: 'label',
	LINEBREAK: 'linebreak',
	// Commented out until we have time to add new form elements
	//SWITCH: 'switch'
};

const renderElementLabel = (element, currentUser, allMultiLingualData) => {
	if (
		element.type === ELEMENT_TYPES.LABEL ||
		element.type === ELEMENT_TYPES.LINEBREAK
	) {
		return null;
	}
	const label = (
		<>
			{element.label}
			{element.required ? (
				<span className="custom-required">
					{ml('(required)', currentUser, allMultiLingualData)}
				</span>
			) : (
				<span className="custom-optional">
					{ml('(optional)', currentUser, allMultiLingualData)}
				</span>
			)}
		</>
	);
	return label;
};

const DefaultElements = ({
	formMetadata,
	formType,
	currentUser,
	allMultiLingualData,
}) => {
	const [referralType, setReferralType] = useState('email');
	const [countryCode, setCountryCode] = useState(
		// TODO: stop casting to lower case after removing react-phone-input-2
		(currentUser?.company?.countryCode ?? 'US').toLowerCase(),
	);
	const titleSection = (
		<h4
			className="ant-modal-sub-title"
			style={{
				color: '#000000',
				fontSize: '16px',
				fontWeight: 700,
				marginBottom: '10px',
			}}
		>
			{ml('Enter Referral Information', currentUser, allMultiLingualData)}
		</h4>
	);
	const acceptanceTitleSection = (
		<div style={{ textAlign: 'center' }}>
			<h4
				className="ant-modal-sub-title"
				style={{
					color: '#000000',
					fontSize: '16px',
					fontWeight: 700,
					marginBottom: '10px',
				}}
			>
				{ml(
					'To accept your referral and apply to the position, we need more information.',
					currentUser,
					allMultiLingualData,
				)}
			</h4>
		</div>
	);
	const nameSection = (
		<Row gutter={12}>
			<Col xs={24} md={12}>
				<div className="custom-form-group">
					<Form.Item>
						<Input
							className="custom-input"
							placeholder={ml(
								'First Name',
								currentUser,
								allMultiLingualData,
							)}
						/>
					</Form.Item>
				</div>
			</Col>
			<Col xs={24} md={12}>
				<div className="custom-form-group">
					<Form.Item>
						<Input
							className="custom-input"
							placeholder={ml(
								'Last Name',
								currentUser,
								allMultiLingualData,
							)}
						/>
					</Form.Item>
				</div>
			</Col>
		</Row>
	);
	const emailSection = (
		<div className="custom-form-group">
			<Form.Item>
				<Input
					className="custom-input"
					placeholder={ml(
						'Email Address',
						currentUser,
						allMultiLingualData,
					)}
				/>
			</Form.Item>
		</div>
	);
	const phoneNumberSection = (
		<div className="custom-form-group">
			<Form.Item>
				<PhoneInput
					disableCountryGuess
					dropdownStyle={{
						lineHeight: 1,
					}}
					inputProps={{
						className: 'ant-input custom-input',
					}}
					inputStyle={{
						paddingLeft: '50px',
					}}
					country={countryCode}
					masks={{ cn: '... .... ....' }}
					onChange={(value, country, e, formattedValue) => {
						setCountryCode(country);
					}}
				/>
			</Form.Item>
		</div>
	);

	const toggleEmailPhoneSection = (
		<Row gutter={12}>
			<Col xs={24}>
				<div className="custom-form-group" style={{ marginBottom: 0 }}>
					<div className="send-by">
						<ToggleEmailPhone
							visible
							referralType={referralType}
							sendByText={'Send By'}
							currentUser={currentUser}
							allMultiLingualData={allMultiLingualData}
							inputLabel={false}
							onClick={(referralType) => {
								setReferralType(referralType);
							}}
						/>
						{referralType === 'email' || referralType === null
							? emailSection
							: phoneNumberSection}
					</div>
				</div>
			</Col>
		</Row>
	);
	const messageSection = (
		<>
			<div className="custom-form-group">
				<Form.Item
					key="message-to-contact"
					label={renderElementLabel(
						{
							type: ELEMENT_TYPES.TEXTAREA,
							label: ml(
								'Include a message to your contact',
								currentUser,
								allMultiLingualData,
							),
							required: false,
						},
						currentUser,
						allMultiLingualData,
					)}
					style={{ marginBottom: '16px' }}
				>
					<TextArea
						className="custom-input"
						placeholder={ml(
							'Personalize the message to your referral',
							currentUser,
							allMultiLingualData,
						)}
						rows={1}
					/>
				</Form.Item>
			</div>
			{!currentUser?.company?.hideMessageRecruiter && (
				<div className="custom-form-group">
					<Form.Item
						key="message-to-recruiter"
						label={renderElementLabel(
							{
								type: ELEMENT_TYPES.TEXTAREA,
								label: ml(
									'Message the Recruiter',
									currentUser,
									allMultiLingualData,
								),
								required: false,
							},
							currentUser,
							allMultiLingualData,
						)}
						style={{ marginBottom: '16px' }}
					>
						<TextArea
							className="custom-input"
							placeholder={ml(
								'How do you know them, why are they a good fit, etc.',
								currentUser,
								allMultiLingualData,
							)}
							rows={1}
						/>
					</Form.Item>
				</div>
			)}
		</>
	);

	if (formType === 'Referral' || formType === 'General') {
		return (
			<>
				{titleSection}
				{nameSection}
				{toggleEmailPhoneSection}
				{messageSection}
			</>
		);
	}

	if (formType === 'Interested') {
		return null;
	}

	if (formType === 'Acceptance') {
		return (
			<>
				{acceptanceTitleSection}
				{nameSection}
				{emailSection}
				{formMetadata.askForPhoneNumber && phoneNumberSection}
			</>
		);
	}
};

const changeReferralType = (referralType) => {
	console.log(referralType);
};

const AnimatedFormElement = ({ element, currentUser, allMultiLingualData }) => {
	return (
		<motion.div
			key={element.id + '-container'}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.3 }}
			layout
			className="custom-form-group"
		>
			<Form.Item
				key={element.id}
				label={renderElementLabel(
					element,
					currentUser,
					allMultiLingualData,
				)}
				required={element.required}
				style={{ marginBottom: '16px' }}
			>
				{renderFormElement(element)}
			</Form.Item>
		</motion.div>
	);
};

const FormPreview = ({
	elements,
	formMetadata,
	formType,
	currentUser,
	allMultiLingualData,
}) => {
	const formKey = `form-${formType}-${formMetadata?.id || 'default'}`;

	return (
		<Card
			title={<span style={{ fontWeight: 900 }}>Live Preview</span>}
			size="small"
			headStyle={{ padding: 0 }}
			style={{ height: '100%', overflowY: 'auto', border: 'none' }}
		>
			<motion.div
				key={formKey}
				className="form-preview-content"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.5 }}
			>
				<Form
					layout="vertical"
					requiredMark={false} // Getting rid of the default asterisk for accessibility
				>
					<DefaultElements
						formMetadata={formMetadata}
						formType={formType}
						currentUser={currentUser}
						allMultiLingualData={allMultiLingualData}
					/>

					<motion.div
						layout
						initial={{ opacity: 1 }}
						animate={{ opacity: 1 }}
					>
						{elements.map((element) => (
							<AnimatedFormElement
								key={element.id}
								element={element}
								currentUser={currentUser}
								allMultiLingualData={allMultiLingualData}
							/>
						))}
					</motion.div>

					{formMetadata.askForResume && (
						<div
							className="click-to-upload"
							style={{ marginBottom: '16px' }}
						>
							<label htmlFor="resumeFile">
								<span className="link">{'Click here '}</span>
								<input
									type="file"
									accept=".doc,.docx,application/msword,.pdf,application/pdf"
								/>
								to attach a resume
								{formMetadata.resumeRequired ? (
									<span className="custom-required">
										(required)
									</span>
								) : (
									<span className="custom-optional">
										(optional)
									</span>
								)}
							</label>
						</div>
					)}

					{formType === 'Interested' &&
						formMetadata.showUserEmail && (
							<div
								className="email-with-label"
								style={{
									color: 'var(--oslo-gray)',
									marginBottom: '16px',
								}}
							>
								<span className="email-withlabel">
									You must apply using this email
									address:{' '}
								</span>
								<div style={{ fontStyle: 'italic' }}>
									user.email@example.com
								</div>
							</div>
						)}

					<motion.div className="pb-container override-svg-styles">
						<Form.Item>
							<Button
								type="primary"
								className="pb-button w-auto"
								icon={formType === 'Acceptance' && <LuCheck />}
							>
								{formType === 'Interested' &&
									'Submit Me For This Position'}
								{formType === 'Acceptance' && 'Accept & Apply'}
								{formType === 'Referral' && 'Submit Referral'}
								{formType === 'General' &&
									'Submit General Referral'}
							</Button>
						</Form.Item>
					</motion.div>
				</Form>
			</motion.div>
		</Card>
	);
};

const renderFormElement = (element) => {
	switch (element.type) {
		case ELEMENT_TYPES.INPUT:
			return (
				<Input
					placeholder={element.placeholder || 'Enter text'}
					className="custom-input"
				/>
			);
		case ELEMENT_TYPES.TEXTAREA:
			return (
				<TextArea
					rows={element.rows || 4}
					placeholder={element.placeholder || 'Enter text'}
					className="custom-input"
				/>
			);
		case ELEMENT_TYPES.SELECT:
			return (
				<Select
					style={{ width: '100%' }}
					placeholder={element.placeholder || 'Select an option'}
					className="custom-input"
				>
					{(element.options || []).map((option, idx) => (
						<Option key={idx} value={option.value}>
							{option.label}
						</Option>
					))}
				</Select>
			);
		case ELEMENT_TYPES.LABEL:
			return (
				<div className="rich-text-custom" style={{ margin: '0' }}>
					<div
						dangerouslySetInnerHTML={{
							__html: sanitizer(element.content),
						}}
						style={{ display: 'inline', margin: 0 }}
					/>
				</div>
			);
		case ELEMENT_TYPES.LINEBREAK:
			return <Divider style={{ margin: '8px 0' }} />;
		// Commented out until we have time to add new form elements
		// case ELEMENT_TYPES.SWITCH:
		//   return <Switch />;
		default:
			return null;
	}
};

export default FormPreview;
