import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Row, message } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ProgressButton from 'react-progress-button';
import { updateContact } from 'src/_shared/api/graphql/custom/contacts/';
import { GetJobReferralContacts } from 'src/_shared/api/graphql/custom/jobs/';
import { queryReferralQuestionsByCompanyId } from 'src/_shared/api/graphql/custom/referral-questions/';
import { JobLocation } from 'src/_shared/index.js';
import {
	ml,
	parse,
	uploadToS3Multipart,
	fetchModernFormQuestions,
} from 'src/_shared/services/utils.js';
import FormElements, { formValues } from 'src/form-builder/FormElements.jsx';
import uuid from 'uuid/v4';
import queryReferralsByUserIdReferralTypeIndex from '../../../my-applications/listReferralsByUserIdReferralTypeGraphql.js';
import * as ModalStyles from './imInterestedSendStyles.js';
import { checkCircle, linkStyles } from './imInterestedSendStyles.js';
const inputs = {};
class ImInterestedButtonWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			visibleApplied: false,
			isSubmitting: false,
			showCompleteModal: false,
			newContact: get(props, 'newContact', false),
			contactId: get(props, 'currentUser.id'),
			buttonState: '',
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			referralQuestions: [],
			resumeAttachData: '',
			interestedQuestionData: '',
			isError: false,
			filename: null,
			filetype: null,
			errors: [],
			originalFile: null,
			showAppliedModal: false,
			careerProfile: JSON.parse(
				get(props, 'currentUser.careerProfile', '{}'),
			),
			selfReferrals: null,
			isAlreadyComplete: false,
			uniqueId: uuid(),
		};
	}

	async componentDidMount() {
		if (this.getCompletionPercentage() == '100%') {
			this.setState({ isAlreadyComplete: true });
		}
	}

	onDelete = () => {
		this.setState({
			originalFile: null,
			filename: null,
			filetype: null,
			errors: [],
		});
	};
	onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			const errors = [];

			// Check file type
			const isValidFileType =
				file.type === 'application/msword' ||
				file.type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				file.type === 'application/pdf';

			if (!isValidFileType) {
				errors.push('You can only upload doc, docx, pdf files!');
			}

			// Check file size
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
				errors.push('File size must be smaller than 2MB!');
			}

			this.setState({
				filename: file.name,
				filetype: file.type,
				originalFile: file,
				errors: errors,
			});

			return errors.length === 0;
		} else {
			this.setState({
				filename: null,
				filetype: null,
				originalFile: null,
				errors: [],
			});
			return false;
		}
	};

	onPhoneNumberChange = () => {};
	getInputElement(item, index) {
		const Input = FormElements[item.element];
		return (
			<div key={index}>
				<Input
					ref={(c) => (inputs[item.field_name] = c)}
					key={`form_${item.id}`}
					mutable
					data={item}
					errors={this.state.errors}
					read_only={this.props.read_only}
				/>
			</div>
		);
	}

	async getReferralQuestions(
		policy = 'network-only',
		nextToken = null,
		allQuestions = [],
	) {
		const { client, currentUser } = this.props;
		try {
			const modernQuestions = await fetchModernFormQuestions(
				currentUser,
				'Interested',
			);
			if (modernQuestions && modernQuestions.length > 0) {
				this.setState({
					referralQuestions: _.sortBy(modernQuestions, ['sortOrder'])
						.filter((question) => question.sortOrder !== 0)
						.map((questionItem) => {
							if (typeof questionItem.questions === 'string') {
								try {
									return JSON.parse(questionItem.questions);
								} catch (e) {
									return questionItem.questions;
								}
							}
							return questionItem.questions;
						}),
					interestedQuestionData: modernQuestions.filter(
						(item) => item.sortOrder === -1,
					),
					resumeAttachData: modernQuestions.filter(
						(question) => question.sortOrder === 0,
					),
				});
			} else {
				const { data } = await client.query({
					query: gql(queryReferralQuestionsByCompanyId),
					variables: {
						companyId: get(currentUser, 'companyId'),
						after: nextToken,
					},
					fetchPolicy: policy,
				});
				const questions = [
					...data.queryReferralQuestionsByCompanyId.items,
				];
				const token = get(
					data,
					'queryJobsByCompanyIdDateIndex.nextToken',
					null,
				);
				allQuestions = [...allQuestions, ...questions];
				if (token) {
					this.getReferralQuestions(policy, token, allQuestions);
				}
				let allQuestionsSort = _.sortBy(allQuestions, ['sortOrder']);
				const attachmentResumeQuestion = allQuestionsSort.filter(
					(item) => item.sortOrder === 0,
				);
				const interestedQuestion = allQuestionsSort.filter(
					(item) => item.sortOrder === -1,
				);
				allQuestionsSort = allQuestionsSort.filter(
					(item) => item.sortOrder !== 0 && item.sortOrder !== -1,
				);
				const allQuestionsForInterested = allQuestionsSort.filter(
					(item) => item.isInterested === true,
				);
				const questionsData = [];
				if (allQuestionsForInterested.length > 0) {
					allQuestionsForInterested.map((element) => {
						questionsData.push(JSON.parse(element.questions));
					});
				}
				this.setState({
					referralQuestions: questionsData,
					resumeAttachData: attachmentResumeQuestion,
					interestedQuestionData: interestedQuestion,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	getSimpleElement(item, index) {
		const Element = FormElements[item.element];
		return <Element key={index} mutable data={item} />;
	}

	_collectFormData(data) {
		const STATIC_ELEMENTS = ['Label', 'LineBreak'];
		const formData = [];
		let errors = '';

		const inputElements = data.filter(
			(item) =>
				item && !STATIC_ELEMENTS.includes(item.element) && !item.static,
		);

		for (const item of inputElements) {
			if (formValues[item.field_name]) {
				const storedData = formValues[item.field_name];

				if (
					item.required &&
					(!storedData.value || storedData.value === '')
				) {
					errors += `${item.label || 'Field'} is required. `;
				}
				formData.push({
					name: item.field_name,
					value: storedData.value || '',
					question: item.label || storedData.question || '',
					text: storedData.text || '',
				});
				continue;
			} else if (item.required) {
				errors += `${item.label || 'Field'} is required. `;
			}
		}

		return {
			formData,
			errors,
		};
	}

	checkIsAlreadyReferredToJob = async () => {
		try {
			const { client, currentUser, job } = this.props;
			const { data } = await client.query({
				query: GetJobReferralContacts,
				variables: {
					id: job.id,
				},
				fetchPolicy: 'network-only',
			});
			const Job = get(data, 'getJob', []);
			const isAlreadyReferredToJob = get(Job, 'referrals', []).find(
				(referral) =>
					get(referral, 'contact', false) &&
					get(referral, 'contact.emailAddress', false) ===
						get(currentUser, 'emailAddress', false),
			);
			return Boolean(isAlreadyReferredToJob);
		} catch (error) {
			console.log(error);
		}
	};

	getCompletionPercentage = (value) => {
		let percentage = 0;

		if (this.state?.selfReferrals?.length > 0 || value == 'submitting') {
			percentage += 25;
		}

		if (this.state.careerProfile?.skills?.length) {
			percentage += 25;
		}

		if (this.state.careerProfile?.employment?.length) {
			percentage += 25;
		}

		if (this.props.currentUser?.openToNewRole) {
			percentage += 25;
		}

		return `${percentage}%`;
	};

	async getReferralsOfCurrentUser(
		policy = 'network-only',
		nextToken = null,
		selfReferrals = [],
	) {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: queryReferralsByUserIdReferralTypeIndex,
					variables: {
						userId,
						referralType: 'self',
						nextToken,
					},
					fetchPolicy: policy,
				});
				const referrals = [
					...data.queryReferralsByUserIdReferralTypeIndex.items,
				];
				const token = get(
					data,
					'queryReferralsByUserIdReferralTypeIndex.nextToken',
					null,
				);
				selfReferrals = [...selfReferrals, ...referrals];
				let allSelfReferrals = [];

				if (token) {
					await this.getReferralsOfCurrentUser(
						policy,
						token,
						selfReferrals,
					);
				}

				allSelfReferrals = _.sortBy(selfReferrals, [
					'referralDate',
				]).reverse();
				this.setState({
					selfReferrals: allSelfReferrals.filter(
						(referral) => referral.job,
					),
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	handleCancel = () => {
		this.setState({
			visible: false,
			visibleApplied: false,
			showCompleteModal: false,
			buttonState: '',
			originalFile: [],
			filename: null,
			filetype: null,
			errors: [],
		});
	};

	handleSubmit = async (isResumeRequired = false) => {
		const { job, onCreateReferral, currentUser, internalJobLink } =
			this.props;
		const { filename, originalFile, referralQuestions } = this.state;
		const percentage = this.getCompletionPercentage('submitting');
		try {
			if (
				internalJobLink !== null &&
				internalJobLink !== undefined &&
				!referralQuestions
			) {
				this.setState({
					showCompleteModal: false,
					visible: false,
					buttonState: 'success',
				});
				this.redirectToInternalJob(internalJobLink);
				return;
			} else {
				const matchingContact = this.props.contacts.find((contact) => {
					if (contact.emailAddress) {
						return (
							contact.emailAddress.toLowerCase() ===
							currentUser.emailAddress.toLowerCase()
						);
					}
				});
				const contactExists = Boolean(matchingContact);
				const contactId = matchingContact ? matchingContact.id : null;
				const interestedQuestionsData =
					this._collectFormData(referralQuestions).formData;

				const isEmptySelect = interestedQuestionsData.some(
					(item) =>
						item.name &&
						typeof item.name === 'string' &&
						item.name.includes('dropdown') &&
						item.text === 'Select' &&
						item.value === '0',
				);

				if (await this.checkIsAlreadyReferredToJob()) {
					if (
						internalJobLink !== null &&
						internalJobLink !== undefined
					) {
						this.setState({
							visible: false,
							visibleApplied: false,
							showCompleteModal: false,
							buttonState: 'success',
							originalFile: [],
							filename: null,
							filetype: null,
							errors: [],
						});
						this.redirectToInternalJob(internalJobLink);
						return;
					} else {
						this.setState({
							visible: false,
							visibleApplied: false,
							showCompleteModal: false,
							buttonState: 'success',
							originalFile: [],
							filename: null,
							filetype: null,
							errors: [],
						});
						return;
					}
				} else if (contactExists) {
					const quesErrors =
						this._collectFormData(referralQuestions).errors;

					if (quesErrors.length > 0 || isEmptySelect) {
						message.error(
							'Please check all the required fields',
							5,
						);
						this.handleError();
						return;
					}

					if (isResumeRequired && !filename) {
						message.error('Please upload your resume.', 5);
						this.handleError();
						return;
					}

					this.toggleIsSubmitting();
					this.showAnimation().then(async () => {
						await onCreateReferral({
							input: {
								companyId: currentUser.companyId,
								contactId,
								userId: currentUser.id,
								jobId: job.id,
								status: 'accepted',
								note: null,
								message: null,
								referralSource: job?.isGeneralReferral
									? 'general'
									: 'direct',
								referralType: 'self',
								interestedQuestionsData: JSON.stringify(
									interestedQuestionsData,
								),
							},
						});
						if (
							percentage == '100%' &&
							!this.state.isAlreadyComplete
						) {
							this.setState({ isAlreadyComplete: true });
						}
						if (
							internalJobLink !== null &&
							internalJobLink !== undefined
						) {
							this.setState({
								showCompleteModal: false,
								visible: false,
								buttonState: '',
							});
							this.redirectToInternalJob(internalJobLink);
							return;
						} else {
							this.setState({
								visible: false,
								buttonState: '',
							});
							return;
						}
					});
				} else {
					const quesErrors =
						this._collectFormData(referralQuestions).errors;
					if (quesErrors.length > 0 || isEmptySelect) {
						message.error(
							'Please check all the required fields',
							5,
						);
						this.handleError();
						return;
					}

					if (isResumeRequired && !filename) {
						message.error('Please upload your resume.', 5);
						this.handleError();
						return;
					}

					this.toggleIsSubmitting();
					this.showAnimation().then(() => {
						this.props
							.ImportedCreateContact({
								input: {
									firstName: currentUser.firstName,
									lastName: currentUser.lastName,
									emailAddress: get(
										currentUser,
										'emailAddress',
										'',
									).toLowerCase(),
									userId: currentUser.id,
									companyId: currentUser.companyId,
									importMethod: 'email',
								},
							})
							.then(async (response) => {
								const contactId = get(
									response,
									'data.createContact.id',
								);
								const d = new Date();
								const dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
								if (filename) {
									const contactResume = {
										bucket: 'erin-documents',
										key: `resumes/${contactId}/${dformat + '-' + filename}`,
										region: 'us-east-2',
									};
									await uploadToS3Multipart(
										originalFile,
										contactResume.key,
										contactResume.bucket,
									);
									await this.updateExistingContact({
										id: contactId,
										contactResume,
									});
								}
								await onCreateReferral({
									input: {
										companyId: currentUser.companyId,
										contactId,
										userId: currentUser.id,
										jobId: job.id,
										status: 'accepted',
										note: null,
										message: null,
										referralType: 'self',
										interestedQuestionsData: JSON.stringify(
											interestedQuestionsData,
										),
									},
								});
								if (
									percentage == '100%' &&
									!this.state.isAlreadyComplete
								) {
									this.setState({ isAlreadyComplete: true });
								}
								if (
									internalJobLink !== null &&
									internalJobLink !== undefined
								) {
									this.handleError();
									this.redirectToInternalJob(internalJobLink);
								} else {
									this.setState({
										visible: false,
										buttonState: '',
									});
								}
							});
					});
				}
			}
		} catch (e) {
			console.log(e);
			this.handleError();
		}
	};

	handleError = () => {
		this.setState({ buttonState: 'error' }, () => {
			setTimeout(() => {
				this.setState({ buttonState: '' });
			}, 1000);
		});
	};

	inputs = {};

	redirectToInternalJob = (internalJobLink) => {
		window.open(internalJobLink, '_blank');
	};

	showAnimation = () => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				try {
					this.toggleIsSubmitting();
					this.handleCancel();
					resolve();
				} catch (error) {
					reject(error);
				}
			}, 2500);
		});
	};

	showModal = async () => {
		await this.getReferralsOfCurrentUser('');
		await this.getReferralQuestions();
		const isAlreadyReferred = await this.checkIsAlreadyReferredToJob();
		if (isAlreadyReferred || this.state.submitted) {
			this.setState({
				buttonState: '',
				visibleApplied: true,
			});
		} else {
			setTimeout(() => {
				this.setState({
					visible: true,
				});
			}, 250);
		}
	};

	toggleIsSubmitting = () => {
		this.setState({ buttonState: 'loading' });
		this.setState((prevState) => ({
			isSubmitting: !prevState.isSubmitting,
		}));
		setTimeout(() => {
			this.setState({ buttonState: 'success' });
		}, 2000);
	};

	updateExistingContact = async (contact) => {
		await this.props.client.mutate({
			mutation: gql(updateContact),
			variables: { input: contact },
		});
	};

	render() {
		const {
			visible,
			visibleApplied,
			showCompleteModal,
			referralQuestions,
			resumeAttachData,
			filename,
			uniqueId,
		} = this.state;
		const { allMultiLingualData, currentUser, internalJobLink, job } =
			this.props;
		const companyId = get(job, 'company.id');
		let modalTxt = '';
		let applyText = '';
		if (internalJobLink !== null && internalJobLink !== undefined) {
			modalTxt =
				'You will be directed to our job site to apply for this position.';
			applyText = 'Apply On Job Site';
		} else {
			modalTxt =
				'Let us know that you are interested in this position and we will be in touch.';
			applyText = 'Submit Me For This Position';
		}

		const toggleClass = ModalStyles.ModalStyles;
		const disabled = get(this.props, 'disabled', false);
		const resumeData = {};
		let isResumeOptional = false;
		let isResumeRequired = false;
		let isUserEmail = false;
		if (resumeAttachData.length > 0) {
			const interestedFormResumeData = resumeAttachData.filter(
				(item) => item.isInterested === true && item.sortOrder === 0,
			);
			if (interestedFormResumeData.length > 0) {
				Object.assign(resumeData, ...interestedFormResumeData);
				const resumeAttachmentData = parse(
					get(resumeData, 'questions'),
				);
				for (const key in resumeAttachmentData) {
					if (resumeAttachmentData.hasOwnProperty(key)) {
						switch (key) {
							case 'Resume required': {
								isResumeRequired = resumeAttachmentData[key];

								break;
							}

							case 'Ask for resume attachment': {
								isResumeOptional = resumeAttachmentData[key];

								break;
							}

							case "Show User's Email Address": {
								isUserEmail = resumeAttachmentData[key];

								break;
							}
						}
					}
				}
			}
		}

		const data_items = referralQuestions;
		let customItems = '';
		if (data_items.length > 0) {
			customItems = data_items.map((item, index) => {
				if (!item) return null;
				switch (item.element) {
					case 'TextInput':
					case 'TextArea':
					case 'Dropdown': {
						return this.getInputElement(item, index);
					}
					default: {
						return this.getSimpleElement(item, index);
					}
				}
			});
		}

		const jobTitle = get(job, 'title');
		let location = get(job, 'job.location', get(job, 'location'));
		if (typeof location === 'string') {
			location = parse(location);
		}
		let locations = parse(
			get(job, 'job.locations', get(job, 'locations', [])),
		);
		locations = locations ? locations : [];
		const department = get(job, 'department');
		return (
			<>
				<Button
					block
					ghost
					disabled={disabled}
					type="primary"
					size="large"
					onClick={() => {
						this.showModal();
					}}
				>
					<i className=" icon-heart" />
					{disabled
						? ml(
								`I'm Interested Not Available`,
								currentUser,
								allMultiLingualData,
							)
						: ml(
								`I'm Interested`,
								currentUser,
								allMultiLingualData,
							)}
				</Button>

				<Modal
					centered
					destroyOnClose
					open={visible}
					footer={null}
					width={600}
					title={ml('Interested?', currentUser, allMultiLingualData)}
					onCancel={this.handleCancel}
				>
					<p className="text-center">
						{ml(modalTxt, currentUser, allMultiLingualData)}
					</p>

					<div className="modal-job-info-wrap">
						<div className="mj-info-wrap">
							<h3 className="mj-info-name">{jobTitle}</h3>
						</div>
						<ul className="mj-info">
							<JobLocation
								allMultiLingualData={allMultiLingualData}
								currentUser={currentUser}
								location={location}
								locations={locations}
							/>
							{department && (
								<li>
									<i className="icon-folder" />
									<span>{department.name}</span>
								</li>
							)}
						</ul>
						{/* If company is ZT systems */}
						{companyId === 'e600195e-d1d9-4163-8b22-f0f250c4bcfd' &&
							get(job, 'displayReqId') !== true &&
							get(job, 'displayReqId') !== '' &&
							get(job, 'displayReqId') && (
								<p className="mj-info-text mb-0">
									ID#:
									<span>{get(job, 'displayReqId')}</span>
								</p>
							)}
						{companyId !== 'e600195e-d1d9-4163-8b22-f0f250c4bcfd' &&
							get(job, 'externalJobId') !== true &&
							get(job, 'externalJobId') !== '' &&
							get(job, 'externalJobId') && (
								<p className="mj-info-text mb-0">
									ID#:
									<span>{get(job, 'externalJobId')}</span>
								</p>
							)}
					</div>
					<>{customItems}</>
					{isResumeOptional ? (
						<>
							{!filename ? (
								<div
									className="click-to-upload"
									style={{ marginBottom: '16px' }}
								>
									<label htmlFor={'uploadFile-' + uniqueId}>
										<span className="link">Click here</span>
										<input
											ref={(ref) => {
												this.uploadInput = ref;
											}}
											hidden
											type="file"
											accept=".doc,.docx,application/msword,.pdf,application/pdf"
											id={'uploadFile-' + uniqueId}
											name="file"
											onChange={this.onFileInputChange}
										/>{' '}
										to attach a resume
										{isResumeRequired ? (
											<span className="custom-required">
												(required)
											</span>
										) : (
											<span className="custom-optional">
												(optional)
											</span>
										)}
									</label>
								</div>
							) : (
								<div className="upload-file-text">
									<p>
										{filename?.length > 50
											? filename.slice(0, 50) + '...'
											: filename}
										<i
											className="icon-bin text-danger cursor-p"
											onClick={this.onDelete}
										/>
									</p>
								</div>
							)}
						</>
					) : null}
					{isUserEmail ? (
						<div
							class="email-with-label"
							style={{
								color: 'var(--oslo-gray)',
								marginBottom: '16px',
							}}
						>
							<span class="email-withlabel">
								You must apply using this email address:
							</span>
							<div style={{ fontStyle: 'italic' }}>
								{get(currentUser, 'emailAddress')}
							</div>
						</div>
					) : (
						''
					)}
					<div className="modal-footer-btn">
						<ProgressButton
							durationSuccess={1500}
							state={this.state.buttonState}
							onClick={(e) => {
								e.preventDefault();
								this.handleSubmit(isResumeRequired);
							}}
						>
							{ml(applyText, currentUser, allMultiLingualData)}
						</ProgressButton>
					</div>
				</Modal>
				<Modal
					destroyOnClose
					className={toggleClass}
					open={showCompleteModal}
					footer={null}
					width={620}
					onCancel={this.handleCancel}
				>
					<div>
						<Row style={{ textAlign: 'center' }}>
							<CheckCircleOutlined className={checkCircle} />
						</Row>
						<p className={ModalStyles.ModalText}>
							{ml(
								'Success. Your information has been submitted for this position.',
								currentUser,
								allMultiLingualData,
							)}
						</p>

						<div className={ModalStyles.SubmitBtnContainer}>
							<Row style={{ marginTop: 8 }}>
								<span style={{ fontSize: 18 }}>
									<a
										className={linkStyles}
										onClick={() => {
											this.setState({
												showCompleteModal: false,
											});
										}}
									>
										{ml(
											'Close This Window',
											currentUser,
											allMultiLingualData,
										)}
									</a>
								</span>
							</Row>
						</div>
					</div>
				</Modal>
				<Modal
					centered
					destroyOnClose
					open={visibleApplied}
					footer={null}
					width={720}
					title={ml(
						"You've Applied!",
						currentUser,
						allMultiLingualData,
					)}
					onCancel={this.handleCancel}
				>
					<>
						<p className="small text-center">
							{ml(
								"You've already applied to this position. Check My Referrals to track your status.",
								currentUser,
								allMultiLingualData,
							)}
						</p>

						<div className="modal-footer-btn">
							<ProgressButton
								durationSuccess={1500}
								state={this.state.buttonState}
								onClick={(e) => {
									e.preventDefault();
									this.handleSubmit(isResumeRequired);
								}}
							>
								{ml('Got It', currentUser, allMultiLingualData)}
							</ProgressButton>
						</div>
					</>
				</Modal>
			</>
		);
	}
}

export default withApollo(ImInterestedButtonWrapper);
