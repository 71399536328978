import { Form, Input, Select, Tooltip } from 'antd';
import dompurify from 'dompurify';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { queryReferralQuestionsByCompanyId } from 'src/_shared/api/graphql/custom/referral-questions/';
import { ml } from 'src/_shared/services/utils';

const { TextArea } = Input;
const sanitizer = dompurify.sanitize;

const ReferralQuestionsDetailsComponent = ({
	allMultiLingualData,
	candidateQuestionsData,
	currentUser,
	questionsData,
	editing,
	onValuesChange,
	client,
}) => {
	const [form] = Form.useForm();
	const [formValues, setFormValues] = useState({});
	const [dropdownOptions, setDropdownOptions] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchQuestionOptions = async () => {
			try {
				setLoading(true);
				const response = await client.query({
					query: gql(queryReferralQuestionsByCompanyId),
					variables: {
						companyId: currentUser.companyId,
						first: 100,
					},
				});

				const options = {};
				for (const item of response.data.queryReferralQuestionsByCompanyId
					.items) {
					if (item.questions) {
						try {
							const questionData = JSON.parse(item.questions);
							if (questionData.field_name && questionData.options) {
								options[questionData.field_name] = questionData.options.map(
									(opt) => ({
										label: opt.text,
										value: opt.value,
									})
								);
							}
						} catch (error) {
							console.error('Error parsing question options:', error);
						}
					}
				}

				setDropdownOptions(options);
			} catch (error) {
				console.error('Error fetching question options:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchQuestionOptions();
	}, [client, currentUser.companyId]);

	useEffect(() => {
		if (editing) {
			const allQuestions = [
				...(questionsData &&
				typeof questionsData === 'string' &&
				questionsData.length > 0
					? JSON.parse(questionsData)
					: []),
				...(candidateQuestionsData &&
				typeof candidateQuestionsData === 'string' &&
				candidateQuestionsData.length > 0
					? JSON.parse(candidateQuestionsData)
					: []),
			]

			const initialValues = allQuestions.reduce((acc, element) => {
				acc[element.name] = element.value;
				return acc;
			}, {});

			setFormValues(initialValues);
			form.setFieldsValue(initialValues);
		}
	}, [editing, questionsData, candidateQuestionsData]);

	const handleFormChange = (changedValues, allValues) => {
		setFormValues(allValues);
		if (onValuesChange) {
			onValuesChange(changedValues, allValues);
		}
	};

	const getDropdownDisplayValue = (name, value, text = null) => {
		const options = dropdownOptions[name] || [];
		const option = options.find((opt) => opt.value === value);
		return option ? option.label : text || value;
	};

	const getInputComponent = (element) => {
		const { name, text } = element;

		if (name.startsWith('dropdown_')) {
			const options = dropdownOptions[name] || [];
			return (
				<Tooltip title={options.length === 0 ? 'This question no longer exists in form builder and cannot be edited.' : null} >
					<Select
						style={{ width: '100%' }}
						options={options}
						loading={loading}
						disabled={options.length === 0}
						placeholder={options.length === 0 ? text : "Select an option"}
					/>
				</Tooltip>
			);
		}

		if (name.startsWith('text_area_')) {
			return <TextArea rows={4} />;
		}

		return <Input />;
	};

	const renderQuestions = (data, isReferralQuestion = false) => {
		if (!data) return null;

		const parsedData = JSON.parse(data);
		if (!parsedData || !Array.isArray(parsedData)) return null;

		return parsedData.map((element, i) => (
			<div key={element.name} className="rpf-qa">
				<div
					className="qa-row"
					style={{ display: 'flex', alignItems: 'flex-start', gap: '1rem' }}
				>
					{isReferralQuestion ? (
						<>
							<label style={{ flexShrink: 0 }}>{`Ques${i + 1}`}.</label>
							<span
								dangerouslySetInnerHTML={{
									__html: sanitizer(element.question),
								}}
								style={{
									whiteSpace: 'pre-line',
									flex: 1,
								}}
							/>
						</>
					) : (
						<>
							<label
								dangerouslySetInnerHTML={{
									__html: sanitizer(element.question),
								}}
								className="qa-label"
							/>
							<br />
						</>
					)}
				</div>
				<div
					className="qa-row"
					style={{ display: 'flex', alignItems: 'flex-start', gap: '1rem' }}
				>
					{isReferralQuestion && <label>Ans.</label>}
					{editing ? (
						<div style={{ flex: 1 }}>
							<Form.Item name={element.name} style={{ marginBottom: 0 }}>
								{getInputComponent(element)}
							</Form.Item>
						</div>
					) : (
						<span
							style={{
								whiteSpace: 'pre-line',
								flex: 1,
							}}
						>
							{element.name.startsWith('dropdown_')
								? getDropdownDisplayValue(element.name, element.value, element.text)
								: element.text || element.value}
						</span>
					)}
				</div>
			</div>
		));
	};

	const questions =
		questionsData && questionsData.length > 0
			? JSON.parse(questionsData)
			: null;
	const candidateQuestions =
		candidateQuestionsData && candidateQuestionsData.length > 0
			? JSON.parse(candidateQuestionsData)
			: null;

	const content = (
		<>
			<div>
				{questions && questions.length > 0 && (
					<>
						<h4 className="content-right-heading">
							{ml('Referral Questions', currentUser, allMultiLingualData)}
						</h4>
						{renderQuestions(questionsData, true)}
					</>
				)}
			</div>
			{candidateQuestions && candidateQuestions.length > 0 && (
				<>
					<h4 className="content-right-heading">
						{ml('Candidate Questions', currentUser, allMultiLingualData)}
					</h4>
					{renderQuestions(candidateQuestionsData)}
				</>
			)}
		</>
	);

	return editing ? (
		<Form
			form={form}
			initialValues={formValues}
			onValuesChange={handleFormChange}
		>
			{content}
		</Form>
	) : (
		content
	);
};

export default withApollo(ReferralQuestionsDetailsComponent);
