import { Button } from 'antd';
import _ from 'lodash';
import { ml } from '../../services/utils';

export function ToggleEmailPhone(props) {
	const {
		allMultiLingualData,
		contact,
		currentUser,
		onClick,
		referralType,
		sendByText,
		visible,
		inputLabel,
	} = props;

	const hasContactWithEmail = contact && contact.emailAddress;
	const hasContactWithPhone = contact && contact.phoneNumber;
	const componentIsRendered =
		hasContactWithEmail || hasContactWithPhone || visible;
	const emailButtonIsRendered = hasContactWithEmail || visible;
	const isTextNotificationDisabled =
		currentUser.company.disableNewReferralSMSNotification;

	function ReferralNotificationButtons({ children }) {
		return (
			<>
				{inputLabel === false ? (
					''
				) : (
					<label className="custom-label">
						<strong>
							{ml(sendByText, currentUser, allMultiLingualData)}
						</strong>
					</label>
				)}
				<div className="custom-btn-group">{children}</div>
			</>
		);
	}

	function NotificationButton({ type, isEnabled }) {
		return (
			<Button
				type={type === referralType ? 'primary' : 'ghost'}
				disabled={isEnabled}
				onClick={() => onClick(type)}
			>
				<i
					className={`icon-${
						type == 'email' ? 'envelope-outline' : 'message-outline'
					}`}
					style={{ fontSize: 18 }}
				/>
				{ml(_.capitalize(type), currentUser, allMultiLingualData)}
			</Button>
		);
	}

	return componentIsRendered ? (
		<ReferralNotificationButtons>
			{emailButtonIsRendered && (
				<NotificationButton
					type="email"
					isEnabled={hasContactWithEmail}
				/>
			)}
			{isTextNotificationDisabled ? null : (
				<NotificationButton
					type="text"
					isEnabled={hasContactWithPhone}
				/>
			)}
		</ReferralNotificationButtons>
	) : null;
}
