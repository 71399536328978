import { useCallback, useEffect, useMemo, useRef, useState, memo } from 'react';
import {
	Form,
	Input,
	Button,
	Select,
	Switch,
	Typography,
	Card,
	Row,
	Col,
	Space,
	Modal,
	message,
	Checkbox,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import {
	uploadToS3Multipart,
	downloadFromS3Signed,
	parse,
} from 'src/_shared/services/utils.js';
import { Editor } from '@hugerte/hugerte-react';

import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';

import { FormElementsSidebar } from 'src/new-form-builder/FormElementsSidebar';
import { FormArea } from 'src/new-form-builder/FormAreaComponent';
import { FormMetadataSettings } from 'src/new-form-builder/FormMetadataSettings';
import FormPreview from 'src/new-form-builder/FormPreview';

import { FormBuilderContext } from 'src/new-form-builder/FormBuilderContext';
import { createRegistry } from 'src/new-form-builder/createRegistry';

const { Title } = Typography;
const { Option } = Select;

// Types of form elements supported
const ELEMENT_TYPES = {
	INPUT: 'input',
	TEXTAREA: 'textarea',
	SELECT: 'select',
	LABEL: 'label',
	LINEBREAK: 'linebreak',
	// Commented out until we have time to add new form elements
	//SWITCH: 'switch'
};

const DEFAULT_FORM_METADATA = {
	askForResume: false,
	resumeRequired: false,
	askForPhoneNumber: false,
	showUserEmail: false,
};

const getDefaultMetadata = (formType = 'Referral') => {
	const metadata = { ...DEFAULT_FORM_METADATA };
	if (formType !== 'Interested') {
		delete metadata.showUserEmail;
	}
	if (formType !== 'Acceptance') {
		delete metadata.askForPhoneNumber;
	}
	return metadata;
};
const createEmptyFormDrafts = () => {
	const formTypes = ['General', 'Referral', 'Acceptance', 'Interested'];
	const drafts = {};
	formTypes.forEach((type) => {
		drafts[type] = { elements: [], metadata: getDefaultMetadata(type) };
	});
	return drafts;
};

const formTypeHeaders = {
	Referral: {
		title: 'Referral Form',
		description:
			'Add custom questions for your employees to answer when making a referral.',
	},
	Acceptance: {
		title: 'Acceptance Form',
		description:
			'Add custom questions to Acceptance Form for candidates to answer when accepting a referral.',
	},
	Interested: {
		title: "I'm Interested Form",
		description:
			'Add custom questions to the "I\'m Interested" Form for applicants to answer when completing their self-referral.',
	},
	General: {
		title: 'General/Lead Form',
		description:
			'Add custom questions for your employees to answer when making a General Referral or Referral Lead.',
	},
};

// Element properties editor component
const ElementEditor = memo(({ element, onSave, visible, onCancel }) => {
	const [form] = Form.useForm();
	const [isFormValid, setIsFormValid] = useState(true);
	const [editorContent, setEditorContent] = useState(
		element?.type === ELEMENT_TYPES.LABEL ? element?.content || '' : '',
	);
	const [editorKey, setEditorKey] = useState(
		element?.type === ELEMENT_TYPES.LABEL ? uuidv4() : null,
	);
	const [editorValue, setEditorValue] = useState(
		element?.type === ELEMENT_TYPES.LABEL ? element?.content || '' : '',
	);

	// Set form values when element changes
	useEffect(() => {
		if (visible && element) {
			form.setFieldsValue(element);
			// Also update editor content when element changes
			if (element.type === ELEMENT_TYPES.LABEL) {
				setEditorContent(element.content || '');
			}
		}
	}, [form, element, visible]);

	// Update form validity on every field change
	const handleFieldsChange = () => {
		const hasErrors = form
			.getFieldsError()
			.some(({ errors }) => errors.length > 0);
		setIsFormValid(!hasErrors);
	};

	const handleSave = () => {
		form.validateFields().then((values) => {
			onSave({ ...element, ...values, content: editorContent });
			form.resetFields();
		});
	};

	const handleEditorChange = (content) => {
		setEditorContent(content || '');
		form.setFieldsValue({ content: content || '' });
	};

	const renderFormFields = () => {
		if (!element) return null;

		const commonFields = (
			<>
				{element.type !== ELEMENT_TYPES.LINEBREAK &&
					element.type !== ELEMENT_TYPES.LABEL && (
						<Form.Item name="label" label="Label">
							<Input />
						</Form.Item>
					)}
				{element.type !== ELEMENT_TYPES.LINEBREAK &&
					element.type !== ELEMENT_TYPES.LABEL && (
						<Form.Item
							name="required"
							label="Required"
							valuePropName="checked"
						>
							<Switch />
						</Form.Item>
					)}
			</>
		);

		// Render different fields based on element type
		switch (element.type) {
			case ELEMENT_TYPES.INPUT:
			case ELEMENT_TYPES.TEXTAREA:
				return (
					<>
						{commonFields}
						<Form.Item name="placeholder" label="Placeholder">
							{element.type === ELEMENT_TYPES.TEXTAREA ? (
								<Input.TextArea />
							) : (
								<Input />
							)}
						</Form.Item>
					</>
				);
			case ELEMENT_TYPES.SELECT:
				return (
					<>
						{commonFields}
						<Form.Item name="placeholder" label="Placeholder">
							<Input />
						</Form.Item>
						<div className="ant-col ant-form-item-label">
							<label>Options</label>
						</div>
						<Form.List name="options">
							{(fields, { add, remove }) => (
								<>
									{fields.map((field) => (
										<Row key={field.key} gutter={8}>
											<Col span={20}>
												<Form.Item
													{...field}
													name={[field.name, 'label']}
													fieldKey={[
														field.fieldKey,
														'label',
													]}
													rules={[
														{
															required: true,
															message:
																'Missing label',
														},
													]}
												>
													<Input placeholder="Option Label" />
												</Form.Item>
												<Form.Item
													{...field}
													name={[field.name, 'value']}
													fieldKey={[
														field.fieldKey,
														'value',
													]}
													hidden={true}
													preserve={true}
												/>
											</Col>
											<Col span={4}>
												<Button
													danger
													onClick={() =>
														remove(field.name)
													}
													icon={<DeleteOutlined />}
												/>
											</Col>
										</Row>
									))}
									<Form.Item>
										<Button
											type="dashed"
											onClick={() => {
												const nextOptionNumber =
													fields.length + 1;
												add({
													label: '',
													value: `option_${nextOptionNumber}`,
												});
											}}
											block
											icon={<PlusOutlined />}
										>
											Add Option
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
					</>
				);
			case ELEMENT_TYPES.LABEL:
				return (
					<>
						<Editor
							key={editorKey}
							init={{
								height: 350,
								menubar: false,
								branding: false,
								plugins: ['quickbars', 'lists'],
								toolbar:
									'undo redo fontsizeinput bold italic forecolor backcolor | \
                        styles alignleft aligncenter alignright alignjustify',

								max_width: '100%',
								placeholder: 'Enter text',
								quickbars_selection_toolbar:
									'bold italic forecolor backcolor removeformat',
								quickbars_image_toolbar: false,
								quickbars_insert_toolbar: false,
								toolbar_mode: 'floating',
							}}
							value={editorContent}
							onEditorChange={(newValue, editor) => {
								setEditorValue(newValue);
								handleEditorChange(
									editor.getContent({ format: 'html' }),
								);
							}}
						/>
					</>
				);
			// Commented out until we have time to add new form elements
			// case ELEMENT_TYPES.SWITCH:
			//   return (
			//     <>
			//       {commonFields}
			//     </>
			//   );
			default:
				return commonFields;
		}
	};

	return (
		<Modal
			title="Edit Element Properties"
			open={visible}
			onOk={handleSave}
			onCancel={onCancel}
			maskClosable={false}
      destroyOnClose={true}
			okButtonProps={{ disabled: !isFormValid }}
			centered
		>
			<Form
				form={form}
				layout="vertical"
				onFieldsChange={handleFieldsChange}
			>
				{renderFormFields()}
			</Form>
		</Modal>
	);
});

// Create default element properties based on type
const createDefaultElement = (type) => {
	const baseElement = {
		id: uuidv4(),
		type,
		name: `field_${Date.now()}`,
		label: '',
		required: false,
	};

	switch (type) {
		case ELEMENT_TYPES.INPUT:
			return {
				...baseElement,
				label: 'Text Input Label Placeholder',
				placeholder: 'Enter text',
			};
		case ELEMENT_TYPES.TEXTAREA:
			return {
				...baseElement,
				label: 'Multi-Line Input Label Placeholder',
				placeholder: 'Enter text',
				rows: 4,
			};
		case ELEMENT_TYPES.SELECT:
			return {
				...baseElement,
				label: 'Dropdown Label Placeholder',
				placeholder: 'Select an option',
				options: [
					{ label: 'Option 1', value: 'option_1' },
					{ label: 'Option 2', value: 'option_2' },
				],
			};
		case ELEMENT_TYPES.LABEL:
			return {
				...baseElement,
				text: 'Label',
				content: 'Rich Text Placeholder',
				static: true,
			};
		case ELEMENT_TYPES.LINEBREAK:
			return {
				...baseElement,
			};
		// Commented out until we have time to add new form elements
		// case ELEMENT_TYPES.SWITCH:
		//   return {
		//     ...baseElement,
		//     label: 'Switch Label Placeholder',
		//   };
		default:
			return baseElement;
	}
};
// Main FormBuilderApp component
export default function FormBuilderApp(props) {
	const [data, setData] = useState(() => ({
		elements: [],
	}));
	const [userGroups, setUserGroups] = useState(props.userGroups || []);
	const [editingElement, setEditingElement] = useState(null);
	const [editingIndex, setEditingIndex] = useState(null);
	const [isEditorVisible, setIsEditorVisible] = useState(false);
	const [formType, setFormType] = useState('Referral');
	const [useUniqueFormsForGroups, setUseUniqueFormsForGroups] =
		useState(false);
	const [selectedUserGroup, setSelectedUserGroup] = useState(null);
	const [formMetadata, setFormMetadata] = useState(getDefaultMetadata());
	const [loadedFromS3, setLoadedFromS3] = useState({
		main: false,
		userGroups: {},
	});

	const currentUser = props.currentUser;
	const allMultiLingualData = props.allMultiLingualData;
	const legacyReferralQuestions = props.legacyReferralQuestions || [];

	// Create a stable reference to the current data
	const stableData = useRef(data);
	useEffect(() => {
		stableData.current = data;
	}, [data]);

	// Create registry for tracking elements
	const [registry] = useState(createRegistry);

	// Create a unique instance ID for this form builder
	const [instanceId] = useState(() => `form-builder-${uuidv4()}`);

	const getElements = useCallback(() => {
		return stableData.current.elements;
	}, []);

	// Loading initial forms from S3 or legacy referral questions
	useEffect(() => {
		const loadInitialForms = async () => {
			try {
				// Try to load main forms from S3 first
				const formsLoaded = await loadFormsFromS3(null);

				// If S3 loading fails, fall back to legacy referral questions
				if (
					!formsLoaded &&
					legacyReferralQuestions &&
					legacyReferralQuestions.length
				) {
					const acceptanceQuestions = legacyReferralQuestions.filter(
						(item) => item.isCandidate,
					);
					const interestedQuestions = legacyReferralQuestions.filter(
						(item) => !item.isCandidate && item.isInterested,
					);
					const referralQuestions = legacyReferralQuestions.filter(
						(item) =>
							!item.isCandidate &&
							!item.isInterested &&
							!item.isGeneral,
					);
					const generalQuestions = legacyReferralQuestions.filter(
						(item) =>
							!item.isCandidate &&
							!item.isInterested &&
							item.isGeneral,
					);

					const importedReferralForms =
						importOldFormatWithMetadata(referralQuestions);
					const importedGeneralForms =
						importOldFormatWithMetadata(generalQuestions);
					const importedInterestedForms =
						importOldFormatWithMetadata(interestedQuestions);
					const importedAcceptanceForms =
						importOldFormatWithMetadata(acceptanceQuestions);

					const referralForm = importedReferralForms[0] || {
						elements: [],
						metadata: getDefaultMetadata(),
					};
					const generalForm = importedGeneralForms[0] || {
						elements: [],
						metadata: getDefaultMetadata(),
					};
					const interestedForm = importedInterestedForms[0] || {
						elements: [],
						metadata: getDefaultMetadata('Interested'),
					};
					const acceptanceForm = importedAcceptanceForms[0] || {
						elements: [],
						metadata: getDefaultMetadata('Acceptance'),
					};

					// Update the drafts state for main forms
					setFormDrafts((prevDrafts) => ({
						...prevDrafts,
						main: {
							...prevDrafts.main,
							Referral: referralForm,
							General: generalForm,
							Interested: interestedForm,
							Acceptance: acceptanceForm,
						},
					}));

					// Set the active form to be the Referral form
					setData((prevData) => ({
						...prevData,
						elements: referralForm.elements || [],
					}));
					setFormType('Referral');
					setFormMetadata(
						referralForm.metadata || getDefaultMetadata(),
					);
				}
			} catch (error) {
				console.error('Error loading initial forms:', error);
			}
		};

		loadInitialForms();
	}, []);

	const reorderElement = useCallback(
		({ elementId, startIndex, finishIndex }) => {
			setData((data) => {
				return {
					...data,
					elements: reorder({
						list: data.elements,
						startIndex,
						finishIndex,
					}),
				};
			});
		},
		[],
	);

	// For adding elements from the sidebar
	useEffect(() => {
		return combine(
			monitorForElements({
				canMonitor({ source }) {
					return source.data.instanceId === instanceId;
				},
				onDrop(args) {
					const { location, source } = args;
					const dropTarget = location.current.dropTargets?.[0];
					const edge = dropTarget?.data
						? extractClosestEdge(dropTarget.data)
						: null;
					if (!location.current.dropTargets.length || !edge) {
						return;
					}

					if (source.data.type === 'sidebar-element') {
						const elementType = source.data.elementType;
						const elementsCount =
							stableData.current.elements.length;
						let insertIndex = elementsCount;
						if (dropTarget.data.type === 'form-element') {
							const targetIndex = dropTarget.data.index;
							insertIndex =
								edge === 'bottom'
									? targetIndex + 1
									: targetIndex;
						} else if (dropTarget.data.type === 'form-area') {
							insertIndex = edge === 'bottom' ? elementsCount : 0;
						}
						addElement(elementType, insertIndex);
					}
				},
			}),
		);
	}, [instanceId, reorderElement]);

	const contextValue = useMemo(() => {
		return {
			getElements,
			reorderElement,
			registerElement: registry.registerElement,
			instanceId,
		};
	}, [getElements, instanceId, reorderElement, registry.registerElement]);

	const addElement = useCallback((elementType, position = null) => {
		const newElement = createDefaultElement(elementType);

		setData((data) => {
			const newElements = [...data.elements];

			if (
				position !== null &&
				position >= 0 &&
				position <= newElements.length
			) {
				newElements.splice(position, 0, newElement);
			} else {
				newElements.push(newElement);
			}

			return {
				...data,
				elements: newElements,
			};
		});
	}, []);

	const removeElement = useCallback((index) => {
		setData((data) => ({
			...data,
			elements: data.elements.filter((_, i) => i !== index),
		}));
	}, []);

	const duplicateElement = useCallback((index) => {
		setData((data) => {
			const newElements = [...data.elements];
			const duplicated = {
				...newElements[index],
				id: uuidv4(),
				name: `${newElements[index].name}_copy_${Math.floor(Math.random() * 1000)}`,
			};
			newElements.splice(index + 1, 0, duplicated);

			return {
				...data,
				elements: newElements,
			};
		});
	}, []);

	const editElement = useCallback(
		(index) => {
			setEditingElement(data.elements[index]);
			setEditingIndex(index);
			setIsEditorVisible(true);
		},
		[data.elements],
	);

	const handleSaveElement = (updatedElement) => {
		setData((data) => {
			const newElements = [...data.elements];
			newElements[editingIndex] = updatedElement;

			return {
				...data,
				elements: newElements,
			};
		});

		setIsEditorVisible(false);
		setEditingElement(null);
		setEditingIndex(null);
	};

	// Dev function to import old format with metadata
	const importOldFormatWithMetadata = (jsonData) => {
		const company = currentUser.companyId;
		let oldFormatItems = [];
		if (typeof jsonData === 'string') {
			try {
				oldFormatItems = JSON.parse(jsonData);
			} catch (error) {
				console.error('Error parsing JSON string:', error);
				return [];
			}
		} else if (Array.isArray(jsonData)) {
			oldFormatItems = jsonData;
		} else {
			console.error('Invalid data format for import');
			return [];
		}

		let groupedItems = {};
		let metadataByGroup = {};
		let phoneNumberElements = [];

		oldFormatItems.forEach((item) => {
			// Determine form type based on flags on the item
			let formType = 'General';
			if (item.isCandidate) formType = 'Acceptance';
			else if (item.isInterested) formType = 'Interested';
			else if (!item.isGeneral) formType = 'Referral';

			// Create a unique key for this group
			const groupKey = `${company}_${formType}`;

			// If sortOrder is 0, treat it as metadata and skip adding to elements.
			if (item.sortOrder === 0) {
				let metadata = getDefaultMetadata(formType);
				try {
					const metadataObj =
						typeof item.questions === 'string'
							? JSON.parse(item.questions)
							: item.questions;
					if (
						metadataObj['Ask for resume attachment'] !== undefined
					) {
						metadata.askForResume =
							!!metadataObj['Ask for resume attachment'];
					}
					if (metadataObj['Resume required'] !== undefined) {
						metadata.resumeRequired =
							!!metadataObj['Resume required'];
					}
					if (
						formType === 'Interested' &&
						metadataObj["Show User's Email Address"] !== undefined
					) {
						metadata.showUserEmail =
							!!metadataObj["Show User's Email Address"];
					}
				} catch (e) {
					console.warn('Error parsing metadata:', e);
				}
				metadataByGroup[groupKey] = metadata;
				return; // Skip adding metadata item as a regular element
			} else if (item.isCandidate) {
				if (parse(item.questions)?.text === 'Phone Number') {
					phoneNumberElements.push(item);
					return; // Skip adding phone number item as a regular element
				}
			}

			// Initialize the group if it doesn't exist
			if (!groupedItems[groupKey]) {
				groupedItems[groupKey] = {
					company,
					formType,
					elements: [],
					sortOrders: {}, // To track each element's order
				};
			}

			// Convert legacy item to a new format element
			const newElement = convertToNewElementFormat(item);
			if (newElement) {
				groupedItems[groupKey].elements.push(newElement);
				groupedItems[groupKey].sortOrders[item.id] = item.sortOrder;
			}
		});

		// Offset original sort orders if there are elements after a phone number element
		if (
			phoneNumberElements.length > 0 &&
			groupedItems[`${company}_Acceptance`]
		) {
			for (const phoneNumberElement of phoneNumberElements) {
				Object.entries(
					groupedItems[`${company}_Acceptance`].sortOrders,
				).forEach(([sortOrder, order]) => {
					if (order > phoneNumberElement.sortOrder) {
						groupedItems[`${company}_Acceptance`].sortOrders[
							sortOrder
						] -= 1;
					}
				});
			}
		}

		const importedForms = [];
		Object.entries(groupedItems).forEach(([groupKey, group]) => {
			group.elements.sort((a, b) => {
				const aOrder =
					group.sortOrders[a.name.replace('field_', 'q_')] || 999;
				const bOrder =
					group.sortOrders[b.name.replace('field_', 'q_')] || 999;
				return aOrder - bOrder;
			});

			const metadata =
				metadataByGroup[groupKey] || getDefaultMetadata(group.formType);

			if (
				group.formType === 'Interested' &&
				metadata.showUserEmail === undefined
			) {
				metadata.showUserEmail = false;
			}
			if (
				group.formType === 'Acceptance' &&
				phoneNumberElements.length > 0
			) {
				metadata.askForPhoneNumber = true;
			}
			const form = {
				id: `imported_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
				name: `Imported ${group.formType} Form (${group.company})`,
				type: group.formType,
				elements: group.elements,
				company: group.company,
				metadata: metadata,
			};

			importedForms.push(form);
		});

		return importedForms;
	};

	const convertToNewElementFormat = (oldItem) => {
		try {
			let questionsObj = {};
			// Parse questions if needed
			if (typeof oldItem.questions === 'string') {
				try {
					questionsObj = JSON.parse(oldItem.questions);
				} catch (e) {
					console.error('Error parsing questions JSON:', e);
					questionsObj = {
						element: 'TextInput',
						text: oldItem.questions || '',
					};
				}
			} else if (typeof oldItem.questions === 'object') {
				questionsObj = oldItem.questions;
			}

			// Generate a unique name if not present
			const name =
				oldItem.id ||
				`field_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

			// Base element with common properties
			const baseElement = {
				id: uuidv4(),
				name: name.startsWith('q_')
					? name.replace('q_', 'field_')
					: name,
				required: questionsObj.required || false,
			};

			// Determine element type from either element or questionType property
			const elementType =
				questionsObj.element || questionsObj.questionType;

			// Map element types using a unified approach
			switch (elementType) {
				case 'TextInput':
					return {
						...baseElement,
						type: ELEMENT_TYPES.INPUT,
						label:
							questionsObj.label ||
							questionsObj.text ||
							'Input Field',
						placeholder: 'Enter text',
					};
				case 'TextArea':
					return {
						...baseElement,
						type: ELEMENT_TYPES.TEXTAREA,
						label:
							questionsObj.label ||
							questionsObj.text ||
							'Text Area',
						placeholder: 'Enter text',
						rows: 4,
					};
				case 'Dropdown':
					return {
						...baseElement,
						type: ELEMENT_TYPES.SELECT,
						label:
							questionsObj.label || questionsObj.text || 'Select',
						placeholder: 'Select an option',
						options: Array.isArray(questionsObj.options)
							? questionsObj.options.map((opt) => {
									const label =
										typeof opt === 'object'
											? opt.text || opt.label
											: opt;
									const value =
										typeof opt === 'object'
											? opt.value
											: typeof opt === 'string'
												? opt
														.toLowerCase()
														.replace(/\s+/g, '_')
												: `option_${Math.random().toString(36).substr(2, 9)}`;
									return { label, value };
								})
							: [],
					};
				case 'Label':
					return {
						...baseElement,
						type: ELEMENT_TYPES.LABEL,
						text: 'Label',
						content:
							questionsObj.content ||
							questionsObj.text ||
							'Label Content',
						static: true,
					};
				case 'LineBreak':
					return {
						...baseElement,
						type: ELEMENT_TYPES.LINEBREAK,
					};
				// Commented out until we have time to add new form elements
				// case 'Switch':
				//   return {
				//     ...baseElement,
				//     type: ELEMENT_TYPES.SWITCH,
				//     label: questionsObj.text || questionsObj.question || 'Switch'
				//   };
				default:
					console.warn(
						`Unknown element type: ${elementType || JSON.stringify(questionsObj)}`,
					);
					return null;
			}
		} catch (e) {
			console.error('Error converting to new element format:', e);
			return null;
		}
	};

	const generateModernJsonFormat = () => {
		try {
			const modernJson = {
				companyId: currentUser.companyId,
				isMainFormGroup: !useUniqueFormsForGroups,
				userGroupId: useUniqueFormsForGroups ? selectedUserGroup : null,
				forms: {
					referral: { metadata: {}, elements: [] },
					general: { metadata: {}, elements: [] },
					interested: { metadata: {}, elements: [] },
					acceptance: { metadata: {}, elements: [] },
				},
			};

			if (!useUniqueFormsForGroups) {
				// Main forms
				Object.keys(formDrafts.main).forEach((formType) => {
					const formKey = formType.toLowerCase();
					const formData = formDrafts.main[formType];

					if (formData) {
						modernJson.forms[formKey] = {
							metadata: formData.metadata || {},
							elements: formData.elements.map(
								(element, index) => ({
									...element,
									sortOrder: index + 1,
								}),
							),
						};
					}
				});
			} else if (selectedUserGroup) {
				// User group specific forms
				if (formDrafts.userGroups[selectedUserGroup]) {
					Object.keys(
						formDrafts.userGroups[selectedUserGroup],
					).forEach((formType) => {
						const formKey = formType.toLowerCase();
						const formData =
							formDrafts.userGroups[selectedUserGroup][formType];

						if (formData) {
							modernJson.forms[formKey] = {
								metadata: formData.metadata || {},
								elements: formData.elements.map(
									(element, index) => ({
										...element,
										sortOrder: index + 1,
									}),
								),
							};
						}
					});
				}
			}

			return modernJson;
		} catch (error) {
			console.error('Error generating modern JSON format:', error);
			return null;
		}
	};

	const saveFormsToModernFormat = async () => {
		try {
			handleFormTypeChange(formType);

			const modernJson = generateModernJsonFormat();

			if (!modernJson) {
				return;
			}

			const fileName = !useUniqueFormsForGroups
				? 'main_forms.json'
				: `${selectedUserGroup}_forms.json`;

			const jsonString = JSON.stringify(modernJson, null, 2);
			const jsonBlob = new Blob([jsonString], {
				type: 'application/json',
			});

			await uploadToS3Multipart(
				jsonBlob,
				`forms/${currentUser.companyId}/${fileName}`,
				'erin-documents',
			);

			message.success('Forms saved successfully');
		} catch (error) {
			console.error('Error saving forms to S3:', error);
			message.error('Failed to save forms');
		}
	};

	// Function to load forms from modern format
	const loadFormsFromModernFormat = (modernJson) => {
		try {
			if (!modernJson || !modernJson.forms) {
				throw new Error('Invalid modern JSON format');
			}

			// Set appropriate state based on the JSON
			setUseUniqueFormsForGroups(!modernJson.isMainFormGroup);

			if (!modernJson.isMainFormGroup && modernJson.userGroupId) {
				setSelectedUserGroup(modernJson.userGroupId);
			}

			// Convert the modern JSON to formDrafts structure
			const newFormDrafts = {
				main: { ...formDrafts.main },
				userGroups: { ...formDrafts.userGroups },
			};

			// Process each form type
			Object.keys(modernJson.forms).forEach((formKey) => {
				const formType =
					formKey.charAt(0).toUpperCase() + formKey.slice(1);
				const formData = modernJson.forms[formKey];

				if (formData && formData.elements) {
					// Sort elements by sortOrder if available
					const elements = [...formData.elements].sort(
						(a, b) => (a.sortOrder || 0) - (b.sortOrder || 0),
					);

					// Create the form state object
					const formState = {
						elements,
						metadata: formData.metadata || {},
					};

					// Add to appropriate draft location
					if (modernJson.isMainFormGroup) {
						newFormDrafts.main[formType] = formState;
					} else if (modernJson.userGroupId) {
						if (!newFormDrafts.userGroups[modernJson.userGroupId]) {
							newFormDrafts.userGroups[modernJson.userGroupId] =
								{};
						}
						newFormDrafts.userGroups[modernJson.userGroupId][
							formType
						] = formState;
					}
				}
			});

			// Update the formDrafts state
			setFormDrafts(newFormDrafts);

			// Load the current form type if available
			const currentFormType = formType;
			const lowerFormType = currentFormType.toLowerCase();

			if (modernJson.forms[lowerFormType]) {
				const formData = modernJson.forms[lowerFormType];

				setData((prevData) => ({
					...prevData,
					elements: formData.elements || [],
				}));

				setFormMetadata(
					formData.metadata || getDefaultMetadata(currentFormType),
				);
			}

			return true;
		} catch (error) {
			console.debug(
				'Forms likely do not exist yet for this user group',
				error,
			);
			return false;
		}
	};

	const loadFormsFromS3 = async (userGroupId = null) => {
		const isMain = !userGroupId;
		const key = isMain ? 'main' : userGroupId;

		// Check if we've already loaded this form group from S3 in this session
		if (
			(isMain && loadedFromS3.main) ||
			(!isMain && loadedFromS3.userGroups[userGroupId])
		) {
			return true;
		}

		try {
			// Determine the file path based on whether it's for main company or a user group
			const fileName = isMain
				? 'main_forms.json'
				: `${userGroupId}_forms.json`;
			const filePath = `forms/${currentUser.companyId}/${fileName}`;

			// Get a signed URL for the S3 object
			const signedUrl = await downloadFromS3Signed(
				filePath,
				'erin-documents',
			);

			if (!signedUrl) {
				return false;
			}

			// Fetch the JSON using the signed URL
			const response = await fetch(signedUrl);

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const jsonData = await response.json();

			const success = loadFormsFromModernFormat(jsonData);

			if (success) {
				// Mark this form group as loaded from S3
				setLoadedFromS3((prev) => ({
					...prev,
					main: isMain ? true : prev.main,
					userGroups: isMain
						? prev.userGroups
						: {
								...prev.userGroups,
								[userGroupId]: true,
							},
				}));
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.debug(
				'Forms likely do not exist yet for this user group',
				error,
			);
			return false;
		}
	};

	const saveCurrentFormState = useCallback(() => {
		const currentFormState = {
			elements: data.elements,
			metadata: formMetadata || getDefaultMetadata(formType),
		};

		setFormDrafts((prevDrafts) => {
			const updatedDrafts = { ...prevDrafts };

			if (!useUniqueFormsForGroups) {
				updatedDrafts.main[formType] = currentFormState;
			} else if (selectedUserGroup) {
				if (!updatedDrafts.userGroups[selectedUserGroup]) {
					updatedDrafts.userGroups[selectedUserGroup] =
						createEmptyFormDrafts();
				}
				updatedDrafts.userGroups[selectedUserGroup][formType] =
					currentFormState;
			}

			return updatedDrafts;
		});
	}, [
		data.elements,
		formMetadata,
		formType,
		useUniqueFormsForGroups,
		selectedUserGroup,
	]);

	// State to store form drafts when switching contexts
	const [formDrafts, setFormDrafts] = useState({
		main: createEmptyFormDrafts(),
		userGroups: {},
	});

	// Handle form type change (General, Referral, Acceptance, Interested)
	const handleFormTypeChange = useCallback(
		(newType) => {
			// Save current form state before switching
			saveCurrentFormState();

			// Load the selected form type's draft
			let nextFormState = {
				elements: [],
				metadata: getDefaultMetadata(newType),
			};

			if (!useUniqueFormsForGroups) {
				nextFormState = formDrafts.main[newType] || nextFormState;
			} else if (selectedUserGroup) {
				if (
					formDrafts.userGroups[selectedUserGroup] &&
					formDrafts.userGroups[selectedUserGroup][newType]
				) {
					nextFormState =
						formDrafts.userGroups[selectedUserGroup][newType];
				}
			}

			// Update state with the loaded draft
			setData((prevData) => ({
				...prevData,
				elements: nextFormState.elements,
			}));
			setFormType(newType);
			setFormMetadata(nextFormState.metadata);
		},
		[
			formDrafts,
			useUniqueFormsForGroups,
			selectedUserGroup,
			saveCurrentFormState,
		],
	);

	const handleUniqueFormsToggle = useCallback(
		async (checked) => {
			// Save current form state before switching
			saveCurrentFormState();

			// Set the toggle state
			setUseUniqueFormsForGroups(checked);

			if (checked && userGroups.length > 0) {
				setTimeout(() => {
					handleUserGroupChange(userGroups[0].id);
				}, 0);
			} else {
				// When disabling user groups, use the stored main form from drafts
				// instead of always trying to load from S3
				const mainDraft = formDrafts.main[formType] || {
					elements: [],
					metadata: getDefaultMetadata(formType),
				};

				setData((prevData) => ({
					...prevData,
					elements: mainDraft.elements,
				}));
				setFormMetadata(mainDraft.metadata);
				setSelectedUserGroup(null);
			}
		},
		[
			data.elements,
			formMetadata,
			formType,
			formDrafts,
			useUniqueFormsForGroups,
			selectedUserGroup,
			userGroups,
		],
	);

	const handleUserGroupChange = useCallback(
		async (userGroupId) => {
			// Save current form state before switching
			saveCurrentFormState();

			// Set the selected user group
			setSelectedUserGroup(userGroupId);

			// Check if we already have this user group's forms in drafts before loading from S3
			if (
				formDrafts.userGroups[userGroupId] &&
				formDrafts.userGroups[userGroupId][formType]
			) {
				// Use existing drafts instead of loading from S3
				const userGroupDraft =
					formDrafts.userGroups[userGroupId][formType];
				setData((prevData) => ({
					...prevData,
					elements: userGroupDraft.elements,
				}));
				setFormMetadata(
					userGroupDraft.metadata || getDefaultMetadata(formType),
				);
			} else {
				// Only load from S3 if we don't have this user group's forms in drafts
				try {
					// Show loading message
					const loadingMessage = message.loading(
						'Loading forms...',
						0,
					);

					const formsLoaded = await loadFormsFromS3(userGroupId);

					// Close loading message
					loadingMessage();

					// If forms couldn't be loaded from S3, reset to empty form
					if (!formsLoaded) {
						setData((prevData) => ({
							...prevData,
							elements: [],
						}));
						setFormMetadata(getDefaultMetadata(formType));
					}
				} catch (error) {
					console.error('Error handling user group change:', error);
					message.error(
						'An error occurred while changing user groups',
					);

					// Reset to empty form
					setData((prevData) => ({
						...prevData,
						elements: [],
					}));
					setFormMetadata(getDefaultMetadata(formType));
				}
			}
		},
		[data.elements, formDrafts, formMetadata, formType, selectedUserGroup],
	);
	const clearForm = useCallback(() => {
		if (data.elements.length > 0) {
			Modal.confirm({
				title: 'Clear Form',
				content:
					'Are you sure you want to clear the form? All unsaved changes will be lost.',
				onOk: () => {
					setData((prevData) => ({
						...prevData,
						elements: [],
					}));
					setFormMetadata(getDefaultMetadata(formType));
				},
			});
		}
	}, [data.elements.length, formType]);

	const copyFromMainForm = useCallback(() => {
		if (!useUniqueFormsForGroups || !selectedUserGroup) {
			return;
		}

		const mainForm = formDrafts.main[formType];

		if (!mainForm || !mainForm.elements || mainForm.elements.length === 0) {
			message.info('The main form is empty. Nothing to copy.');
			return;
		}

		const clonedElements = mainForm.elements.map((element) => ({
			...element,
			id: uuidv4(),
		}));

		setData((prevData) => ({
			...prevData,
			elements: clonedElements,
		}));

		setFormMetadata({
			...mainForm.metadata,
		});

		message.success('Successfully copied elements from the main form.');
	}, [useUniqueFormsForGroups, selectedUserGroup, formDrafts.main, formType]);

	return (
		<FormBuilderContext.Provider value={contextValue}>
			<Title level={2}>Form Builder</Title>
			<Row gutter={16}>
				<Col span={4}>
					<Card
						title={
							<div style={{ fontWeight: 900, fontSize: '16px' }}>
								Form Picker
							</div>
						}
						size="small"
						style={{
							marginBottom: '16px',
							borderRadius: '8px',
						}}
					>
						<div style={{ marginBottom: '16px' }}>
							<label
								style={{
									display: 'block',
									marginBottom: '8px',
								}}
							>
								Form Type:
							</label>
							<Select
								style={{ width: '100%' }}
								value={formType}
								onChange={handleFormTypeChange}
							>
								<Option value="Referral">Referral Form</Option>
								<Option value="Acceptance">
									Acceptance Form
								</Option>
								<Option value="Interested">
									I'm Interested Form
								</Option>
								<Option value="General">
									General/Lead Form
								</Option>
							</Select>
						</div>
						<div style={{ marginBottom: '16px' }}>
							<Checkbox
								checked={useUniqueFormsForGroups}
								onChange={(e) => {
									saveCurrentFormState();
									handleUniqueFormsToggle(e.target.checked);
									if (
										e.target.checked &&
										userGroups.length > 0
									) {
										setTimeout(() => {
											handleUserGroupChange(
												userGroups[0].id,
											);
										}, 0);
									}
								}}
							>
								Create unique forms for different user groups?
							</Checkbox>
						</div>

						{useUniqueFormsForGroups && (
							<div>
								<label
									style={{
										display: 'block',
										marginBottom: '8px',
									}}
								>
									Select User Group:
								</label>
								<Select
									style={{ width: '100%' }}
									placeholder="Select User Group"
									value={selectedUserGroup}
									onChange={handleUserGroupChange}
								>
									{userGroups.map((userGroup) => (
										<Option
											key={userGroup.id}
											value={userGroup.id}
										>
											{userGroup.name}
										</Option>
									))}
								</Select>
							</div>
						)}
					</Card>

					{/* Form Elements Sidebar */}
					<FormElementsSidebar onAddElement={addElement} />

					{/* Form Metadata Settings */}
					<FormMetadataSettings
						metadata={formMetadata}
						onChange={setFormMetadata}
						formType={formType}
						style={{ maxWidth: '100%' }}
					/>
				</Col>

				{/* Form editor and preview */}
				<Col span={20}>
					<Card
						title={
							<>
								<div style={{ fontWeight: 900 }}>
									{formTypeHeaders[formType].title}
								</div>
								<div
									style={{ fontSize: '14px', color: '#666' }}
								>
									{formTypeHeaders[formType].description}
								</div>
							</>
						}
						style={{ borderRadius: '8px' }}
						extra={
							<Space>
								<Button
									type="primary"
									icon={<SaveOutlined />}
									onClick={saveFormsToModernFormat}
								>
									Save Forms
								</Button>
								<Button type="ghost" danger onClick={clearForm}>
									Clear
								</Button>
							</Space>
						}
					>
						<Row
							gutter={16}
							style={{
								minHeight: 'calc(100vh - 200px)',
								marginLeft: 0,
								marginRight: 0,
							}}
						>
							{/* Form editor area - left side */}
							<Col
								span={12}
								style={{
									height: '100%',
									paddingLeft: 0,
									paddingRight: '24px',
								}}
							>
								<Card
									title={
										<span style={{ fontWeight: 900 }}>
											Form Editor
										</span>
									}
									size="small"
									headStyle={{ padding: 0 }}
									style={{
										height: '100%',
										overflowY: 'auto',
										border: 'none',
									}}
								>
									<FormArea
										elements={data.elements}
										onEdit={editElement}
										onDuplicate={duplicateElement}
										onRemove={removeElement}
										onAddElement={addElement}
										reorderElement={reorderElement}
										formAreaId="main-form-area"
										showCopyFromMainButton={
											useUniqueFormsForGroups &&
											selectedUserGroup &&
											data.elements.length === 0
										}
										onCopyFromMain={copyFromMainForm}
									/>
								</Card>
							</Col>

							{/* Form preview area - right side */}
							<Col span={12} style={{ height: '100%' }}>
								<FormPreview
									elements={data.elements}
									formMetadata={formMetadata}
									formType={formType}
									currentUser={currentUser}
									allMultiLingualData={allMultiLingualData}
								/>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			{/* Element editor modal */}
			<ElementEditor
				element={editingElement}
				onSave={handleSaveElement}
				visible={isEditorVisible}
				onCancel={() => {
					setIsEditorVisible(false);
					setEditingElement(null);
				}}
			/>
		</FormBuilderContext.Provider>
	);
}
