import { Button, Checkbox, DatePicker, Input } from 'antd';
import get from 'lodash/get';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';
import { USER_ROLES } from '../../constants';
import { formatDate } from '../../services/utils';

dayjs.extend(utc);

const { TextArea } = Input;
function ReferralEditModal(props) {
	const { currentUser, referral, handleReferralCancel, onUpdateReferral } =
		props;
	const languageCode = get(currentUser, 'languageCode', 'US');
	const displayAs = get(currentUser, 'displayAs');
	const isManagerPermissionDisabled = get(
		currentUser,
		'company.disableManagerPermissions',
		false
	);

	const [editMode, setEditMode] = useState(false);
	const [startDate, setStartDate] = useState(
		(referral.startDate && dayjs.utc(referral.startDate)) || null
	);
	const [hireDate, setHireDate] = useState(
		(referral.hireDate && dayjs.utc(referral.hireDate)) || null
	);
	const [diversityHire, setDiversityHire] = useState(
		get(referral, 'diversityHire', false)
	);
	const [adminNote, setAdminNote] = useState(get(referral, 'adminNote', ''));
	const submitChanges = () => {
		const updatedReferral = {
			input: {
				id: referral.id,
				hireDate: hireDate && hireDate.toISOString(),
				startDate: startDate && startDate.toISOString(),
				diversityHire,
				adminNote,
			},
		};
		if (editMode) {
			onUpdateReferral(updatedReferral);
		}

		setEditMode(false);
		handleReferralCancel();
	};

	const status = get(referral, 'status');
	const DisableClass = status !== 'hired';
	const DiversityDisable = Boolean(
		status === 'notHired' ||
			status === 'noresponse' ||
			status === 'declined' ||
			status === 'inactive'
	);
	return (
		<>
			{isManagerPermissionDisabled &&
			displayAs === USER_ROLES.MANAGER ? null : (
				<div className="text-right">
					<Button
						type="link"
						onClick={() => {
							setEditMode(true);
						}}
					>
						<i className="icon-edit" />
						<span>EDIT REFERRAL</span>
					</Button>
				</div>
			)}
			<ul className="label-data">
				<li className={`${DiversityDisable && 'disabled'}`}>
					<label>Diversity Hire:</label>
					<Checkbox
						checked={diversityHire}
						onChange={(value) => {
							setDiversityHire(value.target.checked);
						}}
					/>
				</li>
				<li className={DisableClass && 'disabled'}>
					<label>{`${'Hire Date'}:`}</label>
					{editMode ? (
						<div className="custom-form-group">
							<DatePicker
								className="custom-input"
								format="MM-DD-YYYY"
								value={hireDate}
								onChange={(value) => {
									setHireDate(value);
								}}
							/>
						</div>
					) : (
						<>
							{hireDate
								? formatDate(hireDate.toISOString(), languageCode)
								: formatDate(referral.hireDate, languageCode)}
						</>
					)}
				</li>
				<li className={DisableClass && 'disabled'}>
					<label>{`${'Start Date'}:`}</label>
					{editMode ? (
						<div className="custom-form-group">
							<DatePicker
								className="custom-input"
								format="MM-DD-YYYY"
								value={startDate}
								onChange={(value) => {
									setStartDate(value);
								}}
							/>
						</div>
					) : (
						<>
							{startDate
								? formatDate(startDate.toISOString(), languageCode)
								: formatDate(referral.startDate, languageCode)}
						</>
					)}
				</li>
				<li>
					<label>{`${'Referral Notes'}:`}</label>
					{editMode ? (
						<div className="custom-form-group" style={{ width: '100%' }}>
							<TextArea
								autoSize={{ minRows: 2, maxRows: 6 }}
								className="custom-input"
								placeholder="Add Notes"
								value={adminNote}
								onChange={(e) => {
									setAdminNote(e.target.value);
								}}
							/>
						</div>
					) : (
						<>{adminNote}</>
					)}
				</li>
			</ul>

			{editMode ? (
				<div className="modal-footer-btn">
					<Button
						size="large"
						className="btn-min-width"
						onClick={() => {
							setEditMode(false);
						}}
					>
						Cancel
					</Button>
					<Button
						type="primary"
						size="large"
						className="btn-min-width"
						onClick={() => submitChanges()}
					>
						Save
					</Button>
				</div>
			) : (
				<div className="modal-footer-btn">
					<Button
						type="primary"
						className="btn-min-width"
						size="large"
						onClick={() => handleReferralCancel()}
					>
						Close
					</Button>
				</div>
			)}
		</>
	);
}

export default ReferralEditModal;
