import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

// Create the context with null as default value
export const FormBuilderContext = createContext(null);

// Hook to use the form builder context
export function useFormBuilderContext() {
	const value = useContext(FormBuilderContext);
	invariant(value, 'cannot find FormBuilderContext provider');
	return value;
}
