import { get } from 'lodash';
import { showConfirmation } from '../referral-tiers/ReferralTiersSettingsComponent.jsx';

export function EligibilityCustomItem(props) {
	const {
		eligbilityChecks,
		company,
		fieldName,
		onUpdateCompany,
		style,
		isSuperAdmin,
	} = props;

	const inactivateCustomField = (name, fieldName) => {
		const originalEligibilityChecks = { ...eligbilityChecks };

		showConfirmation({
			onConfirm: () => {
				let updatedEligibilityChecks = { ...eligbilityChecks };

				if (
					updatedEligibilityChecks &&
					updatedEligibilityChecks[fieldName] &&
					fieldName == 'CustomValuesToCheck'
				) {
					let customValuesToCheck = [
						...updatedEligibilityChecks.CustomValuesToCheck,
					];
					const index = customValuesToCheck.findIndex(
						(item) => item.name === name,
					);

					if (index !== -1) {
						customValuesToCheck.splice(index, 1);
					}

					updatedEligibilityChecks.CustomValuesToCheck =
						customValuesToCheck;

					if (props.onUpdateState) {
						props.onUpdateState(updatedEligibilityChecks);
					}

					onUpdateCompany({
						input: {
							id: company.id,
							eligibiltyChecks: JSON.stringify(
								updatedEligibilityChecks,
							),
						},
					});
				} else if (
					updatedEligibilityChecks &&
					updatedEligibilityChecks[fieldName] &&
					fieldName != 'CustomValuesToCheck'
				) {
					let fieldValues =
						updatedEligibilityChecks[fieldName].split('|');
					const fieldIndex = fieldValues.indexOf(name);
					if (fieldIndex > -1) {
						fieldValues.splice(fieldIndex, 1);
					}

					fieldValues = fieldValues.join('|');
					updatedEligibilityChecks[fieldName] = fieldValues;

					if (props.onUpdateState) {
						props.onUpdateState(updatedEligibilityChecks);
					}

					onUpdateCompany({
						input: {
							id: company.id,
							eligibiltyChecks: JSON.stringify(
								updatedEligibilityChecks,
							),
						},
					});
				}
			},
			onCancel: () => {
				if (props.onCancelChanges) {
					props.onCancelChanges(originalEligibilityChecks);
				}
			},
		});
	};

	return (
		<ul className="tag-wrap">
			{eligbilityChecks &&
			eligbilityChecks[fieldName] &&
			fieldName == 'CustomValuesToCheck'
				? eligbilityChecks &&
					eligbilityChecks.CustomValuesToCheck.map(
						(item) => item.name,
					).map((name, index) => {
						let marginLeft = get(style, 'marginLeft')
							? style.marginLeft
							: 4;
						if (index === 0) marginLeft = 0;
						return (
							<li key={index} className="tag green">
								<span className="tag-name">{name}</span>
								{isSuperAdmin && (
									<i
										className="tag-close icon-cross"
										onClick={() =>
											inactivateCustomField(
												name,
												fieldName,
											)
										}
										style={{ cursor: 'pointer' }}
									/>
								)}
							</li>
						);
					})
				: eligbilityChecks &&
					  eligbilityChecks[fieldName] &&
					  fieldName != 'CustomValuesToCheck'
					? eligbilityChecks &&
						eligbilityChecks[fieldName]
							.split('|')
							.map((name, index) => {
								let marginLeft = get(style, 'marginLeft')
									? style.marginLeft
									: 4;
								if (index === 0) marginLeft = 0;
								return (
									<li key={index} className="tag green">
										<span className="tag-name">{name}</span>
										{isSuperAdmin && (
											<i
												className="tag-close icon-cross"
												onClick={() =>
													inactivateCustomField(
														name,
														fieldName,
													)
												}
												style={{ cursor: 'pointer' }}
											/>
										)}
									</li>
								);
							})
					: null}
		</ul>
	);
}
