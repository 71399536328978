import { memo, useRef, useEffect } from 'react';
import { Card, Space, Tag } from 'antd';
import { BsTextareaResize } from 'react-icons/bs';
import { LuTextCursorInput } from 'react-icons/lu';
import { RiDropdownList, RiDraggable } from 'react-icons/ri';
import { LuType } from 'react-icons/lu';
import { RxDividerHorizontal, RxSwitch } from 'react-icons/rx';

import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { preserveOffsetOnSource } from '@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source';

import { useFormBuilderContext } from 'src/new-form-builder/FormBuilderContext';

// Types of form elements supported
const ELEMENT_TYPES = {
	INPUT: 'input',
	TEXTAREA: 'textarea',
	SELECT: 'select',
	LABEL: 'label',
	LINEBREAK: 'linebreak',
	// Commented out until we have time to add new form elements
	//SWITCH: 'switch'
};

export const FormElementsSidebar = memo(function FormElementsSidebar({
	onAddElement,
}) {
	const renderTag = () => {
		switch (element.type) {
			case ELEMENT_TYPES.INPUT:
				return <Tag color="blue">{'Text Input'}</Tag>;
			case ELEMENT_TYPES.TEXTAREA:
				return <Tag color="green">{'Multi-Line Input'}</Tag>;
			case ELEMENT_TYPES.SELECT:
				return <Tag color="magenta">{'Dropdown'}</Tag>;
			case ELEMENT_TYPES.LABEL:
				return <Tag color="gold">{'Rich Text'}</Tag>;
			case ELEMENT_TYPES.LINEBREAK:
				return <Tag color="cyan">{'Line Break'}</Tag>;
			// Commented out until we have time to add new form elements
			// case ELEMENT_TYPES.SWITCH:
			//   return (
			//     <Tag color="purple">
			//       {"Switch"}
			//     </Tag>
			//   );
			default:
				return null;
		}
	};
	// Define available elements
	const sidebarElements = [
		{
			id: 'input',
			type: ELEMENT_TYPES.INPUT,
			color: '#096dd9',
			background: '#e6f7ff',
			border: '#91d5ff',
			label: 'Text Input',
			icon: <LuTextCursorInput />,
		},
		{
			id: 'textarea',
			type: ELEMENT_TYPES.TEXTAREA,
			color: '#389e0d',
			background: '#f6ffed',
			border: '#b7eb8f',
			label: 'Multi-Line Input',
			icon: <BsTextareaResize />,
		},
		{
			id: 'select',
			type: ELEMENT_TYPES.SELECT,
			color: '#c41d7f',
			background: '#fff0f6',
			border: '#ffadd2',
			label: 'Dropdown',
			icon: <RiDropdownList />,
		},
		{
			id: 'label',
			type: ELEMENT_TYPES.LABEL,
			color: '#d48806',
			background: '#fffbe6',
			border: '#ffe58f',
			label: 'Rich Text',
			icon: <LuType />,
		},
		{
			id: 'linebreak',
			type: ELEMENT_TYPES.LINEBREAK,
			color: '#08979c',
			background: '#e6fffb',
			border: '#87e8de',
			label: 'Line Break',
			icon: <RxDividerHorizontal />,
		},
		// Commented out until we have time to add new form elements
		//{ id: 'switch', type: ELEMENT_TYPES.SWITCH, color: '#531dab', background: '#f9f0ff', border: '#d3adf7', label: 'Switch', icon: <RxSwitch /> }
	];

	// Get the instance ID from context
	const { instanceId } = useFormBuilderContext();

	// Refs for the sidebar elements
	const elementRefs = useRef(new Map());

	// Set up draggable for each sidebar element
	useEffect(() => {
		const cleanupFunctions = [];

		sidebarElements.forEach((element) => {
			const ref = elementRefs.current.get(element.id);
			if (!ref) return;

			const cleanup = draggable({
				element: ref,
				dragHandle: ref,
				getInitialData: () => ({
					type: 'sidebar-element',
					elementType: element.type,
					id: element.id,
					instanceId,
				}),
				onGenerateDragPreview: ({
					location,
					source,
					nativeSetDragImage,
				}) => {
					// Add visual feedback on drag start
					if (source.element) {
						source.element.classList.add('dragging');
					}

					// Create custom drag preview
					setCustomNativeDragPreview({
						nativeSetDragImage,
						getOffset: preserveOffsetOnSource({
							element: ref,
							input: location.current.input,
						}),
						render: ({ container }) => {
							// Create a styled preview element
							const previewEl = document.createElement('div');
							previewEl.className = 'sidebar-element preview';
							previewEl.innerHTML = ref.innerHTML;
							previewEl.style.width = `${ref.offsetWidth}px`;
							previewEl.style.backgroundColor =
								element.background;
							previewEl.style.opacity = '0.9';
							previewEl.style.boxShadow =
								'0 4px 8px rgba(0, 0, 0, 0.15)';
							previewEl.style.borderRadius = '4px';
							previewEl.style.padding = '10px';

							container.appendChild(previewEl);

							return () => {
								// No additional cleanup needed
							};
						},
					});
				},
				onDragStart: () => {
					if (ref) {
						ref.classList.add('dragging');
					}
				},
				onDrop: ({ source }) => {
					// Remove visual feedback
					if (source && source.element) {
						source.element.classList.remove('dragging');
					}
					if (ref) {
						ref.classList.remove('dragging');
					}
				},
			});

			cleanupFunctions.push(cleanup);
		});

		// Clean up all draggable instances
		return () => {
			cleanupFunctions.forEach((cleanup) => cleanup());
		};
	}, [instanceId]);

	return (
		<Card
			title={
				<div style={{ fontWeight: 900, fontSize: '16px' }}>
					Form Elements
				</div>
			}
			size="small"
			style={{
				marginBottom: '16px',
				maxWidth: '100%',
				borderRadius: '8px',
			}}
		>
			<div className="sidebar-elements-container">
				{sidebarElements.map((element) => (
					<div
						key={element.id}
						ref={(node) => {
							if (node) {
								elementRefs.current.set(element.id, node);
							} else {
								elementRefs.current.delete(element.id);
							}
						}}
						className="sidebar-element"
						style={{
							padding: '10px',
							margin: '5px 0',
							border: `1px dashed #d9d9d9`,
							borderRadius: '4px',
							backgroundColor: '#fafafa',
							cursor: 'grab',
							transition: 'all 0.2s ease',
						}}
						onClick={() => onAddElement(element.type)}
						onMouseEnter={(e) => {
							e.currentTarget.style.backgroundColor =
								element.background;
							e.currentTarget.style.color = element.color;
							e.currentTarget.style.border = `1px solid ${element.border}`;
						}}
						onMouseLeave={(e) => {
							e.currentTarget.style.backgroundColor = '#fafafa';
							e.currentTarget.style.color = '#000000A6';
							e.currentTarget.style.border = `1px dashed #d9d9d9`;
						}}
					>
						<Space align="center">
							<RiDraggable style={{ color: '#1890ff' }} />
							{element.icon}
							{element.label}
						</Space>
					</div>
				))}
			</div>
		</Card>
	);
});
