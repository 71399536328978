import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Typography, Button } from 'antd';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import {
	extractClosestEdge,
	attachClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { CopyOutlined } from '@ant-design/icons';

import { FormElementCard } from 'src/new-form-builder/FormElementCard';
import { useFormBuilderContext } from 'src/new-form-builder/FormBuilderContext';
import { FormAreaContext } from 'src/new-form-builder/FormAreaContext';
import { Reorder } from 'motion/react';
const { Text } = Typography;

const idle = { type: 'idle' };
const isElementOver = { type: 'is-element-over' };

export const FormArea = memo(function FormArea({
	elements,
	onEdit,
	onDuplicate,
	onRemove,
	onReorder,
	formAreaId,
	reorderElement,
	showCopyFromMainButton,
	onCopyFromMain,
}) {
	const scrollableRef = useRef(null);
	const contentRef = useRef(null);

	const [state, setState] = useState(idle);
	const [closestEdge, setClosestEdge] = useState(null);

	const { instanceId } = useFormBuilderContext();

	const stableElements = useRef(elements);
	useEffect(() => {
		stableElements.current = elements;
	}, [elements]);

	const getElementIndex = useCallback((elementId) => {
		return stableElements.current.findIndex(
			(element) => element.id === elementId,
		);
	}, []);

	const getNumElements = useCallback(() => {
		return stableElements.current.length;
	}, []);

	const contextValue = useMemo(() => {
		return { formAreaId, getElementIndex, getNumElements };
	}, [formAreaId, getElementIndex, getNumElements]);

	useEffect(() => {
		const scrollable = scrollableRef.current;

		if (!scrollable) return;

		return combine(
			dropTargetForElements({
				element: scrollable,
				getData: ({ input, element }) => {
					const data = {
						type: 'form-area',
						formAreaId,
					};

					return attachClosestEdge(data, {
						input,
						element,
						allowedEdges: ['top', 'bottom'],
					});
				},
				canDrop: ({ source }) => {
					return (
						source.data.instanceId === instanceId &&
						(source.data.type === 'sidebar-element' ||
							source.data.type === 'form-element')
					);
				},
				getIsSticky: () => true,
				onDragEnter: (args) => {
					if (
						args.source.data.type === 'sidebar-element' ||
						args.source.data.type === 'form-element'
					) {
						setState(isElementOver);
						setClosestEdge(extractClosestEdge(args.self.data));
					}
				},
				onDrag: (args) => {
					if (
						args.source.data.type === 'sidebar-element' ||
						args.source.data.type === 'form-element'
					) {
						setState(isElementOver);
						setClosestEdge(extractClosestEdge(args.self.data));
					} else {
						setState(idle);
						setClosestEdge(null);
					}
				},
				onDragLeave: () => {
					setState(idle);
					setClosestEdge(null);
				},
				onDrop: (args) => {
					const { source, location } = args;
					const sourceIndex = source.data.index;
					if (sourceIndex !== undefined) {
						const data = location.current.dropTargets[0].data;
						let targetIndex = data.index;
						const symbols = Object.getOwnPropertySymbols(data);
						const closestEdgeSymbol = symbols.find(
							(sym) => sym.toString() === 'Symbol(closestEdge)',
						);
						if (closestEdgeSymbol && targetIndex !== undefined) {
							if (
								data[closestEdgeSymbol] === 'top' &&
								sourceIndex < targetIndex
							) {
								targetIndex = targetIndex - 1;
							}
							if (
								data[closestEdgeSymbol] === 'bottom' &&
								sourceIndex > targetIndex
							) {
								targetIndex = targetIndex + 1;
							}
							reorderElement({
								elementId:
									location.current.dropTargets[0].data.type,
								startIndex: sourceIndex,
								finishIndex: targetIndex,
								trigger: 'pointer',
							});
						}
					}
					setState(idle);
					setClosestEdge(null);
				},
			}),

			autoScrollForElements({
				element: scrollable,
				canScroll: ({ source }) =>
					source.data.instanceId === instanceId &&
					(source.data.type === 'form-element' ||
						source.data.type === 'sidebar-element'),
			}),
		);
	}, [formAreaId, instanceId]);
	const spring = {
		type: 'spring',
		damping: 25,
		stiffness: 120,
	};

	return (
		<FormAreaContext.Provider value={contextValue}>
			<div
				ref={scrollableRef}
				className={`form-area-scrollable ${state.type === 'is-element-over' ? 'drop-active' : ''}`}
				style={{
					height: '100%',
					padding: '16px',
					backgroundColor:
						state.type === 'is-element-over'
							? 'aliceblue'
							: '#f5f5f5',
					minHeight: '760px',
					maxHeight: '100%',
					overflowY: 'auto',
					borderRadius: '8px',
					border:
						state.type === 'is-element-over'
							? '1px solid rgb(100, 221, 255)'
							: '1px dashed #d9d9d9',
					position: 'relative',
					transition: 'all 0.3s ease',
				}}
			>
				<div
					ref={contentRef}
					className="form-area-content"
					style={{
						boxSizing: 'border-box',
						minHeight: '100%',
						padding: 0,
						display: 'flex',
						flexDirection: 'column',
						gap: '12px',
					}}
				>
					{elements.length === 0 ? (
						<div
							className="form-builder-empty"
							style={{ textAlign: 'center', padding: '20px 0' }}
						>
							<Text type="secondary">
								Drag elements from the sidebar or click to add
							</Text>

							{showCopyFromMainButton && (
								<div style={{ marginTop: '12px' }}>
									<Button
										type="primary"
										icon={<CopyOutlined />}
										onClick={onCopyFromMain}
									>
										Copy from Main Form
									</Button>
								</div>
							)}
						</div>
					) : (
						<Reorder.Group
							values={elements}
							onReorder={onReorder}
							style={{ listStyle: 'none', padding: 0, margin: 0 }}
						>
							{elements.map((element, index) => (
								<Reorder.Item
									dragListener={false}
									key={element.id}
									value={element}
									style={{ margin: '6px 0' }}
								>
									<FormElementCard
										key={element.id}
										layout
										transition={spring}
										element={element}
										index={index}
										onEdit={() => onEdit(index)}
										onDuplicate={() => onDuplicate(index)}
										onRemove={() => onRemove(index)}
									/>
								</Reorder.Item>
							))}
						</Reorder.Group>
					)}
				</div>
			</div>
		</FormAreaContext.Provider>
	);
});
