import { useState, useEffect, Suspense } from 'react';
import { store } from 'src/store.js';
import { queryReferralQuestionsByCompanyId } from 'src/_shared/api/graphql/custom/referral-questions/';
import { withApollo } from 'react-apollo';
import { gql } from 'graphql-tag';
import { graphql } from 'src/_shared/services/utils.js';
import { Spinner } from 'src/_shared/components/spinner';
import FormBuilderApp from 'src/new-form-builder/FormBuilderApp';

const DataFetcher = ({
	currentUser,
	allMultiLingualData,
	client,
	urlError,
	children,
}) => {
	const [error, setError] = useState(null);
	const [pageData, setPageData] = useState(null);
	const [referralQuestions, setReferralQuestions] = useState(null);
	const [userGroups, setUserGroups] = useState(null);

	useEffect(() => {
		let isMounted = true;
		let newPageData = {};
		const fetchUserGroups = async () => {
			try {
				if (!pageData?.userGroups && currentUser?.companyId) {
					const userGroupsResponse = await graphql({
						input: { companyId: currentUser.companyId },
						query: 'queryUserGroupsByCompanyIdIndex',
					});

					const mappedUserGroups =
						userGroupsResponse?.items?.map(({ id, name }) => ({
							id,
							name,
						})) || [];
					newPageData.userGroups = [...mappedUserGroups];
					setUserGroups(mappedUserGroups);
				}
			} catch (error) {
				if (isMounted) {
					setError(error);
					newPageData.userGroups = [];
					setUserGroups([]);
				}
			}
		};
		const fetchReferralQuestions = async () => {
			try {
				if (!pageData?.referralQuestions && currentUser?.companyId) {
					const fetchQuestions = async (nextToken = null) => {
						const response = await client.query({
							query: gql(queryReferralQuestionsByCompanyId),
							fetchPolicy: 'network-only',
							variables: {
								companyId: currentUser.companyId,
								after: nextToken,
							},
						});
						const referralQuestionsData =
							response.data.queryReferralQuestionsByCompanyId
								.items;

						if (
							response.data.queryReferralQuestionsByCompanyId
								.nextToken
						) {
							const moreReferralQuestionsData =
								await fetchQuestions(
									response.data
										.queryReferralQuestionsByCompanyId
										.nextToken,
								);
							return [
								referralQuestionsData,
								...moreReferralQuestionsData,
							];
						}

						return referralQuestionsData;
					};
					const totalReferralQuestions =
						(await fetchQuestions()) || [];
					newPageData.legacyReferralQuestions = [
						...totalReferralQuestions,
					];
					setReferralQuestions(totalReferralQuestions);
				}
			} catch (error) {
				if (isMounted) {
					setError(error);
					newPageData.legacyReferralQuestions = [];
					setReferralQuestions([]);
				}
			}
		};
		fetchUserGroups();
		fetchReferralQuestions();
		setPageData({ ...pageData, ...newPageData });
		return () => {
			isMounted = false;
		};
	}, [currentUser?.companyId]);

	if (!userGroups || !referralQuestions || !currentUser)
		return <Spinner forceDefault />;
	return children({
		urlError,
		userGroups,
		referralQuestions,
		allMultiLingualData,
	});
};

const PragmaticFormBuilder = (props) => {
	const [urlError, setUrlError] = useState(false);
	if (urlError) {
		console.log('URL ERROR');
		return <Spinner forceDefault />;
	}
	const { currentUser } = store.getState().user;
	const { allMultiLingualData } = store.getState().dashboard;
	const client = props.client;
	return (
		<Suspense fallback={<Spinner forceDefault />}>
			<DataFetcher
				urlError={urlError}
				currentUser={currentUser}
				allMultiLingualData={allMultiLingualData}
				client={client}
			>
				{({
					urlError,
					userGroups,
					referralQuestions,
					allMultiLingualData,
				}) => (
					<div>
						{urlError ? (
							<div>Error fetching data</div>
						) : (
							<section className={'landing-background'}>
								<FormBuilderApp
									userGroups={userGroups}
									legacyReferralQuestions={referralQuestions}
									currentUser={currentUser}
									allMultiLingualData={allMultiLingualData}
								/>
							</section>
						)}
					</div>
				)}
			</DataFetcher>
		</Suspense>
	);
};

export default withApollo(PragmaticFormBuilder);
