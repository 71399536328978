import { Modal } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import _ from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { JobLocation } from '../components/index.js';
import {
	calculateReferralBonus,
	ml,
	parse,
	searchData,
	fetchModernFormQuestions,
} from '../services/utils';
import { ReferralComplianceForm, ReferralForm } from './referral-modal-form';
import * as ModalStyles from './referralModalStyles.js';

class ReferralModalComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			autoCompleteResult: [],
			isSubmitting: false,
			newContact: get(props, 'newContact', true),
			error: false,
			contact: props.contact,
			referralType: 'email',
			visible: props.visible,
			buttonState: '',
			buttonStateCompliance: '',
			isComplianceSubmitting: false,
			theme: parse(get(props, 'companyData.theme', '{}')),
			referralQuestions: [],
			resumeAttachData: [],
			errorMessage: null,
			disableSubmit: false,
		};
	}

	async componentDidMount() {
		const { currentUser } = this.props;
		const formType = 'Referral';
		const modernQuestions = await fetchModernFormQuestions(
			currentUser,
			formType,
		);
		if (modernQuestions && modernQuestions.length > 0) {
			const resumeAttachData = modernQuestions.filter(
				(question) => question.sortOrder === 0,
			);
			resumeAttachData[0].subCompanyId = null;
			this.setState({
				referralQuestions: _.sortBy(modernQuestions, ['sortOrder'])
					.filter((question) => question.sortOrder !== 0)
					.map((questionItem) => {
						if (typeof questionItem.questions === 'string') {
							try {
								return JSON.parse(questionItem.questions);
							} catch (e) {
								return questionItem.questions;
							}
						}
						return questionItem.questions;
					}),
				resumeAttachData: resumeAttachData,
			});
		} else {
			const questions = get(
				this.props,
				'companyData.referralQuestions',
				[],
			);
			const resumeAttachData = questions.filter(
				(question) => question.sortOrder === 0,
			);
			const allQuestionsSort = questions.filter(
				(question) => question.sortOrder !== 0,
			);
			const allQuestionsForEmployee = allQuestionsSort.filter(
				(question) =>
					question.isCandidate === false &&
					question.isInterested !== true &&
					question.isGeneral !== true &&
					!question?.subCompanyId,
			);
			const referralQuestions = [];
			if (allQuestionsForEmployee.length > 0) {
				allQuestionsForEmployee.map((element) => {
					referralQuestions.push(element.questions);
				});
			}
			this.setState({ referralQuestions, resumeAttachData });
		}

		if (this.props.matchFound) {
			this.setState({ autoCompleteResult: [this.props.matchFound] });
		}

		if (this.props.contact) {
			this.setState({ autoCompleteResult: [this.props.contact] });
		}
	}

	changeReferralType = (referralType) => {
		this.setState({ referralType });
	};

	changeReferralTypeCompliance = (referralType) => {
		this.setState({ referralType });
	};

	handleCancelAll = () => {
		this.setState({ visible: false });
		this.props.handleCancel();
	};

	handleContactChange = (value, contact) => {
		if (!contact) {
			this.setState({ contact: null });
		}

		let autoCompleteResult = [];
		if (contact) {
			autoCompleteResult = [contact];

			this.setState({ contact });
		} else if (value) {
			autoCompleteResult = searchData(
				['firstName', 'lastName'],
				value,
				this.props.contacts,
			);
		} else {
			autoCompleteResult = [];
		}

		this.setState({ autoCompleteResult });
	};

	handleError = (errorMessage = null) => {
		this.setState({ error: true, errorMessage });
		if (this.state.buttonState === '') {
			this.setState({
				buttonState: 'error',
				disableSubmit: false,
			});
		} else {
			this.setState({ buttonState: '', disableSubmit: false });
		}
	};

	handleNewContact = (contact) => {
		if (contact) this.handleContactChange(null, contact);

		this.setState((prevState) => ({ newContact: !prevState.newContact }));
	};

	handleViewContact = (contact) => {
		this.props.history.push(`/mycontacts/${contact.id}`);
	};

	toggleIsSubmitting = () => {
		this.setState({
			buttonState: 'loading',
			disableSubmit: true,
		});
		this.setState((prevState) => ({
			isSubmitting: !prevState.isSubmitting,
		}));
		setTimeout(() => {
			this.setState({
				buttonState: 'success',
			});
		}, 4000);
		setTimeout(() => {
			this.setState({
				buttonState: '',
				disableSubmit: false,
			});
		}, 6000);
	};

	toggleIsSubmittingCompliance = () => {
		this.setState({
			buttonStateCompliance: 'loading',
			disableSubmit: true,
		});
		this.setState((prevState) => ({
			isComplianceSubmitting: !prevState.isComplianceSubmitting,
		}));
		setTimeout(() => {
			this.setState({ buttonStateCompliance: 'success' });
		}, 2500);
		setTimeout(() => {
			this.setState({ buttonStateCompliance: '', disableSubmit: false });
		}, 6000);
	};

	render() {
		const {
			allMultiLingualData,
			currentUser,
			campaignId,
			client,
			companyData,
			contacts,
			handleCancel,
			job,
			nextToken,
			notification,
			onCreateContact,
			onCreateReferral,
			onUpdateContact,
			referrals,
			visible,
			setCurrentUser,
			onUpdateJob,
			onCreateUserJobShare,
			onUpdateUserJobShare,
		} = this.props;

		const {
			company,
			autoCompleteResult,
			contact,
			referralType,
			newContact,
			isSubmitting,
			error,
			errorMessage,
			buttonState,
			isComplianceSubmitting,
			theme,
			referralQuestions,
			resumeAttachData,
			disableSubmit,
		} = this.state;

		let referralBonus = get(this.props, 'job.referralBonus');
		const location = parse(
			get(
				this.props,
				'job.job.location',
				get(this.props, 'job.location'),
			),
		);
		const locations = parse(this.props?.job?.locations);
		if (typeof referralBonus === 'string') {
			referralBonus = parse(referralBonus);
		}

		const currentCurrencyRate = get(this.props, 'currentCurrencyRate', 1);
		const currentCurrencySymbol = get(
			this.props,
			'currentCurrencySymbol',
			'$',
		);
		const eligible = get(currentUser, 'incentiveEligible');
		const amount = get(job, 'referralBonus.amount');
		let currentTieredBonus =
			get(job, 'tieredBonus') || get(this.props, 'currentTieredBonus');
		const contactIncentiveBonus = get(
			this.props,
			'currentUser.company.contactIncentiveBonus',
			0,
		);

		const campaign = get(job, 'campaign');

		if (
			!campaign ||
			!campaign.id ||
			campaign.archived == 'true' ||
			!campaign.tieredBonus
		) {
			currentTieredBonus =
				get(job, 'tieredBonus') ||
				get(this.props, 'currentTieredBonus');
		} else {
			currentTieredBonus = get(job, 'campaign.tieredBonus');
		}

		const formattedCurrency = `${calculateReferralBonus(
			contactIncentiveBonus,
			amount,
			eligible,
			currentTieredBonus,
			'employee',
			get(currentUser, 'userGroupId'),
		)}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
		let toggleClass;
		let isShowBonus;
		isShowBonus = formattedCurrency !== '0';
		const isCompliance = get(
			currentUser.company,
			'confirmCompliance',
			false,
		);
		if (isCompliance) {
			toggleClass = isComplianceSubmitting
				? ModalStyles.ModalStylesSubmitting
				: ModalStyles.ModalStyles;
		} else {
			toggleClass = isSubmitting
				? ModalStyles.ModalStylesSubmitting
				: ModalStyles.ModalStyles;
		}

		let userGroupCurrency = get(currentUser, 'userGroup.currency');
		if (userGroupCurrency === null) userGroupCurrency = 'USD';
		const getFormattedCurrency = (formattedCurrency) => {
			if (formattedCurrency !== 'NaN') {
				return `${getSymbolFromCurrency(
					userGroupCurrency,
				)}${formattedCurrency}`;
			}

			return 'None';
		};

		return job ? (
			<Modal
				centered
				open={visible}
				footer={null}
				width={600}
				className="referral-modal"
				title={ml('Refer Someone', currentUser, allMultiLingualData)}
				onCancel={this.handleCancelAll}
			>
				{nextToken ? (
					<Spinner company={company} />
				) : (
					<>
						<p className="text-center">
							{ml(
								'We will send them information to apply',
								currentUser,
								allMultiLingualData,
							)}
						</p>
						<div className="modal-job-info-wrap">
							<div className="mj-info-wrap">
								<h3 className="mj-info-name">{job.title}</h3>

								{isShowBonus && (
									<ul className="mj-info-bonus">
										{job?.isHotJob && (
											<li>
												<i className="icon-fire text-orange" />
											</li>
										)}
										<li>
											{formattedCurrency === '0' ? (
												<span>
													{ml(
														'No Bonus',
														currentUser,
														allMultiLingualData,
													)}
												</span>
											) : (
												<span className="text-green">
													{getFormattedCurrency(
														formattedCurrency,
													)}
												</span>
											)}
										</li>
									</ul>
								)}
							</div>

							<ul className="mj-info">
								<JobLocation
									allMultiLingualData={allMultiLingualData}
									currentUser={currentUser}
									location={location}
									locations={locations}
								/>
								{job.department && (
									<li>
										<i className="icon-folder" />
										<span>
											{job.department
												? job.department.name
												: ''}
										</span>
									</li>
								)}
							</ul>
						</div>
						{isCompliance ? (
							<ReferralComplianceForm
								handleViewContact={this.handleViewContact}
								theme={theme}
								allMultiLingualData={
									this.props.allMultiLingualData
								}
								changeReferralTypeCompliance={
									this.changeReferralTypeCompliance
								}
								contact={contact}
								contacts={contacts}
								referralType={referralType}
								referralQuestions={referralQuestions}
								toggleIsSubmittingCompliance={
									this.toggleIsSubmittingCompliance
								}
								handleCancel={handleCancel}
								handleCancelAll={this.handleCancelAll}
								error={error}
								errorMessage={errorMessage}
								handleError={this.handleError}
								currentUser={currentUser}
								visible={visible}
								job={job}
								notification={notification}
								currentCurrencyRate={currentCurrencyRate}
								currentCurrencySymbol={currentCurrencySymbol}
								companyData={companyData}
								buttonState={this.state.buttonStateCompliance}
								disableSubmit={disableSubmit}
								handleNotificationCompleted={
									this.props.handleNotificationCompleted
								}
								client={client}
								onCreateContact={onCreateContact}
								onCreateReferral={onCreateReferral}
								onCreateWebNotification={
									this.props.onCreateWebNotification
								}
								onUpdateJob={onUpdateJob}
								onCreateUserJobShare={onCreateUserJobShare}
								onUpdateUserJobShare={onUpdateUserJobShare}
							/>
						) : (
							<ReferralForm
								handleViewContact={this.handleViewContact}
								allMultiLingualData={
									this.props.allMultiLingualData
								}
								newContact={newContact}
								theme={theme}
								handleNewContact={this.handleNewContact}
								setUpdatedContacts={
									this.props.setUpdatedContacts
								}
								contacts={this.props.contacts}
								handleContactChange={this.handleContactChange}
								autoCompleteResult={autoCompleteResult}
								isCompliance={isCompliance}
								isSubmitting={isSubmitting}
								toggleIsSubmitting={this.toggleIsSubmitting}
								handleCancel={handleCancel}
								handleCancelAll={this.handleCancelAll}
								error={error}
								errorMessage={errorMessage}
								handleError={this.handleError}
								notification={notification}
								currentUser={currentUser}
								visible={visible}
								job={job}
								matchFound={this.props.matchFound}
								contact={contact}
								referrals={referrals}
								currentCurrencyRate={currentCurrencyRate}
								currentCurrencySymbol={currentCurrencySymbol}
								companyData={companyData}
								buttonState={buttonState}
								currentTieredBonus={currentTieredBonus}
								userGroupCurrency={userGroupCurrency}
								referralQuestions={referralQuestions}
								resumeAttachData={resumeAttachData}
								disableSubmit={disableSubmit}
								campaignId={campaignId}
								handleNotificationCompleted={
									this.props.handleNotificationCompleted
								}
								client={client}
								setCurrentUser={setCurrentUser}
								onCreateContact={onCreateContact}
								onCreateReferral={onCreateReferral}
								onUpdateContact={onUpdateContact}
								onUpdateJob={onUpdateJob}
								onCreateUserJobShare={onCreateUserJobShare}
								onUpdateUserJobShare={onUpdateUserJobShare}
							/>
						)}
					</>
				)}
			</Modal>
		) : null;
	}
}

export default withApollo(withRouter(ReferralModalComponent));
