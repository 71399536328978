import get from 'lodash/get';
import { connect } from 'react-redux';

// Global themes/styles can be built in this file which will be accessable throught the whole app
// How it works: You create someVariable for the common styles, return it in classNames at the bottom, then anywhere in the app
// on an element you can say type="someVariable" to apply those styles
// See examble below for type="addButton"...This is the colored round add buttons for add employee, add referral, add job etc.
// This eliminates the need to get and add the theme in the css file for every component that wants it and duplicating the styles.

export default connect((state) => ({
	themeJson: get(state, ['user', 'currentUser', 'company', 'theme']),
	themeEnabled: false,
}))(function ThemeProvider({ themeJson, themeEnabled, children }) {
	let theme;
	try {
		// Attempt to parse the theme json
		theme = JSON.parse(themeJson);
		themeEnabled = Boolean(theme.enabled);
	} catch {
		// If there was an error parsing the json or the theme is disabled
		// fallback to default theme
	}

	/**
	 * Brightens a hex color by converting to HSL, adjusting lightness, and converting back to hex
	 * @param {string} hexColor - Hex color string (with or without #)
	 * @param {number} factor - Amount to brighten (0-100), represents percentage increase in lightness
	 * @returns {string} Brightened hex color
	 */
	function brightenHexColor(hexColor, factor) {
		hexColor = hexColor.replace(/^#/, '');

		const r = parseInt(hexColor.slice(0, 2), 16) / 255;
		const g = parseInt(hexColor.slice(2, 4), 16) / 255;
		const b = parseInt(hexColor.slice(4, 6), 16) / 255;

		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);

		let h,
			s,
			l = (max + min) / 2;

		if (max === min) {
			h = s = 0;
		} else {
			const d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

			switch (max) {
				case r:
					h = (g - b) / d + (g < b ? 6 : 0);
					break;
				case g:
					h = (b - r) / d + 2;
					break;
				case b:
					h = (r - g) / d + 4;
					break;
			}
			h /= 6;
		}

		l = Math.min(1, l + factor / 100);

		let r1, g1, b1;

		if (s === 0) {
			// Achromatic (gray)
			r1 = g1 = b1 = l;
		} else {
			const hue2rgb = (p, q, t) => {
				if (t < 0) t += 1;
				if (t > 1) t -= 1;
				if (t < 1 / 6) return p + (q - p) * 6 * t;
				if (t < 1 / 2) return q;
				if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
				return p;
			};

			const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
			const p = 2 * l - q;

			r1 = hue2rgb(p, q, h + 1 / 3);
			g1 = hue2rgb(p, q, h);
			b1 = hue2rgb(p, q, h - 1 / 3);
		}

		const toHex = (x) => {
			const hex = Math.round(x * 255).toString(16);
			return hex.length === 1 ? '0' + hex : hex;
		};

		return `#${toHex(r1)}${toHex(g1)}${toHex(b1)}`;
	}

	if (themeEnabled) {
		const {
			buttonColor,
			addButtonColor,
			enabled,
			menuColor,
			menuHighlightColor,
		} = theme;

		addButtonColor &&
			document
				.querySelector(':root')
				.style.setProperty('--add-btn-color', addButtonColor);
		buttonColor &&
			document
				.querySelector(':root')
				.style.setProperty('--primary-btn-color', buttonColor);
		buttonColor &&
			document
				.querySelector(':root')
				.style.setProperty(
					'--primary-btn-highlight-color',
					brightenHexColor(buttonColor, 30),
				);
		menuColor &&
			document
				.querySelector(':root')
				.style.setProperty('--menu-color', menuColor);
		menuHighlightColor &&
			document
				.querySelector(':root')
				.style.setProperty(
					'--menu-highlight-color',
					menuHighlightColor,
				);
	} else {
		document
			.querySelector(':root')
			.style.setProperty('--add-btn-color', null);
		document
			.querySelector(':root')
			.style.setProperty('--primary-btn-color', null);
		document
			.querySelector(':root')
			.style.setProperty('--primary-btn-highlight-color', null);
		document.querySelector(':root').style.setProperty('--menu-color', null);
		document
			.querySelector(':root')
			.style.setProperty('--menu-highlight-color', null);
	}

	return children;
});
