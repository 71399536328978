import { get } from 'lodash';
import { showConfirmation } from '../referral-tiers/ReferralTiersSettingsComponent.jsx';

export function EligibilityCustomValueObject(props) {
	const {
		eligbilityChecks,
		company,
		fieldObject,
		onUpdateCompany,
		fieldType,
		style,
		isSuperAdmin,
	} = props;

	const inactivateCustomFieldInvalidOrValidValues = (
		name,
		fieldObject,
		fieldType,
	) => {
		const originalEligibilityChecks = { ...eligbilityChecks };
		const originalFieldObject = fieldObject ? { ...fieldObject } : null;

		showConfirmation({
			onConfirm: () => {
				let updatedEligibilityChecks = { ...eligbilityChecks };
				let updatedFieldObject = { ...fieldObject };

				if (updatedFieldObject && updatedFieldObject[fieldType]) {
					let theValues = updatedFieldObject[fieldType].split('|');

					const fieldIndex = theValues.indexOf(name);
					if (fieldIndex > -1) {
						theValues.splice(fieldIndex, 1);
					}

					theValues = theValues.join('|');

					updatedFieldObject[fieldType] = theValues;

					const index =
						updatedEligibilityChecks.CustomValuesToCheck.findIndex(
							(f) => f.name === updatedFieldObject.name,
						);

					if (index !== -1) {
						updatedEligibilityChecks.CustomValuesToCheck[index][
							fieldType
						] = theValues;
					}

					if (props.onUpdateState) {
						props.onUpdateState(
							updatedEligibilityChecks,
							updatedFieldObject,
						);
					}

					onUpdateCompany({
						input: {
							id: company.id,
							eligibiltyChecks: JSON.stringify(
								updatedEligibilityChecks,
							),
						},
					});
				}
			},
			onCancel: () => {
				if (props.onCancelChanges) {
					props.onCancelChanges(
						originalEligibilityChecks,
						originalFieldObject,
					);
				}
			},
		});
	};

	return (
		<ul className="tag-wrap">
			{fieldObject && fieldObject[fieldType]
				? fieldObject[fieldType]
						.split('|')
						.filter((name) => name && name.trim() !== '')
						.map((name, index) => {
							let marginLeft = get(style, 'marginLeft')
								? style.marginLeft
								: 4;
							if (index === 0) marginLeft = 0;
							return (
								<li key={index} className="tag green">
									<span className="tag-name">{name}</span>
									{isSuperAdmin && (
										<i
											className="tag-close icon-cross"
											onClick={() =>
												inactivateCustomFieldInvalidOrValidValues(
													name,
													fieldObject,
													fieldType,
												)
											}
											style={{ cursor: 'pointer' }}
										/>
									)}
								</li>
							);
						})
				: null}
		</ul>
	);
}
