import { memo } from 'react';
import { Card, Form, Space, Checkbox } from 'antd';

// FormMetadataSettings component, these are the sortOrder "0" questions from legacy
export const FormMetadataSettings = memo(function FormMetadataSettings({
	metadata,
	onChange,
	formType,
}) {
	// Default metadata if not provided
	const currentMetadata = metadata || {
		askForResume: false,
		resumeRequired: false,
		showUserEmail: false,
	};

	// Update a single metadata property
	const updateMetadata = (key, value) => {
		const updatedMetadata = { ...currentMetadata, [key]: value };
		onChange(updatedMetadata);
	};

	return (
		<Card
			title={
				<div style={{ fontWeight: 900, fontSize: '16px' }}>
					Form Settings
				</div>
			}
			size="small"
			style={{ marginBottom: '16px', borderRadius: '8px' }}
		>
			<Form layout="vertical">
				<Form.Item>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Checkbox
							checked={currentMetadata.askForResume}
							onChange={(e) =>
								updateMetadata('askForResume', e.target.checked)
							}
						>
							Ask for resume attachment
						</Checkbox>

						{currentMetadata.askForResume && (
							<Checkbox
								checked={currentMetadata.resumeRequired}
								onChange={(e) =>
									updateMetadata(
										'resumeRequired',
										e.target.checked,
									)
								}
								style={{ marginLeft: '24px' }}
							>
								Resume is required
							</Checkbox>
						)}
					</Space>
				</Form.Item>

				{formType === 'Interested' && (
					<Form.Item>
						<Checkbox
							checked={currentMetadata.showUserEmail}
							onChange={(e) =>
								updateMetadata(
									'showUserEmail',
									e.target.checked,
								)
							}
						>
							Show user's email address
						</Checkbox>
					</Form.Item>
				)}
				{formType === 'Acceptance' && (
					<Form.Item>
						<Checkbox
							checked={currentMetadata.askForPhoneNumber}
							onChange={(e) =>
								updateMetadata(
									'askForPhoneNumber',
									e.target.checked,
								)
							}
						>
							Ask for Candidate Phone Number
						</Checkbox>
					</Form.Item>
				)}
			</Form>
		</Card>
	);
});

export default FormMetadataSettings;
