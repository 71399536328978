import { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Modal,
  message,
  Tooltip,
  Spin
} from 'antd';
import { DownOutlined, PlusCircleOutlined, SyncOutlined } from '@ant-design/icons';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { BONUS_STATUS, USER_ROLES } from '../constants';
import { formatDate, mapReferralStatus, ml, parse } from '../services/utils';
import { updateBonusStatus, revalidateBonus } from 'src/_shared/referral/referralActions';

dayjs.extend(utc);

const BonusDetailsModal = (props) => {
  const {
    onClose,
    bonus,
    currentUser,
    allMultiLingualData,
    updateBonusStatus,
    fetchEligibilityResults,
    fetchingEligibility,
    revalidatingBonuses,
    revalidateBonus
  } = props;
  const [isRevalidating, setIsRevalidating] = useState(Object.keys(revalidatingBonuses)?.length > 0);
  useEffect(() => {
    if (isRevalidating && (bonus?.referral?.eligibilityResults || bonus?.user?.title)) {
      setIsRevalidating(false);
    }
  }, [bonus?.referral?.eligibilityResults]);
  const referringUserData = useMemo(() => bonus.referral?.user || {}, [bonus.referral]);
  const eligibilityResults = useMemo(() => {
    if (!bonus.referral?.eligibilityResults) return [];
    try {
      return JSON.parse(bonus.referral.eligibilityResults);
    } catch (e) {
      console.error('Error parsing eligibility results:', e);
      return [];
    }
  }, [bonus.referral?.eligibilityResults]);

  useEffect(() => {
    if (bonus.referralId && !bonus.referral?.eligibilityResults) {
      fetchEligibilityResults(bonus.referralId, bonus.id);
    }
  }, [bonus.referralId, bonus.id, bonus.referral?.eligibilityResults, fetchEligibilityResults]);
  
  const languageCode = get(currentUser, 'languageCode', 'US');
  const dateFormat = get(currentUser, 'dateFormat');
  const displayAs = get(currentUser, 'displayAs');
  
  const managerBonuses = useMemo(() => {
    return currentUser.company?.disableManagerPermissionsByType
      ? JSON.parse(currentUser.company.disableManagerPermissionsByType).managerBonuses
      : 'hidden';
  }, [currentUser.company]);
  
  const isManagerPermissionDisabled = managerBonuses !== 'edit';

  const userGroupCurrency = get(bonus, 'user.userGroup.currency', 'USD');
  const symbol = getSymbolFromCurrency(userGroupCurrency);

  const referrer = useMemo(() => ({
    firstName: get(bonus, 'user.firstName'),
    lastName: get(bonus, 'user.lastName'),
    URL: `/employees/${bonus.userId}`,
  }), [bonus]);

  const referredCandidate = useMemo(() => ({
    firstName: get(bonus, 'contact.firstName'),
    lastName: get(bonus, 'contact.lastName'),
    URL: `/referrals/${bonus.referralId}`,
  }), [bonus]);

  const bonusRecipient = useMemo(() => {
    return bonus.recipientType === 'employee'
      ? {
          firstName: get(bonus, 'user.firstName'),
          lastName: get(bonus, 'user.lastName'),
          URL: `/employees/${bonus.userId}`,
        }
      : {
          firstName: get(bonus, 'contact.firstName'),
          lastName: get(bonus, 'contact.lastName'),
          URL: `/referrals/${bonus.referralId}`,
        };
  }, [bonus]);

  const [isBonusEdited, setIsBonusEdited] = useState(false);
  const [reasonForChange, setReasonForChange] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [notes, setNotes] = useState(bonus.notes || '');
  const [recipientType] = useState(bonus.recipientType || null);
  const [bonusStatus, setBonusStatus] = useState(bonus.bonusStatus || null);
  const [amountDue, setAmountDue] = useState(
    (bonus.amountDue && Number(bonus.amountDue)) || 0
  );
  const [startDate, setStartDate] = useState(
    (bonus.startDate && dayjs.utc(bonus.startDate)) || null
  );
  const [earnedDate, setEarnedDate] = useState(
    (bonus.earnedDate && dayjs.utc(bonus.earnedDate)) || null
  );
  const [paidDate, setPaidDate] = useState(
    (bonus.paidDate && dayjs.utc(bonus.paidDate)) || null
  );
  const [translatedJobTitle, setTranslatedJobTitle] = useState('');

  const history = useHistory();

  useEffect(() => {
    setTranslatedJobTitle(get(bonus, 'job.title'));
  }, [get(bonus, 'job.title'), currentUser]);

  const createLogMessage = useCallback((change) => {
    let changeLog = `${currentUser.emailAddress} updated the ${change} on payment "${bonus.payment}"`;
    if (reasonForChange) changeLog += ` with the reason "${reasonForChange}"`;
    return changeLog;
  }, [currentUser.emailAddress, reasonForChange, bonus.payment]);

  const generateLogs = useCallback(() => {
    const currentDate = dayjs().toISOString();
    const logArray = [];
    const normalFormat = (value) =>
      value == 'earned' ? `"eligible"` : `"${value}"`;
    const dateFormat = (value) =>
      `"${value ? formatDate(value) : 'undefined'}"`;
    const fieldsToCheck = [
      {
        key: 'amountDue',
        newValue: amountDue,
        label: 'bonus amount',
        formatter: normalFormat,
      },
      {
        key: 'startDate',
        newValue: startDate && startDate.toISOString(),
        label: 'start date',
        formatter: dateFormat,
      },
      {
        key: 'earnedDate',
        newValue: earnedDate && earnedDate.toISOString(),
        label: 'eligible date',
        formatter: dateFormat,
      },
      {
        key: 'paidDate',
        newValue: paidDate && paidDate.toISOString(),
        label: 'paid date',
        formatter: dateFormat,
      },
      {
        key: 'bonusStatus',
        newValue: bonusStatus,
        label: 'status',
        formatter: normalFormat,
      },
      {
        key: 'notes',
        newValue: notes ? notes : null,
        label: 'bonus notes',
        formatter: normalFormat,
      },
    ];

    for (const { key, newValue, label, formatter } of fieldsToCheck) {
      const oldValue = key == 'notes' && bonus[key] == '' ? null : bonus[key];

      if (newValue != oldValue) {
        logArray.push({
          date: currentDate,
          changeLog: createLogMessage(`${label} to ${formatter(newValue)}`),
        });
      }
    }

    return logArray;
  }, [
    amountDue, 
    startDate, 
    earnedDate, 
    paidDate, 
    bonusStatus, 
    notes, 
    bonus, 
    createLogMessage
  ]);

  const submitChanges = useCallback(() => {
    const logEntries = generateLogs();
    if (logEntries.length === 0) {
      setEditMode(false);
      setReasonForChange('');
      return;
    }

    if (!reasonForChange && bonus.referralId) {
      message.error(
        ml('Reason for change required', currentUser, allMultiLingualData)
      );
      return;
    }

    const updatedBonus = {
      id: bonus.id,
      earnedDate: earnedDate && earnedDate.toISOString(),
      startDate: startDate && startDate.toISOString(),
      paidDate: paidDate && paidDate.toISOString(),
      recipientType,
      bonusStatus,
      amountDue,
      notes,
    };

    updateBonusStatus(updatedBonus, bonus.referralId, logEntries, eligibilityResults)
      .then(() => {
        setEditMode(false);
        onClose();updateBonusStatus
      })
      .catch((error) => {
        console.error('Error updating bonus:', error);
      });
  }, [
    bonus.id, 
    bonus.referralId,
    bonus.payment,
    bonus.bonusStatus,
    earnedDate, 
    startDate, 
    paidDate, 
    recipientType, 
    bonusStatus, 
    amountDue, 
    notes,
    reasonForChange,
    currentUser,
    allMultiLingualData,
    updateBonusStatus,
    onClose
  ]);

  const renderLogs = useCallback(() => {
    if (!bonus.referralId || !eligibilityResults || eligibilityResults.length === 0) {
      return null;
    }
    if (isRevalidating) {
      return (
        <li>
          <label>{ml('Logs', currentUser, allMultiLingualData)}:</label>
          <div className="log-box" style={{ textAlign: 'center', padding: '20px' }}>
            <Spin size="small" />
            <div style={{ marginTop: '10px' }}>
            Revalidating bonus... This may take a moment.
            </div>
          </div>
        </li>
      );
	  }

	  if (!eligibilityResults || eligibilityResults.length === 0) {
      if (fetchingEligibility) {
        return (
          <li>
            <label>{ml('Logs', currentUser, allMultiLingualData)}:</label>
            <div className="log-box" style={{ textAlign: 'center', padding: '10px' }}>
              <Spin size="small" />
              Loading eligibility records...
            </div>
          </li>
        );
      }

      return (
        <li>
          <label>{ml('Logs', currentUser, allMultiLingualData)}:</label>
          <div className="log-box" style={{ textAlign: 'center', padding: '10px' }}>
            No eligibility records found.
          </div>
        </li>
      );
	  }

	  const parsedEligibilityResults = parse(eligibilityResults);

    return (
      <li>
        <label>{ml('Logs', currentUser, allMultiLingualData)}:</label>
        <div
          className="log-box"
          style={{
            maxHeight: '100px',
            display: 'block',
            width: '100%',
            overflow: 'auto'
          }}
        >
          <div id="log">
            {parsedEligibilityResults.map((entry, index) => (
              <p key={index}>
                <strong>
                  {formatDate(get(entry, 'date')) +
                    (get(entry, 'results')
                      ? ' Eligibility Check - '
                      : ' Bonus Record Change - ')}
                </strong>
                {get(entry, 'results') ?? get(entry, 'changeLog')}
              </p>
            ))}
          </div>
        </div>
      </li>
    );
  }, [bonus.referralId, eligibilityResults, currentUser, allMultiLingualData, fetchingEligibility, isRevalidating]);

  const navigateTo = useCallback((URL) => {
    history.push(URL);
  }, [history]);

  return (
    <Modal
      centered
      open
      footer={null}
      title={ml('Bonus Details', currentUser, allMultiLingualData)}
      closable={false}
	    maskClosable={false}
    >
      <div className="text-right">
        {isManagerPermissionDisabled &&
          displayAs === USER_ROLES.MANAGER ? null : (
            <Button
              type="link"
              onClick={() => {
                setEditMode(true);
                setIsBonusEdited(true);
              }}
              disabled={isRevalidating}
            >
              <i className="icon-edit" />
              {ml('Edit Bonus', currentUser, allMultiLingualData)}
            </Button>
        )}
      </div>
      <ul className="label-data">
        <li>
          <label>
            {ml('Recipient Name', currentUser, allMultiLingualData)}:
          </label>
          <a
            href={bonusRecipient.URL}
            onClick={(event) => {
              event.preventDefault();
              navigateTo(bonusRecipient.URL);
            }}
          >
            {bonusRecipient.firstName}&nbsp;{bonusRecipient.lastName}
          </a>
        </li>
        <li>
          <label>
            {ml('Recipient Type', currentUser, allMultiLingualData)}:
          </label>
          {bonus.recipientType &&
            bonus.recipientType.charAt(0).toUpperCase() +
              bonus.recipientType.slice(1)}
        </li>
        <li>
          <label>{ml('Job', currentUser, allMultiLingualData)}:&nbsp;</label>
          <a
            href={`/jobs/${get(bonus, 'job.id')}`}
            onClick={(event) => {
              event.preventDefault();
              navigateTo(`/jobs/${get(bonus, 'job.id')}`);
            }}
          >
            {translatedJobTitle}
          </a>
        </li>
        {recipientType === 'candidate' ? (
          <li>
            <label>
              {`${ml(
                `${mapReferralStatus('referred', currentUser.company)} By`,
                currentUser,
                allMultiLingualData
              )}: `}
            </label>
            <a
              href={referrer.URL}
              onClick={(event) => {
                event.preventDefault();
                navigateTo(referrer.URL);
              }}
            >
              {referrer.firstName}&nbsp;{referrer.lastName}
            </a>
          </li>
        ) : (
          <li>
            <label>
              {ml('Referred Candidate', currentUser, allMultiLingualData)}:
            </label>
            <a
              href={referredCandidate.URL}
              onClick={(event) => {
                event.preventDefault();
                navigateTo(referredCandidate.URL);
              }}
            >
              {referredCandidate?.firstName}&nbsp;{referredCandidate?.lastName}
            </a>
          </li>
        )}
        <li>
          <label>
            {ml('Bonus Amount', currentUser, allMultiLingualData)}:&nbsp;
          </label>
          {editMode ? (
            <div className="custom-form-group">
              <Input
                value={amountDue}
                type="number"
                className="custom-input"
                onChange={(e) => {
                  setAmountDue(e.target.value);
                }}
              />
            </div>
          ) : (
            <span className="text-green">
              {symbol}
              {bonus.amountDue
                .toString()
                .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',') ||
                (0).toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </span>
          )}
        </li>
        <li>
          <label>{ml('Currency', currentUser, allMultiLingualData)}:</label>
          {symbol}&nbsp;{referringUserData?.userGroup?.currency}
        </li>
        <li>
          <label>
            {ml('Referring Employee Title', currentUser, allMultiLingualData)}:
          </label>
          {referringUserData?.title || ''}
        </li>
        {get(bonus, 'user.accountClaim.companyNumber') && (
          <li>
            <label>Referring Employee Company No.:</label>
            {bonus?.user?.accountClaim?.companyNumber}
          </li>
        )}
        <li>
          <label>
            {ml('Referring Employee Status', currentUser, allMultiLingualData)}:
          </label>
          {referringUserData?.active ? 'Active' : 'Inactive'}
        </li>
        {get(bonus, 'user.accountClaim') &&
          get(bonus, 'user.accountClaim.employeeId') ? (
            <li>
              <label>Referring Employee Number:</label>
              {get(bonus, 'user.accountClaim.employeeId')}
            </li>
          ) : null}

        {get(bonus, 'contact.accountClaim') &&
          get(bonus, 'contact.accountClaim.employeeId') ? (
            <li>
              <label>Referred Employee Number:</label>
              {get(bonus, 'contact.accountClaim.employeeId')}
            </li>
          ) : null}

        <li>
          <label>
            {`${ml(
              `${mapReferralStatus('hired', currentUser.company)} Date`,
              currentUser,
              allMultiLingualData
            )}:`}
          </label>
          {formatDate(bonus.hireDate, languageCode, dateFormat)}
        </li>
        <li>
          <label>{`${ml(
            'Start Date',
            currentUser,
            allMultiLingualData
          )}:`}</label>
          {editMode ? (
            <div className="custom-form-group">
              <DatePicker
                format="MM-DD-YYYY"
                value={startDate}
                className="custom-input"
                onChange={(value) => setStartDate(value)}
              />
            </div>
          ) : (
            <>{formatDate(bonus.startDate, languageCode, dateFormat)}</>
          )}
        </li>
        <li>
          <label>{`${ml(
            'Eligible Date',
            currentUser,
            allMultiLingualData
          )}:`}</label>
          {editMode ? (
            <div className="custom-form-group">
              <DatePicker
                format="MM-DD-YYYY"
                value={earnedDate}
                className="custom-input"
                onChange={(value) => {
                  setEarnedDate(value);
                }}
              />
            </div>
          ) : (
            <>{formatDate(bonus.earnedDate, languageCode, dateFormat)}</>
          )}
        </li>
        <li>
          <label>
            {(paidDate || editMode) &&
              `${ml('Paid Date', currentUser, allMultiLingualData)}:`}
          </label>
          {editMode ? (
            <div className="custom-form-group">
              <DatePicker
                format="MM-DD-YYYY"
                value={paidDate}
                className="custom-input"
                onChange={(value) => setPaidDate(value)}
              />
            </div>
          ) : paidDate ? (
            <>{formatDate(bonus?.paidDate, languageCode, dateFormat)}</>
          ) : null}
        </li>
        <li>
          <label>{ml('Status', currentUser, allMultiLingualData)}:</label>
          {editMode ? (
            <Dropdown
              trigger={['click']}
              menu={{
                items: Object.keys(BONUS_STATUS).map((key) => ({
                  key,
                  label: ml(BONUS_STATUS[key], currentUser, allMultiLingualData),
                  onClick: () => setBonusStatus(key),
                })),
              }}
			        disabled={isRevalidating}
            >
              <Button>
                {ml(
                  BONUS_STATUS[bonusStatus],
                  currentUser,
                  allMultiLingualData
                )}
                <DownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <>
              {bonus?.bonusStatus === 'earned'
                ? ml('Eligible', currentUser, allMultiLingualData)
                : bonus?.bonusStatus
                  ? ml(bonus.bonusStatus, currentUser, allMultiLingualData)
                      .charAt(0)
                      .toUpperCase() +
                      ml(
                        bonus.bonusStatus,
                        currentUser,
                        allMultiLingualData
                      ).slice(1)
                  : null}
                {(bonus.referralId) ? (
                  <Tooltip title={isRevalidating ? "Revalidation in progress..." : "Revalidate Bonus?"}>
                    <Button 
                      type="default" 
                      style={{padding: 2, marginRight: 5}}
                      onClick={() => {
                        revalidateBonus(bonus.id);
                        setIsRevalidating(true);
                      }} 
                      shape="circle" 
                      disabled={isRevalidating}
                      icon={<SyncOutlined spin={isRevalidating} />} 
                    />
                  </Tooltip>
                ) : null}
            </>
          )}
        </li>
        <li>
          <label>{ml('Bonus Notes', currentUser, allMultiLingualData)}:</label>
          {editMode ? (
            <Input.TextArea
              value={notes}
              rows={4}
              className="custom-input"
              style={{ width: '100%' }}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          ) : (
            <>
              {bonus.notes}
              {isManagerPermissionDisabled &&
                displayAs === USER_ROLES.MANAGER ? null : (
                  <div style={{ width: '100%', marginTop: '8px' }}>
                    <Button
                      type="link"
                      onClick={() => {
                        setEditMode(true);
                        setIsBonusEdited(true);
                      }}
                    >
                      <PlusCircleOutlined size={20} />
                      {ml('Add Note', currentUser, allMultiLingualData)}
                    </Button>
                  </div>
              )}
            </>
          )}
        </li>
        {!editMode && renderLogs()}
        {editMode && bonus.referralId ? (
          <li>
            <label>
              <span style={{ color: 'red' }}>* </span>
              {ml('Reason for Change', currentUser, allMultiLingualData)}:
            </label>
            <Input
              value={reasonForChange}
              className="custom-input"
              placeholder={
                ml(
                  'Provide a reason for change',
                  currentUser,
                  allMultiLingualData
                ) + ' ' + '(' +
                ml('required', currentUser, allMultiLingualData) +
                ')'
              }
              style={{ width: '100%' }}
              onChange={(e) => {
                setReasonForChange(e.target.value);
              }}
            />
          </li>
        ) : null}
      </ul>

      {editMode ? (
        <div className="modal-footer-btn">
          <Button
            className="btn-min-width"
            onClick={() => {
              setEditMode(false);
              setIsBonusEdited(false);
            }}
			      disabled={isRevalidating}
          >
            {ml('Cancel', currentUser, allMultiLingualData)}
          </Button>
          <Button
            type="primary"
            className="btn-min-width"
            onClick={submitChanges}
            disabled={isRevalidating}
          >
            {ml('Save', currentUser, allMultiLingualData)}
          </Button>
        </div>
      ) : (
        <div className="modal-footer-btn">
          <Button
            type="primary"
            className="btn-min-width"
            onClick={() => onClose()}
            disabled={isRevalidating}
          >
            {ml('Close', currentUser, allMultiLingualData)}
          </Button>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
	fetchingEligibility: state.referrals.fetchingEligibility,
	revalidatingBonuses: state.referrals.revalidatingBonuses || {}
});
  
const mapDispatchToProps = {
	updateBonusStatus,
	revalidateBonus
};
  
export default connect(mapStateToProps, mapDispatchToProps)(BonusDetailsModal);
