import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

// Create the context with null as default value
export const FormAreaContext = createContext(null);

// Hook to use the form area context
export function useFormAreaContext() {
	const value = useContext(FormAreaContext);
	invariant(value, 'cannot find FormAreaContext provider');
	return value;
}
